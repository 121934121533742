import React from "react";
import { useSelector } from "react-redux";
import {
  Navigate,
  Outlet,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import NotFound from "../pages/NotFound";
import Path from "./Path";

export default function PrivateRoute() {
  const location = useLocation();
  const [param] = useSearchParams();
  const token = useSelector((state) => state.auth.token?.accessToken);

  console.log("token", token);

  if (token) {
    return <Outlet />;
  }
  if (location.pathname === "/") {
    if (!token) {
      return <Navigate to={Path.login} state={{ from: location }} replace />;
    }
  }

  if (location.pathname && param) {
    // const name = encodeURIComponent(`${location.pathname}?${param.toString()}`);
    if (!token) {
      return (
        <Navigate to={`${Path.login}`} state={{ from: location }} replace />
      );
    }
  }

  return <NotFound />;
}
