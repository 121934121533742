import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";

import InputField from "app/components/commonUI/InputField";
import InputDateField from "app/components/commonUI/InputDateField";
import { Avatar } from "@mui/material";
import defaultAvatar from "app/assets/svg/default_avatar.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { useRef } from "react";
import clientFormData from "../../../../services/api/clientFormData";

const validationErrMessages = {
  firstNameKanjiRequired: "名は必須です。",
  lastNameKanjiRequired: "姓は必須です。",
  firstNameKataRequired: "メイは必須です。",
  lastNameKataRequired: "セイは必須です。",
  dobRequired: "生年月日は必須です。",
  phoneRequired: "電話番号は必須です。",
  phoneValid: "電話番号は 10 桁または 11 桁で構成する必要があります",
  emailRequired: "メールアドレスが必要です。",
  emailValid: "有効なメールアドレスを入力してください。",
  addressRequired: "住所は必須です。",
  postalCodeRequired: "郵便番号は必須です。",
  postalCodeValid: "郵便番号は7桁でなければなりません",
};

function RegisterStaffAvatar(props) {
  const { imageUrl, setImageUrl } = props;

  const inputFileRef = useRef();

  const onFileUpload = (file) => {
    const formData = new FormData();

    formData.append("file", file, file.name);

    clientFormData.post("/upload-image", formData).then((res) => {
      if (res.data?.success && res.data?.result) {
        setImageUrl(res.data?.result);
      }
    });
  };

  const onFileChange = (event) => {
    const selectFile = event.target.files[0];
    if (selectFile) {
      onFileUpload(event.target.files[0]);
    }
  };

  return (
    <div className="flex flex-col items-center">
      <div className="w-24 h-24 rounded-full">
        <Avatar
          alt=""
          src={imageUrl || defaultAvatar}
          sx={{ width: 96, height: 96 }}
        />
      </div>

      <button
        type="button"
        onClick={() => {
          inputFileRef.current && inputFileRef.current.click();
        }}
        className="button-size bg-blue-btn-primary mt-6 w-full"
      >
        <FontAwesomeIcon icon={faCamera} />
        <span className="ml-2">写真を選択</span>
      </button>

      <input
        ref={inputFileRef}
        type="file"
        accept="image/*"
        className="hidden"
        onChange={onFileChange}
      />
    </div>
  );
}

function UpdateStaffForm(props) {
  const {
    staffInfo = {},
    isReadMode,
    handleCreateOrUpdate,
    setConfirmedData,
    hanldeBackClick,
  } = props;

  const validationSchema = Yup.object().shape({
    firstNameKanji: Yup.string().required(
      validationErrMessages.firstNameKanjiRequired
    ),
    lastNameKanji: Yup.string().required(
      validationErrMessages.lastNameKanjiRequired
    ),
    firstNameKata: Yup.string().required(
      validationErrMessages.firstNameKataRequired
    ),
    lastNameKata: Yup.string().required(
      validationErrMessages.lastNameKataRequired
    ),
    dob: Yup.object().required(validationErrMessages.dobRequired),
    phone: Yup.string()
      .required(validationErrMessages.phoneRequired)
      .matches(/^(\d{10}|\d{11})$/, validationErrMessages.phoneValid),
    email: Yup.string()
      .email(validationErrMessages.emailValid)
      .required(validationErrMessages.emailRequired),
    address: Yup.string().required(validationErrMessages.addressRequired),
    postalCode: Yup.string()
      .required(validationErrMessages.postalCodeRequired)
      .matches(/^(\d{7})$/, validationErrMessages.postalCodeValid),
  });

  const onSubmit = async (values) => {
    console.log("setConfirmedData: ", values);
    setConfirmedData(values);
  };

  const {
    handleSubmit,
    errors,
    values,
    setFieldValue,
    // resetForm,
    touched,
    handleBlur,
  } = useFormik({
    initialValues: {
      firstNameKanji: staffInfo.firstNameKanji || "",
      lastNameKanji: staffInfo.lastNameKanji || "",
      firstNameKata: staffInfo.firstNameKata || "",
      lastNameKata: staffInfo.lastNameKata || "",
      dob: staffInfo.dob ? new moment(staffInfo.dob) : null,
      phone: staffInfo.phone || "",
      email: staffInfo.email || "",
      address: staffInfo.address || "",
      postalCode: staffInfo.postalCode || "",
      imageUrl: staffInfo.imageUrl || "",
    },
    onSubmit,
    validationSchema,
  });

  return (
    <form className="w-full h-full max-w-[960px] mx-auto relative max-h-full flex flex-wrap pt-4 pb-10 border border-gray-400 rounded-xl px-3 lg:px-6">
      <div className="w-full h-full overflow-auto">
        <div className="w-full h-auto sm:h-full flex justify-between flex-wrap sm:flex-nowrap">
          <div className="w-full sm:w-[160px] h-full flex flex-col items-center sm:py-8">
            <RegisterStaffAvatar
              imageUrl={values.imageUrl}
              setImageUrl={(newValue) => setFieldValue("imageUrl", newValue)}
            />
          </div>

          <div className="w-full sm:w-[calc(100%-180px)] h-full flex flex-col overflow-auto">
            <div className="w-full h-auto flex mb-5 items-end flex-wrap">
              <div className="w-full xl:w-36 h-8 text-lg font-semibold">
                お名前
              </div>
              <div className="w-full xl:w-[calc(100%-144px)] h-auto xl:pl-6 py-0">
                <div className="w-full h-auto flex">
                  <div className="w-1/2 h-auto pr-3 mb-5">
                    <InputField
                      label="セイ"
                      name="lastNameKata"
                      value={values.lastNameKata}
                      type="text"
                      onChange={(newValue) =>
                        setFieldValue("lastNameKata", newValue)
                      }
                      handleBlur={handleBlur}
                      touched={touched.lastNameKata}
                      errors={errors.lastNameKata}
                      disabled={isReadMode}
                    />
                  </div>

                  <div className="w-1/2 h-auto pl-3">
                    <InputField
                      label="メイ"
                      name="firstNameKata"
                      value={values.firstNameKata}
                      type="text"
                      onChange={(newValue) =>
                        setFieldValue("firstNameKata", newValue)
                      }
                      handleBlur={handleBlur}
                      touched={touched.firstNameKata}
                      errors={errors.firstNameKata}
                      disabled={isReadMode}
                    />
                  </div>
                </div>

                <div className="w-full h-auto flex">
                  <div className="w-1/2 h-auto pr-3">
                    <InputField
                      label="姓"
                      name="lastNameKanji"
                      value={values.lastNameKanji}
                      type="text"
                      onChange={(newValue) =>
                        setFieldValue("lastNameKanji", newValue)
                      }
                      handleBlur={handleBlur}
                      touched={touched.lastNameKanji}
                      errors={errors.lastNameKanji}
                      disabled={isReadMode}
                    />
                  </div>

                  <div className="w-1/2 h-auto pl-3">
                    <InputField
                      label="名"
                      name="firstNameKanji"
                      value={values.firstNameKanji}
                      type="text"
                      onChange={(newValue) =>
                        setFieldValue("firstNameKanji", newValue)
                      }
                      handleBlur={handleBlur}
                      touched={touched.firstNameKanji}
                      errors={errors.firstNameKanji}
                      disabled={isReadMode}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full h-auto flex mb-5 flex-wrap">
              <div className="w-full xl:w-36 h-8 text-lg font-semibold">
                生年月日
              </div>
              <div className="w-full xl:w-[calc(100%-144px)] h-auto xl:pl-6 py-0">
                <div className="w-full h-auto flex gap-8">
                  <InputDateField
                    id="dob"
                    value={values.dob}
                    setValue={(newValue) => setFieldValue("dob", newValue)}
                    disabled={isReadMode}
                    onBlur={handleBlur}
                    touched={touched.dob}
                    errors={errors.dob}
                  />
                </div>
              </div>
            </div>

            <div className="w-full h-auto flex mb-5 flex-wrap">
              <div className="w-full xl:w-36 h-8 text-lg font-semibold">
                電話番号
              </div>
              <div className="w-full xl:w-[calc(100%-144px)] h-auto xl:pl-6 py-0">
                <div className="w-full h-auto flex">
                  <div className="w-1/2 h-auto pr-3">
                    <InputField
                      name="phone"
                      value={values.phone}
                      type="text"
                      onChange={(newValue) => setFieldValue("phone", newValue)}
                      handleBlur={handleBlur}
                      touched={touched.phone}
                      errors={errors.phone}
                      disabled={isReadMode}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full h-auto flex mb-5 flex-wrap">
              <div className="w-full xl:w-36 h-8 text-lg font-semibold">
                メールアドレス
              </div>
              <div className="w-full xl:w-[calc(100%-144px)] h-auto xl:pl-6 py-0">
                <div className="w-full h-auto flex items-center">
                  <InputField
                    name="email"
                    value={values.email}
                    type="email"
                    onChange={(newValue) => setFieldValue("email", newValue)}
                    handleBlur={handleBlur}
                    touched={touched.email}
                    errors={errors.email}
                    disabled={isReadMode}
                  />
                </div>
              </div>
            </div>

            <div className="w-full h-auto flex mb-5 flex-wrap">
              <div className="w-full xl:w-36 h-8 text-lg font-semibold">
                住所
              </div>
              <div className="w-full xl:w-[calc(100%-144px)] h-auto xl:pl-6 py-0">
                <div className="w-full h-auto flex flex-col">
                  <div className="w-full h-auto overflow-hidden mb-5">
                    <InputField
                      name="postalCode"
                      value={values.postalCode}
                      type="text"
                      onChange={(newValue) =>
                        setFieldValue("postalCode", newValue)
                      }
                      handleBlur={handleBlur}
                      touched={touched.postalCode}
                      errors={errors.postalCode}
                      disabled={isReadMode}
                      inputClasses="!w-[144px]"
                    />
                  </div>
                  <div className="w-full h-auto overflow-hidden">
                    <InputField
                      name="address"
                      value={values.address}
                      type="text"
                      onChange={(newValue) =>
                        setFieldValue("address", newValue)
                      }
                      handleBlur={handleBlur}
                      touched={touched.address}
                      errors={errors.address}
                      disabled={isReadMode}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full h-10 px-3 lg:px-6 flex items-center justify-end absolute inset-x-0 bottom-0 bg-white rounded-b-xl">
        <button
          onClick={hanldeBackClick}
          type="button"
          className="button-size bg-gray-btn-secondary mr-4 lg:mr-6"
        >
          戻る
        </button>
        <button
          onClick={() => {
            console.log("handleCreateOrUpdate", handleCreateOrUpdate);
            if (handleCreateOrUpdate) {
              handleCreateOrUpdate();
            } else {
              handleSubmit();
            }
          }}
          type="button"
          className="button-size bg-blue-btn-primary"
        >
          確認画面へ
        </button>
      </div>
    </form>
  );
}

export default UpdateStaffForm;
