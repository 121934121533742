import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { QRCodeCanvas } from "qrcode.react";
import CommonDialog from "app/components/CommonDialog";
import QrCodeIcon from "@mui/icons-material/QrCode";
import DefaultLayout from "app/layouts/DefaultLayout";
import MainContentLayout from "app/layouts/MainContentLayout";
import LoadingProgress from "app/components/commonUI/LoadingProgress";
import LoadInitError from "app/components/commonUI/LoadInitError";
import { deleteCustomer, getDetailCustomer } from "app/services/api";
import Path from "app/route/Path";
import ConfirmDeleteDialog from "../../../components/CommonDialog/ConfirmDeleteDialog";
import { IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  getAllCustomerReservation,
  getAllCustomerVisit,
} from "../../../services/api/14";
import moment from "moment";
import { defaultDateFormat, jpDateFormat } from "../../../constants";
import InputField from "../../../components/commonUI/InputField";
import InputDateField from "../../../components/commonUI/InputDateField";
import NoData from "../../../components/commonUI/NoData";

function ReservationHistoryBox(props) {
  const { customerId } = props;
  const [loadInitState, setLoadInitState] = useState({
    isLoading: true,
    data: null,
    error: null,
  });

  useEffect(() => {
    if (!customerId) return;
    setLoadInitState({
      isLoading: true,
      data: null,
      error: null,
    });

    getAllCustomerReservation(customerId)
      .then((res) => {
        setLoadInitState({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        setLoadInitState({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  }, [customerId]);

  const reservationToShow = useMemo(() => {
    if (Array.isArray(loadInitState.data)) {
      return loadInitState.data.filter((item) => !item.visitId);
    }
  }, [loadInitState.data]);

  return (
    <div className="w-full h-full p-3 border border-gray-600 rounded-lg flex flex-col">
      <div className="w-full h-9 flex items-center justify-start">
        <h3 className="text-lg font-semibold leading-6 ">予約</h3>
      </div>
      <div className="w-full h-[calc(100%-36px)] overflow-auto">
        {loadInitState.isLoading ? <LoadingProgress /> : null}

        {!loadInitState.isLoading && loadInitState.error ? (
          <LoadInitError error={loadInitState.error} />
        ) : null}

        {!loadInitState.isLoading &&
        !loadInitState.error &&
        loadInitState.data ? (
          <div className="w-full h-full">
            {Array.isArray(loadInitState.data) &&
            reservationToShow.length > 0 ? (
              <div className="w-full h-auto flex flex-col">
                {reservationToShow.map((item) => (
                  <Link
                    key={item.id}
                    to={Path.reservationManagementDetail(item.id)}
                    className="w-full h-auto py-2 px-1 mb-2 text-start bg-slate-100 hover:bg-slate-200 rounded-md transition-all"
                  >
                    <div className="text-xs font-semibold">
                      {moment(item.visitDate).format(jpDateFormat)}
                    </div>
                    <div className=" text-base">
                      {Array.isArray(item.reservationMenus)
                        ? item.reservationMenus
                            .map((menuItem) => menuItem.name)
                            .join(" + ")
                        : null}
                    </div>
                  </Link>
                ))}
              </div>
            ) : (
              <NoData />
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
}

function VisitHistoryBox(props) {
  const { customerId } = props;
  const [loadInitState, setLoadInitState] = useState({
    isLoading: true,
    data: null,
    error: null,
  });

  useEffect(() => {
    if (!customerId) return;

    setLoadInitState({
      isLoading: true,
      data: null,
      error: null,
    });

    getAllCustomerVisit(customerId)
      .then((res) => {
        console.log("res", res);
        setLoadInitState({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        setLoadInitState({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  }, [customerId]);

  return (
    <div className="w-full h-full p-3 border border-gray-600 rounded-lg flex flex-col">
      <Link
        to={Path.customerManagementVisitHistory(customerId)}
        className="w-full h-9 flex items-center justify-between"
      >
        <h3 className="text-lg font-semibold leading-6 ">来店履歴</h3>

        <IconButton onClick={() => {}} size="small">
          <MoreVertIcon />
        </IconButton>
      </Link>
      <div className="w-full h-[calc(100%-36px)] overflow-auto">
        {loadInitState.isLoading ? <LoadingProgress /> : null}

        {!loadInitState.isLoading && loadInitState.error ? (
          <LoadInitError error={loadInitState.error} />
        ) : null}

        {!loadInitState.isLoading &&
        !loadInitState.error &&
        loadInitState.data ? (
          <div className="w-full h-full">
            {Array.isArray(loadInitState.data) &&
            loadInitState.data.length > 0 ? (
              <div className="w-full h-auto flex flex-col">
                {loadInitState.data.map((item) => (
                  <button
                    key={item.id}
                    className="w-full h-auto py-2 px-1 mb-2 text-start bg-slate-100 hover:bg-slate-200 rounded-md transition-all"
                  >
                    <div className="text-xs font-semibold">
                      {moment(item.visitDate).format(jpDateFormat)}
                    </div>
                    <div className=" text-base">
                      {Array.isArray(item.visitMenus)
                        ? item.visitMenus
                            .map((menuItem) => menuItem.name)
                            .join(" + ")
                        : null}
                    </div>
                  </button>
                ))}
              </div>
            ) : (
              <NoData />
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
}

function CustomerBaseInfoContent(props) {
  const { customerInfo } = props;
  return (
    <div className="w-full h-auto rounded-lg mx-auto">
      <div className="w-ffull h-auto border-b border-gray-400">
        <div className="w-full h-8 border-b border-gray-400 px-6 flex items-center text-lg font-semibold bg-gray-200 rounded-t-lg">
          お客様No.
        </div>
        <div className="w-full h-auto px-3 lg:px-6 py-4">
          <div className="w-full h-auto flex items-center">
            <InputField
              label=" "
              name="customerNo"
              value={customerInfo.customerNo || ""}
              type="text"
              disabled
            />
          </div>
        </div>
      </div>

      <div className="w-ffull h-auto border-b border-gray-400">
        <div className="w-full h-8 border-b border-gray-400 px-6 flex items-center text-lg font-semibold bg-gray-200">
          お名前
        </div>
        <div className="w-full h-auto px-3 lg:px-6 py-4">
          <div className="w-full h-auto flex">
            <div className="w-1/2 h-auto pr-3 mb-5">
              <InputField
                label="セイ"
                name="lastNameKata"
                value={customerInfo.lastNameKata}
                type="text"
                disabled
              />
            </div>

            <div className="w-1/2 h-auto pl-3">
              <InputField
                label="メイ"
                name="firstNameKata"
                value={customerInfo.firstNameKata}
                type="text"
                disabled
              />
            </div>
          </div>

          <div className="w-full h-auto flex">
            <div className="w-1/2 h-auto pr-3">
              <InputField
                label="姓"
                name="lastNameKanji"
                value={customerInfo.lastNameKanji}
                type="text"
                disabled
              />
            </div>

            <div className="w-1/2 h-auto pl-3">
              <InputField
                label="名"
                name="firstNameKanji"
                value={customerInfo.firstNameKanji}
                type="text"
                disabled
              />
            </div>
          </div>
        </div>
      </div>

      <div className="w-ffull h-auto border-b border-gray-400">
        <div className="w-full h-8 border-b border-gray-400 px-6 flex items-center text-lg font-semibold bg-gray-200">
          生年月日
        </div>
        <div className="w-full h-auto px-3 lg:px-6 py-4">
          <div className="w-full h-auto flex gap-8 pl-12">
            <InputDateField
              value={moment(customerInfo.dob, defaultDateFormat)}
              disabled
            />
          </div>
        </div>
      </div>

      <div className="w-ffull h-auto border-b border-gray-400">
        <div className="w-full h-8 border-b border-gray-400 px-6 flex items-center text-lg font-semibold bg-gray-200">
          電話番号
        </div>
        <div className="w-full h-auto px-3 lg:px-6 py-4">
          <div className="w-full h-auto flex">
            <div className="w-1/2 h-auto pr-3">
              <InputField
                label="1"
                name="phone1"
                value={customerInfo.phone1}
                type="text"
                disabled
              />
            </div>
            <div className="w-1/2 h-auto pl-3">
              <InputField
                label="2"
                name="phone2"
                value={customerInfo.phone2}
                type="text"
                disabled
              />
            </div>
          </div>
        </div>
      </div>

      <div className="w-ffull h-auto border-b border-gray-400">
        <div className="w-full h-8 border-b border-gray-400 px-6 flex items-center text-lg font-semibold bg-gray-200">
          メールアドレス
        </div>
        <div className="w-full h-auto px-3 lg:px-6 py-4">
          <div className="w-full h-auto flex items-center">
            <InputField
              label=" "
              name="email"
              value={customerInfo.email}
              type="email"
              disabled
            />
          </div>
        </div>
      </div>

      <div className="w-ffull h-auto">
        <div className="w-full h-8 border-b border-gray-400 px-6 flex items-center text-lg font-semibold bg-gray-200">
          住所
        </div>
        <div className="w-full h-auto px-3 lg:px-6 py-4">
          <div className="w-full h-auto flex items-start">
            <div className="w-1/3 overflow-hidden">
              <InputField
                label=" "
                name="postalCode"
                value={customerInfo.postalCode}
                type="text"
                disabled
              />
            </div>

            <div className="w-2/3 overflow-hidden">
              <InputField
                label=" "
                name="address"
                value={customerInfo.address}
                type="text"
                disabled
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function DetailCustomerContent(props) {
  const {
    customerId,
    handleShowConfirmDelete,
    redirectToContact,
    customerBaseInfo,
  } = props;

  const navigate = useNavigate();

  const [showMemberQrCode, setShowMemberQrCode] = useState(false);

  return (
    <div className="w-full h-full pt-16 pb-8 lg:py-12 relative">
      <CommonDialog
        open={showMemberQrCode}
        handleClose={() => setShowMemberQrCode(false)}
        actions={[]}
      >
        <div className="w-full h-full">
          <div className="w-full h-auto py-6 flex items-center justify-center">
            <div className="w-60 h-60">
              {customerId ? (
                <QRCodeCanvas value={customerBaseInfo.id} size={240} />
              ) : null}
            </div>
          </div>
        </div>
      </CommonDialog>
      <div className="w-full h-16 lg:h-12 pl-20 lg:pl-0 flex items-center justify-between absolute inset-x-0 top-0 bg-white">
        <h2 className="text-base lg:text-xl font-semibold">お客様情報</h2>

        <div className="w-auto h-auto flex items-center gap-4 lg:gap-6">
          <button
            type="button"
            className="button-size bg-[#6670c8]"
            onClick={() => setShowMemberQrCode(true)}
          >
            <QrCodeIcon />
          </button>
          <button
            type="button"
            className="button-size bg-[#6670c8]"
            onClick={() =>
              navigate(Path.customerManagementUpdateBasicInfo(customerId))
            }
          >
            <span className="text-white">変更</span>
          </button>
          <button
            type="button"
            className="button-size bg-[#6670c8]"
            onClick={redirectToContact}
          >
            <span className="text-white">連絡</span>
          </button>
          <button
            type="button"
            className="button-size bg-red-500"
            onClick={handleShowConfirmDelete}
          >
            <span className="text-white">削除</span>
          </button>
        </div>
      </div>

      <div className="w-full h-full py-3">
        <div className="w-full h-full overflow-auto">
          <div className="w-full h-auto flex flex-col justify-start items-center md:flex-row md:items-stretch">
            <div className="w-full h-auto md:w-3/4 md:h-full md:pr-6 mb-6 lg:mb-0">
              <div className="w-full h-full rounded-md">
                <div className="w-full h-[calc(100%-80px)] border border-gray-600 rounded-xl overflow-hidden">
                  <div className="w-full h-full overflow-auto">
                    <div className="w-full h-auto flex items-center justify-center text-xl font-semibold">
                      <CustomerBaseInfoContent
                        customerInfo={customerBaseInfo}
                      />
                    </div>
                  </div>
                </div>

                <div className="w-full h-20 flex items-center pt-4">
                  <div className="h-full w-1/3 pr-2">
                    <Link
                      to={Path.customerManagementFactCheck(customerId, 1)}
                      disabled
                      className="w-full h-full flex items-center justify-center border border-gray-600 rounded-xl"
                    >
                      1stインプレッション
                    </Link>
                  </div>
                  <div className="h-full w-1/3 px-1">
                    <Link
                      to={Path.customerManagementCounselingSheet(customerId)}
                      className="w-full h-full flex items-center justify-center border border-gray-600 rounded-xl"
                    >
                      カウンセリングシート
                    </Link>
                  </div>
                  <div className="h-full w-1/3 pl-1">
                    <Link
                      to={Path.historyOfOrderingDetail(
                        customerBaseInfo.customerNo
                      )}
                      className="w-full h-full flex items-center justify-center border border-gray-600 rounded-xl"
                    >
                      頭皮データ
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full h-auto md:w-1/4 md:relative">
              <div className="w-full h-full inset-0 md:absolute">
                <div className="w-full md:h-1/2 pb-3 h-[300px]">
                  <ReservationHistoryBox customerId={customerId} />
                </div>

                <div className="w-full md:h-1/2 pt-3 h-[300px]">
                  <VisitHistoryBox customerId={customerId} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full h-8 flex items-center justify-end bg-white text-base font-semibold absolute inset-x-0 bottom-0">
        <button
          onClick={() => navigate(Path.customerManagement)}
          type="button"
          className="button-size bg-gray-btn-secondary"
        >
          戻る
        </button>
      </div>
    </div>
  );
}

function DetailCustomerPage() {
  const [loadInitState, setLoadInitState] = useState({
    isLoading: true,
    data: null,
    error: null,
  });
  const [isShowConfirmDelete, setIsShowConfirmDelete] = useState(false);
  const { customerId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setLoadInitState({
      isLoading: true,
      data: null,
      error: null,
    });

    getDetailCustomer(customerId)
      .then((res) => {
        setLoadInitState({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        setLoadInitState({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  }, [customerId]);

  const handleDeleteCustomer = async () => {
    if (loadInitState.data?.salonCustomerId) {
      return await deleteCustomer(loadInitState.data?.salonCustomerId);
    }
  };

  const nextToHome = () => {
    navigate(Path.home);
  };

  return (
    <DefaultLayout>
      <MainContentLayout pageTitle={<p className="text-sm">お客様情報</p>}>
        {loadInitState.isLoading ? <LoadingProgress /> : null}

        {!loadInitState.isLoading && loadInitState.error ? (
          <LoadInitError error={loadInitState.error} />
        ) : null}

        {!loadInitState.isLoading &&
        !loadInitState.error &&
        loadInitState.data ? (
          <div className="w-full h-full flex items-center justify-center">
            <DetailCustomerContent
              customerBaseInfo={loadInitState.data}
              customerId={customerId}
              handleShowConfirmDelete={() => setIsShowConfirmDelete(true)}
              redirectToContact={() =>
                navigate(Path.contactToCustomer(customerId))
              }
            />
          </div>
        ) : null}

        <ConfirmDeleteDialog
          open={isShowConfirmDelete}
          handleClose={() => setIsShowConfirmDelete(false)}
          confirmTitle="以下のお客様情報を全て削除しますか？"
          confirmMessage={
            !loadInitState.isLoading &&
            !loadInitState.error &&
            loadInitState.data?.lastNameKata + loadInitState.data?.firstNameKata
          }
          confirmTitleAgain="本当に以下のお客様情報を全て削除しますか？"
          confirmMessageAgain={
            !loadInitState.isLoading &&
            !loadInitState.error &&
            loadInitState.data?.lastNameKata + loadInitState.data?.firstNameKata
          }
          deleteCompleteTitle="お客様情報を削除しました。"
          deleteCompleteBtnLabel="終る"
          deleteCompleteAction={nextToHome}
          deleteAction={handleDeleteCustomer}
          handleDeleteSuccess={() => {}}
          handleDeleteError={() => {}}
        />
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default DetailCustomerPage;
