import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { fake_history_dms_list } from "../../../../services/api/fake_services/fake_data";
import Path from "../../../../route/Path";
import DefaultLayout from "../../../../layouts/DefaultLayout";
import MainContentLayout from "../../../../layouts/MainContentLayout";
import LoadingProgress from "../../../../components/commonUI/LoadingProgress";
import LoadInitError from "../../../../components/commonUI/LoadInitError";
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  Radio,
  RadioGroup,
  Select,
} from "@mui/material";
import { getDMsDetail } from "../../../../services/api/1A";
import { getNameFromFirstLast } from "../../../../utils";
import moment from "moment";
import { defaultDateTimeFormat } from "../../../../constants";
import NoData from "../../../../components/commonUI/NoData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
const STATUS_DELIVERY = {
  INSTANT_DELIVERY: "immediate",
  RESERVATION_DELIVERY: "reserve",
};

function ConfirmStatusDelivery(props) {
  const { value, handleSelect } = props;
  const handleChange = (event) => {
    handleSelect(event.target.value);
  };

  return (
    <div>
      <RadioGroup value={value} onChange={handleChange} disabled>
        <FormControlLabel
          value={STATUS_DELIVERY.INSTANT_DELIVERY}
          control={<Radio />}
          label="即時配信"
          disabled
        />
        <FormControlLabel
          value={STATUS_DELIVERY.RESERVATION_DELIVERY}
          control={<Radio />}
          label="予約配信"
          disabled
        />
      </RadioGroup>
    </div>
  );
}

function HistoryDmsDetailPage() {
  const navigate = useNavigate();
  const dmsId = useParams();
  const [fetchHistoryDmsDetail, setFetchHistoryDmsDetail] = useState({
    isLoading: true,
    data: null,
    error: null,
  });

  const loadHistoryDmsDetail = () => {
    setFetchHistoryDmsDetail({
      isLoading: true,
      data: null,
      error: null,
    });

    getDMsDetail(dmsId.dmsId)
      .then((res) => {
        setFetchHistoryDmsDetail({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        setFetchHistoryDmsDetail({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  };

  useEffect(() => {
    loadHistoryDmsDetail();
  }, []);
  return (
    <DefaultLayout>
      <MainContentLayout
        pageTitle={
          <>
            <p>ラボ</p>
          </>
        }
      >
        <div className="w-full h-full pt-16 pb-8 lg:py-12 relative">
          <div className="w-full h-16 lg:h-12 pl-20 lg:pl-0 flex items-center justify-start absolute inset-x-0 top-0 bg-white">
            <h2 className="text-base lg:text-xl font-semibold">頭皮ラボ詳細</h2>
          </div>

          <div className="w-full h-full py-3">
            <div className="w-full h-full pr-3 border border-gray-400 rounded-2xl p-4">
              <div className="w-full h-full overflow-auto">
                {fetchHistoryDmsDetail.isLoading ? <LoadingProgress /> : null}

                {!fetchHistoryDmsDetail.isLoading &&
                fetchHistoryDmsDetail.error ? (
                  <LoadInitError error={fetchHistoryDmsDetail.error} />
                ) : null}
                {!fetchHistoryDmsDetail.isLoading &&
                !fetchHistoryDmsDetail.error ? (
                  <div className="w-full h-full overflow-auto">
                    <div className="flex">
                      <label className="block shrink-0 font-semibold !w-[130px]">
                        配信対象
                      </label>
                      <FormControl sx={{ m: 1, minWidth: 250 }} size="small">
                        <InputLabel id="demo-select-small-label">
                          {fetchHistoryDmsDetail.data?.targetAudience}
                        </InputLabel>
                        <Select
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          label={fetchHistoryDmsDetail.data?.targetAudience}
                        ></Select>
                      </FormControl>
                    </div>
                    <div className="flex">
                      <label className="block shrink-0 font-semibold !w-[130px]">
                        配信方法
                      </label>
                      <ConfirmStatusDelivery
                        value={fetchHistoryDmsDetail.data?.deliveryType}
                      />
                    </div>
                    <div className="flex w-full  mb-6 mt-1">
                      <label className="font-semibold !w-[118px]">
                        配信日時
                      </label>

                      <div className="flex gap-2 border p-2 px-8 rounded-md">
                        <div>
                          {moment(
                            fetchHistoryDmsDetail.data?.targetDeliverDatetime
                          )
                            .tz("Asia/Tokyo")
                            .format(defaultDateTimeFormat)}
                        </div>
                        <div className="">
                          <FontAwesomeIcon icon={faCalendarDays} />
                        </div>
                      </div>
                    </div>
                    <div className="flex w-full">
                      <label className="font-semibold !w-[130px]">
                        タイトル
                      </label>
                      <input
                        name="title"
                        type="text"
                        className="w-full h-full p-2 rounded-md border"
                        value={fetchHistoryDmsDetail.data?.title}
                        disabled
                      />
                    </div>
                    <div className="flex w-full mt-6">
                      <label className="font-semibold !w-[130px]">本文</label>
                      <textarea
                        value={fetchHistoryDmsDetail.data?.content}
                        className="w-full  rounded-md border p-2"
                        rows="5"
                        disabled
                      />
                    </div>
                  </div>
                ) : (
                  <NoData />
                )}
              </div>
            </div>
          </div>
          <div className="w-full h-8 flex items-center justify-end text-white text-base font-semibold absolute inset-x-0 bottom-0">
            <button
              onClick={() => navigate(Path.dmsManagementHistory)}
              className="button-size bg-gray-btn-secondary"
            >
              戻る
            </button>
          </div>
        </div>
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default HistoryDmsDetailPage;
