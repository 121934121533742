import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { v4 } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { MenuItem } from "@mui/material";
import {
  faAdd,
  faPenToSquare,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";

import CommonPopoverMenu from "app/components/commonUI/CommonPopoverMenu";
import ConfirmDeleteDialog from "app/components/CommonDialog/ConfirmDeleteDialog";
import MainMenuItem from "app/components/MainMenuItem";
import LoadingBackdrop from "app/components/commonUI/LoadingBackdrop";
import SelectServiceDialog from "app/components/SelectServiceDialog";
import { payment_type_and_method } from "app/constants";
import PrintReceiptDialog from "app/components/PrintReceiptDialog.js";
import Path from "app/route/Path";
import { formatPrice } from "app/utils";
import { updateVisitDetail, paymentForVisit } from "app/services/api";
import PaymentMethodDialog from "../../components/PaymentMethodDialog";
import PaymentSuccessDialog from "../../components/PaymentSuccessDialog";
import PaymentDiscountDialog from "../../components/PaymentDiscountDialog";
import PaymentProductDialog from "../../components/PaymentProductDialog";
import EnterCashDialog from "./EnterCashDialog";

const paymentStatusOptions = {
  idle: "idle",
  loading: "loading",
  success: "success",
  error: "error",
};

function ConfirmServiceItem(props) {
  const { showModeEdit, serviceInfo, handleDelete } = props;

  const [showEditOptionEl, setShowEditOptionEl] = useState(null);

  return (
    <>
      <div
        className={`w-full h-auto flex items-center justify-between relative mb-4 ${
          showModeEdit ? "pr-9" : "pr-0"
        }`}
      >
        {/* service name */}
        <div className="text-base text-white font-semibold">
          <div className="bg-stone-500 py-2 px-4 rounded-md ">
            {serviceInfo.name}
          </div>
        </div>

        {/* service price */}
        <div className="flex-1 text-end">
          {formatPrice(serviceInfo.priceTaxInc)}
        </div>

        {showModeEdit ? (
          <button
            type="button"
            onClick={(e) => setShowEditOptionEl(e.currentTarget)}
            className="absolute top-1/2 right-0 -translate-y-1/2 w-6 h-6 flex items-center justify-center bg-stone-500 text-white font-medium rounded-md"
          >
            <MoreHorizIcon />
          </button>
        ) : null}
      </div>

      <CommonPopoverMenu
        open={!!showEditOptionEl}
        anchorEl={showEditOptionEl}
        onClose={() => setShowEditOptionEl(null)}
      >
        <MenuItem
          onClick={() => {
            handleDelete(serviceInfo);
            setShowEditOptionEl(null);
          }}
          disableRipple
        >
          <span className="text-red-500 mr-3">
            <FontAwesomeIcon icon={faTrashCan} />
          </span>
          <span>消去</span>
        </MenuItem>
      </CommonPopoverMenu>
    </>
  );
}

function ConfirmProductItem(props) {
  const { showModeEdit, productInfo, handleDelete } = props;

  const [showEditOptionEl, setShowEditOptionEl] = useState(null);

  return (
    <>
      <div
        className={`w-full h-auto flex items-center justify-between mb-4 relative ${
          showModeEdit ? "pr-9" : "pr-0"
        }`}
      >
        {/* service name */}
        <div className="flex-1 truncate font-semibold">
          {productInfo?.name || ""}
        </div>

        {/* service price */}
        <div className="">{`${productInfo.amount}x${formatPrice(
          productInfo.price
        )}`}</div>

        {showModeEdit ? (
          <button
            type="button"
            onClick={(e) => setShowEditOptionEl(e.currentTarget)}
            className="absolute top-1/2 right-0 -translate-y-1/2 w-6 h-6 flex items-center justify-center bg-stone-500 text-white font-medium rounded-md ml-3"
          >
            <MoreHorizIcon />
          </button>
        ) : null}
      </div>

      <CommonPopoverMenu
        open={!!showEditOptionEl}
        anchorEl={showEditOptionEl}
        onClose={() => setShowEditOptionEl(null)}
      >
        <MenuItem
          onClick={() => {
            handleDelete(productInfo);
            setShowEditOptionEl(null);
          }}
          disableRipple
        >
          <span className="text-red-500 mr-3">
            <FontAwesomeIcon icon={faTrashCan} />
          </span>
          <span>消去</span>
        </MenuItem>
      </CommonPopoverMenu>
    </>
  );
}

function ConfirmDiscount(props) {
  const { showModeEdit, discountInfo, handleDelete, setDiscount } = props;

  const [showEditOptionEl, setShowEditOptionEl] = useState(null);
  const [showDiscountEdit, setShowDiscountEdit] = useState(false);

  return (
    <>
      <div
        className={`w-full h-auto flex items-center justify-between mb-3 relative ${
          showModeEdit ? "pr-9" : "pr-0"
        }`}
      >
        {/* service name */}
        <div className="">割引</div>

        {/* service price */}
        <div className="flex-1 text-end text-green-500">
          {typeof discountInfo?.discountAmount === "number"
            ? formatPrice(discountInfo.discountAmount)
            : null}
        </div>

        {showModeEdit ? (
          <button
            type="button"
            onClick={(e) => setShowEditOptionEl(e.currentTarget)}
            className="absolute right-0 top-1/2 -translate-y-1/2 w-6 h-6 flex items-center justify-center bg-stone-500 text-white font-medium rounded-md ml-3"
          >
            <MoreHorizIcon />
          </button>
        ) : null}
      </div>

      <CommonPopoverMenu
        open={!!showEditOptionEl}
        anchorEl={showEditOptionEl}
        onClose={() => setShowEditOptionEl(null)}
      >
        {discountInfo ? (
          <>
            <MenuItem
              onClick={() => {
                setShowDiscountEdit(true);
                setShowEditOptionEl(null);
              }}
              disableRipple
            >
              <span className="mr-3">
                <FontAwesomeIcon icon={faPenToSquare} />
              </span>
              <span>編集</span>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleDelete();
                setShowEditOptionEl(null);
              }}
              disableRipple
            >
              <span className="text-red-500 mr-3">
                <FontAwesomeIcon icon={faTrashCan} />
              </span>
              <span>消去</span>
            </MenuItem>
          </>
        ) : (
          <MenuItem
            onClick={() => {
              setShowDiscountEdit(true);
              setShowEditOptionEl(null);
            }}
            disableRipple
          >
            <span className="text-green-500 mr-3">
              <FontAwesomeIcon icon={faAdd} />
            </span>
            <span>追加</span>
          </MenuItem>
        )}
      </CommonPopoverMenu>

      <PaymentDiscountDialog
        open={showDiscountEdit}
        handleClose={() => setShowDiscountEdit(false)}
        discountProps={discountInfo}
        setDiscountProps={setDiscount}
      />
    </>
  );
}

function ConfirmPaymentMethod(props) {
  const { paymentMethodInfo, showModeEdit, setPaymentMethod } = props;
  const [showPaymentMethodEdit, setShowPaymentMethodEdit] = useState(false);

  const paymentMethod = useMemo(() => {
    if (
      !paymentMethodInfo ||
      !paymentMethodInfo.paymentType ||
      !paymentMethodInfo.paymentMethod
    )
      return { payment_method: "", payment_method_label: "" };

    const paymentTypeObj =
      payment_type_and_method[paymentMethodInfo.paymentType];
    const paymentMethodObj = Array.isArray(paymentTypeObj?.payment_methods)
      ? paymentTypeObj.payment_methods.find(
          (item) => item.payment_method === paymentMethodInfo.paymentMethod
        )
      : undefined;
    return paymentMethodObj || { payment_method: "", payment_method_label: "" };
  }, [paymentMethodInfo]);

  return (
    <>
      <div className="flex items-center justify-between mb-3">
        <div className="w-1/3 font-medium">お支払方法</div>

        <div className="flex items-center justify-end">
          <MainMenuItem
            itemTitle={
              <p className="text-xs font-medium">
                {paymentMethod?.payment_method_label || ""}
              </p>
            }
            borderSize={4}
            size={50}
          />
          {showModeEdit ? (
            <button
              type="button"
              onClick={(e) => setShowPaymentMethodEdit(true)}
              className="w-6 h-6 flex items-center justify-center bg-stone-500 text-white font-medium rounded-md ml-3"
            >
              <MoreHorizIcon />
            </button>
          ) : null}
        </div>
      </div>
      {showModeEdit ? (
        <PaymentMethodDialog
          open={showPaymentMethodEdit}
          handleClose={() => setShowPaymentMethodEdit(false)}
          paymentMethodProps={paymentMethodInfo}
          setPaymentMethodProps={setPaymentMethod}
        />
      ) : null}
    </>
  );
}

function PaymentConfirmWithEditEditMode(props) {
  const {
    visitId,
    confirmedVisitData,
    setConfirmedVisitData,
    setShowModeEdit,
    selectedPaymentMethod,
  } = props;

  const [updateVisitData, setUpdateVisitData] = useState({});
  const [isUpdatingVisit, setIsUpdatingVisit] = useState(false);
  const [showPaymentProductDialog, setShowPaymentProductDialog] =
    useState(false);

  const [showSelectMenu, setShowSelectMenu] = useState(false);

  useEffect(() => {
    const initSales = Array.isArray(confirmedVisitData.sales)
      ? confirmedVisitData.sales.map((item) => ({
          ...item.product,
          amount: item.amount,
          name: item.name,
          price: item.price,
        }))
      : [];
    const initDiscount =
      Array.isArray(confirmedVisitData.discounts) &&
      confirmedVisitData.discounts[0]
        ? confirmedVisitData.discounts[0]
        : null;
    const initUpdateVisitData = {
      sales: initSales,
      discount: initDiscount,
      visitMenus: confirmedVisitData.visitMenus || [],
    };

    setUpdateVisitData(initUpdateVisitData);
  }, [confirmedVisitData]);

  const totalAmountVisitMenus = useMemo(() => {
    const visitMenus = confirmedVisitData.visitMenus;

    let amount = 0;
    if (Array.isArray(visitMenus))
      visitMenus.forEach((visitMenuItem) => {
        amount += visitMenuItem?.priceTaxInc || 0;
      });

    return amount;
  }, [confirmedVisitData]);

  const totalAmountSales = useMemo(() => {
    const sales = updateVisitData.sales;

    let amount = 0;
    if (Array.isArray(sales))
      sales.forEach((saleItem) => {
        amount += (saleItem?.price || 0) * (saleItem?.amount || 0);
      });

    return amount;
  }, [updateVisitData]);

  const totalDiscount = useMemo(() => {
    const discount = updateVisitData.discount;

    return Number(discount?.discountAmount || 0);
  }, [updateVisitData]);

  const setDiscount = (newDiscount) => {
    setUpdateVisitData((oldState) => ({
      ...oldState,
      discount: newDiscount,
    }));
  };

  const setSaleProduct = (newSelectedProduct) => {
    setUpdateVisitData((oldState) => ({
      ...oldState,
      sales: newSelectedProduct,
    }));
  };

  const setUpdateVisitMenu = (newVisitMenus) => {
    setUpdateVisitData((oldState) => ({
      ...oldState,
      visitMenus: newVisitMenus,
    }));
  };

  const exitModeEdit = () => {
    setShowModeEdit(false);
  };

  const handleDeleteSaleItem = (productInfo) => {
    const oldList = updateVisitData?.sales;

    if (Array.isArray(oldList))
      setSaleProduct(oldList.filter((item) => item.id !== productInfo.id));
    else setSaleProduct([]);
  };

  const handleDeleteMenuItem = (menu) => {
    const oldList = updateVisitData?.visitMenus;
    if (Array.isArray(oldList))
      setUpdateVisitMenu(oldList.filter((item) => item.id !== menu.id));
    else setUpdateVisitMenu([]);
  };

  const handleSelectService = (newMenuItem) => {
    const oldList = updateVisitData?.visitMenus;

    if (Array.isArray(oldList))
      setUpdateVisitMenu([
        ...oldList,
        {
          ...newMenuItem,
          salonMenuId: newMenuItem.id,
          id: v4(),
        },
      ]);
    else
      setUpdateVisitMenu([
        {
          ...newMenuItem,
          salonMenuId: newMenuItem.id,
          id: v4(),
        },
      ]);
  };

  const handleSaveUpdate = () => {
    setIsUpdatingVisit(true);

    const data = {
      discounts: updateVisitData.discount
        ? [
            {
              discountType: updateVisitData.discount.discountType,
              discountAmount: updateVisitData.discount.discountAmount,
            },
          ]
        : [],
      sales: Array.isArray(updateVisitData.sales)
        ? updateVisitData.sales.map((item) => ({
            amount: item.amount,
            productId: item.id,
          }))
        : [],
      salonMenuIds: Array.isArray(updateVisitData.visitMenus)
        ? updateVisitData.visitMenus.map((item) => item.salonMenuId)
        : [],
    };

    updateVisitDetail(visitId, data)
      .then((res) => {
        setConfirmedVisitData(res.data);
        setShowModeEdit(false);
        setIsUpdatingVisit(false);
      })
      .catch((error) => {
        setIsUpdatingVisit(false);
        console.log("error: ", error);
      });
  };

  return (
    <>
      <div className="w-full h-8 flex items-center justify-end text-white text-base font-semibold absolute inset-x-0 top-0"></div>

      <div className="flex w-full max-h-max overflow-auto relative flex-wrap sm:flex-nowrap">
        <div className="w-full sm:w-1/2 h-auto flex flex-col sm:pr-8">
          {Array.isArray(updateVisitData?.visitMenus)
            ? updateVisitData?.visitMenus.map((itemMenu) => (
                <ConfirmServiceItem
                  serviceInfo={itemMenu}
                  showModeEdit={true}
                  handleDelete={handleDeleteMenuItem}
                />
              ))
            : null}

          <div className="w-full h-auto flex items-center justify-end mb-5">
            <button
              type="button"
              onClick={() => setShowSelectMenu(true)}
              className="button-size bg-blue-btn-primary"
            >
              メニューを追加
            </button>
          </div>

          {Array.isArray(updateVisitData?.sales)
            ? updateVisitData?.sales.map((itemProduct) => (
                <ConfirmProductItem
                  showModeEdit={true}
                  productInfo={itemProduct}
                  handleDelete={handleDeleteSaleItem}
                />
              ))
            : null}

          <div className="w-full h-auto flex items-center justify-end">
            <button
              type="button"
              onClick={() => setShowPaymentProductDialog(true)}
              className="button-size bg-blue-btn-primary"
            >
              その他の販売
            </button>
          </div>
        </div>

        <div className="w-full h-1 bg-[#e5e7eb] my-6 block sm:hidden" />

        <div className="w-full sm:w-1/2 h-auto flex flex-col">
          <div className="w-full h-auto flex items-center justify-between mb-3 relative pr-9">
            <div className="w-1/3 font-medium">小計</div>
            <div>{formatPrice(totalAmountVisitMenus + totalAmountSales)}</div>
          </div>

          <ConfirmDiscount
            showModeEdit={true}
            discountInfo={updateVisitData.discount}
            setDiscount={setDiscount}
            handleDelete={() => {
              setDiscount(null);
            }}
          />

          <div className="w-full h-auto flex items-center justify-between mb-3 relative pr-9">
            <div className="">ご使用クーポン</div>
            <div className="flex-1 text-end"></div>

            <button
              type="button"
              onClick={(e) => {}}
              className="absolute right-0 top-1/2 -translate-y-1/2 w-6 h-6 flex items-center justify-center bg-stone-500 text-white font-medium rounded-md ml-3"
            >
              <MoreHorizIcon />
            </button>
          </div>

          <div className="border-2 mb-4" />

          <div className="w-full h-auto flex items-center justify-between mb-8 relative text-lg font-bold pr-9">
            <div className="w-1/3 font-medium">合計</div>
            <div className="flex-1 text-end">
              {formatPrice(
                totalAmountVisitMenus + totalAmountSales - totalDiscount
              )}
            </div>
          </div>

          <ConfirmPaymentMethod
            showModeEdit={false}
            paymentMethodInfo={selectedPaymentMethod}
          />
        </div>
      </div>

      <div className="w-full h-8 flex items-center justify-end text-white text-base font-semibold absolute inset-x-0 bottom-0">
        <button
          className="button-size bg-gray-btn-secondary mr-4"
          onClick={exitModeEdit}
        >
          戻る
        </button>

        <button
          className="button-size bg-blue-btn-primary"
          onClick={handleSaveUpdate}
        >
          修正を完了させる
        </button>
      </div>

      <PaymentProductDialog
        open={showPaymentProductDialog}
        handleClose={() => {
          setShowPaymentProductDialog(false);
        }}
        selectedProductProps={updateVisitData?.sales || []}
        setSelectedProductProps={setSaleProduct}
      />

      <SelectServiceDialog
        menuType={showSelectMenu}
        handleClose={() => setShowSelectMenu("")}
        handleSelectService={handleSelectService}
      />

      <LoadingBackdrop isOpen={isUpdatingVisit} />
    </>
  );
}

function PaymentConfirmWithEdit(props) {
  const {
    confirmedVisitDataInput,
    backToInput,

    selectedPaymentMethod,
    visitId,
  } = props;

  const [confirmedVisitData, setConfirmedVisitData] = useState(
    confirmedVisitDataInput
  );

  const [showModeEdit, setShowModeEdit] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(paymentStatusOptions.idle);
  const [showEnterCash, setShowEnterCash] = useState(false);
  const [showPaymentSuccessDialog, setShowPaymentSuccessDialog] =
    useState(false);
  const [showPrint, setShowPrint] = useState(false);

  const navigate = useNavigate();

  const totalVisitWithoutDiscount = useMemo(() => {
    const visitMenus = confirmedVisitData.visitMenus;
    const sales = confirmedVisitData.sales;

    let amount = 0;
    if (Array.isArray(visitMenus))
      visitMenus.forEach((visitMenuItem) => {
        amount += visitMenuItem?.priceTaxInc || 0;
      });
    if (Array.isArray(sales))
      sales.forEach((saleItem) => {
        amount += (saleItem?.price || 0) * (saleItem?.amount || 0);
      });

    return amount;
  }, [confirmedVisitData]);

  const totalDiscount = useMemo(() => {
    const discounts = confirmedVisitData.discounts;

    return Number(discounts?.[0]?.discountAmount || 0);
  }, [confirmedVisitData]);

  useEffect(() => {
    setConfirmedVisitData(confirmedVisitDataInput);
  }, [confirmedVisitDataInput]);

  const handleShowModeEdit = () => {
    setShowModeEdit(true);
  };

  const handleShowConfirmDelete = () => {
    setShowConfirmDelete(true);
  };

  const handleDeletePaymentInfo = async () => {
    const resetPaymentVisitData = {
      sales: [],
      discounts: [],
    };

    return updateVisitDetail(visitId, resetPaymentVisitData);
  };

  const backToSelectPaymentType = () => {
    navigate(Path.toDayVisitList);
  };

  const handleCallPayment = (paymentAmount) => {
    console.log("handleCallPayment");
    if (
      paymentStatus === paymentStatusOptions.success ||
      paymentStatus === paymentStatusOptions.loading
    )
      return;
    const paymentData = {
      ...selectedPaymentMethod,
      paymentAmount: paymentAmount,
    };
    setPaymentStatus(paymentStatusOptions.loading);

    paymentForVisit(visitId, paymentData)
      .then((res) => {
        toast.success("支払い成功");
        setShowEnterCash(false);
        setPaymentStatus(paymentStatusOptions.success);
      })
      .catch(() => {
        toast.error("支払い失敗");
        setPaymentStatus(paymentStatusOptions.error);
      });
  };

  if (showModeEdit)
    return (
      <PaymentConfirmWithEditEditMode
        visitId={visitId}
        confirmedVisitData={confirmedVisitData}
        setConfirmedVisitData={setConfirmedVisitData}
        selectedPaymentMethod={selectedPaymentMethod}
        setShowModeEdit={setShowModeEdit}
      />
    );

  return (
    <>
      <div className="w-full h-8 flex items-center justify-end text-white text-base font-semibold absolute inset-x-0 top-0">
        <button
          className="button-size bg-gray-btn-secondary mr-4"
          onClick={handleShowModeEdit}
        >
          会計修正
        </button>

        <button
          className="button-size bg-red-500 flex items-center"
          onClick={handleShowConfirmDelete}
        >
          <FontAwesomeIcon icon={faTrashCan} className="mr-3" />
          削除
        </button>
      </div>

      <div className="flex w-full max-h-max overflow-auto relative flex-wrap sm:flex-nowrap">
        <div className="w-full sm:w-1/2 h-auto flex flex-col sm:pr-8">
          {Array.isArray(confirmedVisitData?.visitMenus)
            ? confirmedVisitData?.visitMenus.map((itemMenu) => (
                <ConfirmServiceItem serviceInfo={itemMenu} />
              ))
            : null}

          {Array.isArray(confirmedVisitData?.sales)
            ? confirmedVisitData?.sales.map((saleItem) => (
                <ConfirmProductItem
                  productInfo={{
                    amount: saleItem.amount,
                    name: saleItem.name,
                    price: saleItem.price,
                    ...saleItem.product,
                  }}
                />
              ))
            : null}
        </div>

        <div className="w-full h-1 bg-[#e5e7eb] my-6 block sm:hidden" />

        <div className="w-full sm:w-1/2 h-auto flex flex-col">
          <div className="w-full h-auto flex items-center justify-between mb-3 relative pr-0">
            <div className="w-1/3 font-medium">小計</div>
            <div>{formatPrice(totalVisitWithoutDiscount)}</div>
          </div>

          <ConfirmDiscount
            discountInfo={
              Array.isArray(confirmedVisitData.discounts) &&
              confirmedVisitData.discounts[0]
                ? confirmedVisitData.discounts[0]
                : null
            }
          />

          <div className="w-full h-auto flex items-center justify-between mb-3 relative pr-0">
            <div className="">ご使用クーポン</div>
            <div className="flex-1 text-end"></div>
          </div>

          <div className="border-2 mb-4" />

          <div className="w-full h-auto flex items-center justify-between mb-8 relative text-lg font-bold pr-0">
            <div className="w-1/3 font-medium">合計</div>
            <div className="flex-1 text-end">
              {formatPrice(totalVisitWithoutDiscount - totalDiscount)}
            </div>
          </div>

          <ConfirmPaymentMethod paymentMethodInfo={selectedPaymentMethod} />
        </div>
      </div>

      <div className="w-full h-8 flex items-center justify-end text-white text-base font-semibold absolute inset-x-0 bottom-0">
        {paymentStatus === paymentStatusOptions.success ? null : (
          <button
            className="button-size bg-gray-btn-secondary mr-4"
            onClick={backToInput}
          >
            戻る
          </button>
        )}

        <button
          className="button-size bg-blue-btn-primary"
          onClick={() => {
            if (paymentStatus === paymentStatusOptions.success) {
              setShowPrint(true);
            } else {
              setShowEnterCash(true);
            }
          }}
        >
          {paymentStatus === paymentStatusOptions.success
            ? "レシート出力"
            : "会計する"}
        </button>
      </div>

      <ConfirmDeleteDialog
        open={showConfirmDelete}
        handleClose={() => setShowConfirmDelete(false)}
        confirmTitle="会計内容を全て削除しますか？"
        confirmMessage=""
        confirmTitleAgain="本当にすべての会計情報を削除しますか?"
        confirmMessageAgain=""
        deleteCompleteTitle="会計内容を削除しました"
        deleteCompleteBtnLabel="お支払方法選択に戻る"
        deleteCompleteAction={backToSelectPaymentType}
        deleteAction={handleDeletePaymentInfo}
        confirmCancleLabel="キャンセル"
        confirmCancleAgainLabel="キャンセル"
        confirmLabel="削除する"
        confirmAgainLabel="削除する"
      />

      <LoadingBackdrop
        isOpen={paymentStatus === paymentStatusOptions.loading}
      />

      <PaymentSuccessDialog
        open={
          paymentStatus === paymentStatusOptions.success &&
          showPaymentSuccessDialog
        }
      />

      <EnterCashDialog
        open={showEnterCash}
        handleClose={() => setShowEnterCash(false)}
        total={totalVisitWithoutDiscount - totalDiscount}
        handlePayment={handleCallPayment}
      />

      <PrintReceiptDialog
        open={showPrint}
        handleClose={() => {
          setShowPrint(false);
          setShowPaymentSuccessDialog(true);
        }}
        receiptInfo={{
          ...confirmedVisitData,
          payment: selectedPaymentMethod,
        }}
      />
    </>
  );
}

export default PaymentConfirmWithEdit;
