import moment from "moment";
import client from "../client";
import { defaultDateFormat } from "../../../constants";

const fakeResponseDataApi = (responseData) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        data: responseData,
      });
    }, 1000);
  });
};

const randomData = () => {
  return Math.random() * 1000;
};

const fakeData = (startDate, endDate) => {
  const startDateObj = moment(startDate, defaultDateFormat);
  const endDateObj = moment(endDate, defaultDateFormat);

  const result = [];

  const nextMonth = startDateObj.clone().add(1, "M");
  if (nextMonth.diff(endDateObj) === 0) {
    while (startDateObj.diff(endDateObj) < 0) {
      result.push({
        label: startDateObj.format("DD"),
        value: Math.ceil(randomData() / 30),
      });

      startDateObj.add(1, "d");
    }

    return result;
  }

  while (startDateObj.diff(endDateObj) < 0) {
    result.push({
      label: startDateObj.format("YYYY年MM月"),
      value: Math.ceil(randomData()),
    });

    startDateObj.add(1, "M");
  }

  return result;
};

export const sendConsultation = (data) => {
  return client.post("/consultation/create", data).then((res) => {
    if (res.data.success) {
      const rawData = res.data.result;
      return {
        data: rawData,
      };
    }
    return {
      data: null,
    };
  });
};

// Fake service data statistics

// 1B02 customer visit
export const getStatisticsCustomerVisit = (startDate, endDate) => {
  return fakeResponseDataApi(fakeData(startDate, endDate));
};

// 1B03 customer repeat reta
export const getStatisticsCustomerRepeatRate = (startDate, endDate) => {
  return fakeResponseDataApi(fakeData(startDate, endDate));
};

// 1B04 turnover
export const getStatisticsTurnover = (startDate, endDate) => {
  return fakeResponseDataApi(fakeData(startDate, endDate));
};

// 1B05 average spend per customer
export const getStatisticsAverageSpendPerCustomer = (startDate, endDate) => {
  return fakeResponseDataApi(fakeData(startDate, endDate));
};

// 1B06 cost rate
export const getStatisticsCostRate = (startDate, endDate) => {
  return fakeResponseDataApi(fakeData(startDate, endDate));
};

// 1B07 operatiing profit ratio
export const getStatisticsOperatingProfitRatio = (startDate, endDate) => {
  return fakeResponseDataApi(fakeData(startDate, endDate));
};

// 1B07 productivity
export const getStatisticsProductivity = (startDate, endDate) => {
  return fakeResponseDataApi(fakeData(startDate, endDate));
};
