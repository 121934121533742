import React, { useCallback, useEffect, useState } from "react";
import DefaultLayout from "app/layouts/DefaultLayout";
import MainContentLayout from "app/layouts/MainContentLayout";
import { getDetailSalonStaff, updateSalonStaff } from "app/services/api";
import { Link, useNavigate, useParams } from "react-router-dom";
import LoadingProgress from "app/components/commonUI/LoadingProgress";
import LoadInitError from "app/components/commonUI/LoadInitError";
import Path from "app/route/Path";
import ConfirmUpdateDialog from "app/components/CommonDialog/ConfirmUpdateDialog";
import UpdateStaffForm from "../components/UpdateStaffForm";

function StaffManagementUpdatePageContent(props) {
  const { staffInfo, staffId } = props;
  const navigate = useNavigate();

  const [confirmedData, setConfirmedData] = useState(null);
  const [showConfirmUpdate, setShowConfirmUpdate] = useState(false);

  const handleCreateOrUpdate = () => {
    setShowConfirmUpdate(true);
  };

  const handleUpdateStaffInfo = async () => {
    if (!confirmedData) return;
    const data = {
      lastNameKata: confirmedData.lastNameKata,
      firstNameKata: confirmedData.firstNameKata,
      lastNameKanji: confirmedData.lastNameKanji,
      firstNameKanji: confirmedData.firstNameKanji,
      dob: confirmedData.dob.toISOString(),
      phone: confirmedData.phone,
      email: confirmedData.email,
      postalCode: confirmedData.postalCode,
      address: confirmedData.address,
      imageUrl: confirmedData.imageUrl,
    };

    return await updateSalonStaff(staffId, data);
  };

  return (
    <>
      <UpdateStaffForm
        staffInfo={staffInfo}
        setConfirmedData={setConfirmedData}
        isReadMode={!!confirmedData}
        handleCreateOrUpdate={confirmedData ? handleCreateOrUpdate : null}
        hanldeBackClick={() => {
          if (confirmedData) setConfirmedData(null);
          else navigate(Path.staffManagementDetail(staffId));
        }}
      />

      <ConfirmUpdateDialog
        open={confirmedData && showConfirmUpdate}
        handleClose={() => setShowConfirmUpdate(false)}
        confirmTitle="スタッフ情報を変更したいのですが?"
        confirmMessage=""
        updateCompleteTitle="社員情報を更新しました"
        updateCompleteBtnLabel="終る"
        updateCompleteAction={() => {
          navigate(Path.staffManagementDetail(staffId));
        }}
        updateAction={handleUpdateStaffInfo}
        confirmCancleLabel="キャンセル"
        confirmLabel="変化"
      />
    </>
  );
}

function StaffManagementUpdatePage() {
  const [fetchInitState, setFetchInitState] = useState({
    isLoading: false,
    data: null,
    error: null,
  });

  const { staffId } = useParams();

  const loadAllShiftPlan = useCallback(() => {
    setFetchInitState({
      isLoading: true,
      data: null,
      error: null,
    });

    getDetailSalonStaff(staffId)
      .then((res) => {
        setFetchInitState({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        setFetchInitState({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  }, [staffId]);

  useEffect(() => {
    loadAllShiftPlan();
  }, [loadAllShiftPlan]);

  return (
    <DefaultLayout>
      <MainContentLayout
        pageTitle={
          <>
            <p>予約を</p>
            <p>確認する</p>
          </>
        }
      >
        <div className="w-full h-full pt-16 pb-8 lg:py-12 relative">
          <div className="w-full h-16 lg:h-12 pl-20 lg:pl-0 flex items-center justify-between absolute inset-x-0 top-0 bg-white">
            <h2 className="text-base lg:text-xl font-semibold">
              スタッフ個人情報
            </h2>

            <div className="w-auto flex items-center justify-center"></div>
          </div>

          <div className="w-full h-full py-3">
            <div className="w-full h-full flex items-center justify-center">
              {fetchInitState.isLoading ? <LoadingProgress /> : null}

              {!fetchInitState.isLoading && fetchInitState.error ? (
                <LoadInitError error={fetchInitState.error} />
              ) : null}
              {!fetchInitState.isLoading &&
              !fetchInitState.error &&
              fetchInitState.data ? (
                <StaffManagementUpdatePageContent
                  staffInfo={fetchInitState.data}
                  staffId={staffId}
                />
              ) : null}
            </div>
          </div>

          <div className="w-full h-8 flex items-center justify-end text-white text-base font-semibold absolute inset-x-0 bottom-0">
            <Link
              to={Path.staffManagementDetail(staffId)}
              className="button-size bg-gray-btn-secondary"
            >
              戻る
            </Link>
          </div>
        </div>
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default StaffManagementUpdatePage;
