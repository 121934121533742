import React, { useEffect, useState } from "react";
import DefaultLayout from "app/layouts/DefaultLayout";
import { CircularProgress } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Path from "app/route/Path";
import moment from "moment";
import { defaultDateFormat } from "app/constants";
import InputField from "app/components/commonUI/InputField";
import InputDateField from "app/components/commonUI/InputDateField";
import { getDetailCustomer } from "app/services/api/11";
import MainContentLayout from "app/layouts/MainContentLayout";
import LoadInitError from "../../../components/commonUI/LoadInitError";
import LoadingProgress from "../../../components/commonUI/LoadingProgress";

function ConfirmCustomerInfoPageContent(props) {
  const { customerInfo, handleConfirm, backToHomePage } = props;
  return (
    <div className="w-full h-full pb-8 lg:pb-12 relative">
      <div className="w-full h-full py-3">
        <div className="w-full h-full overflow-auto">
          <div className="w-full h-auto min-h-full flex flex-col justify-center">
            <div className="w-full h-full max-w-[960px] mx-auto relative max-h-full flex flex-wrap">
              <div className="w-full h-full overflow-auto flex flex-col justify-center">
                <div className="w-full h-auto rounded-lg border border-gray-400 max-w-[680px] mx-auto">
                  <div className="w-ffull h-auto border-b border-gray-400">
                    <div className="w-full h-8 border-b border-gray-400 px-6 flex items-center text-lg font-semibold bg-gray-200 rounded-t-lg">
                      お名前
                    </div>
                    <div className="w-full h-auto px-3 lg:px-6 py-4">
                      <div className="w-full h-auto flex">
                        <div className="w-1/2 h-auto pr-3 mb-5">
                          <InputField
                            label="セイ"
                            name="lastNameKata"
                            value={customerInfo.lastNameKata}
                            type="text"
                            disabled
                          />
                        </div>

                        <div className="w-1/2 h-auto pl-3">
                          <InputField
                            label="メイ"
                            name="firstNameKata"
                            value={customerInfo.firstNameKata}
                            type="text"
                            disabled
                          />
                        </div>
                      </div>

                      <div className="w-full h-auto flex">
                        <div className="w-1/2 h-auto pr-3">
                          <InputField
                            label="姓"
                            name="lastNameKanji"
                            value={customerInfo.lastNameKanji}
                            type="text"
                            disabled
                          />
                        </div>

                        <div className="w-1/2 h-auto pl-3">
                          <InputField
                            label="名"
                            name="firstNameKanji"
                            value={customerInfo.firstNameKanji}
                            type="text"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="w-ffull h-auto border-b border-gray-400">
                    <div className="w-full h-8 border-b border-gray-400 px-6 flex items-center text-lg font-semibold bg-gray-200">
                      生年月日
                    </div>
                    <div className="w-full h-auto px-3 lg:px-6 py-4">
                      <div className="w-full h-auto flex gap-8 pl-12">
                        <InputDateField
                          value={moment(customerInfo.dob, defaultDateFormat)}
                          disabled
                        />
                      </div>
                    </div>
                  </div>

                  <div className="w-ffull h-auto border-b border-gray-400">
                    <div className="w-full h-8 border-b border-gray-400 px-6 flex items-center text-lg font-semibold bg-gray-200">
                      電話番号
                    </div>
                    <div className="w-full h-auto px-3 lg:px-6 py-4">
                      <div className="w-full h-auto flex">
                        <div className="w-1/2 h-auto pr-3">
                          <InputField
                            label="1"
                            name="phone1"
                            value={customerInfo.phone1}
                            type="text"
                            disabled
                          />
                        </div>
                        <div className="w-1/2 h-auto pl-3">
                          <InputField
                            label="2"
                            name="phone2"
                            value={customerInfo.phone2}
                            type="text"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="w-ffull h-auto border-b border-gray-400">
                    <div className="w-full h-8 border-b border-gray-400 px-6 flex items-center text-lg font-semibold bg-gray-200">
                      メールアドレス
                    </div>
                    <div className="w-full h-auto px-3 lg:px-6 py-4">
                      <div className="w-full h-auto flex items-center">
                        <InputField
                          label=" "
                          name="email"
                          value={customerInfo.email}
                          type="email"
                          disabled
                        />
                      </div>
                    </div>
                  </div>

                  <div className="w-ffull h-auto">
                    <div className="w-full h-8 border-b border-gray-400 px-6 flex items-center text-lg font-semibold bg-gray-200">
                      住所
                    </div>
                    <div className="w-full h-auto px-3 lg:px-6 py-4">
                      <div className="w-full h-auto flex items-start">
                        <div className="w-1/3 overflow-hidden">
                          <InputField
                            label=" "
                            name="postalCode"
                            value={customerInfo.postalCode}
                            type="text"
                            disabled
                          />
                        </div>

                        <div className="w-2/3 overflow-hidden">
                          <InputField
                            label=" "
                            name="address"
                            value={customerInfo.address}
                            type="text"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="absolute inset-x-0 bottom-0 w-full h-8 lg:h-12 flex items-end bg-white">
        <div className="w-full h-8 flex items-center justify-end">
          <button
            onClick={backToHomePage}
            type="button"
            className="button-size bg-gray-btn-secondary mr-4 lg:mr-6"
          >
            戻る
          </button>
          <button
            onClick={handleConfirm}
            type="button"
            className="button-size bg-blue-btn-primary"
          >
            施術確定
          </button>
        </div>
      </div>
    </div>
  );
}

function ConfirmCustomerInfoPage() {
  const [loadInitState, setLoadInitState] = useState({
    isLoading: true,
    data: null,
    error: null,
  });
  const { customerId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setLoadInitState({
      isLoading: true,
      data: null,
      error: null,
    });

    getDetailCustomer(customerId)
      .then((res) => {
        setLoadInitState({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        setLoadInitState({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  }, [customerId]);

  const nextToCounselingSheet = () => {
    navigate(Path.createNewReservationAndVisit(customerId));
  };

  const backToHomePage = () => {
    navigate(Path.home);
  };

  return (
    <DefaultLayout>
      <MainContentLayout
        pageTitle={
          <>
            <p>お客様の</p>
            <p>ご来店</p>
          </>
        }
      >
        {loadInitState.error ? (
          <LoadInitError error={loadInitState.error} />
        ) : null}

        {loadInitState.isLoading ? <LoadingProgress /> : null}

        {!loadInitState.isLoading &&
        !loadInitState.error &&
        loadInitState.data ? (
          <div className="w-full h-full flex items-center justify-center pt-16 lg:pt-0">
            <ConfirmCustomerInfoPageContent
              customerInfo={loadInitState.data}
              handleConfirm={nextToCounselingSheet}
              backToHomePage={backToHomePage}
            />
          </div>
        ) : null}
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default ConfirmCustomerInfoPage;
