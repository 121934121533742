import React, { useState } from "react";
import CommonDialog from "./index";
import LoadingBackdrop from "../commonUI/LoadingBackdrop";

function ConfirmActionDialog(props) {
  const {
    open,
    handleClose,
    confirmMessage,
    confirmedAction,
    handleSuccess,
    handleError,

    confirmMessageAgain,
  } = props;

  const [showConfirmAgain, setShowConfirmAgain] = useState(false);
  const [isExecuting, setIsExecuting] = useState(false);

  const handleBack = () => {
    handleClose();
  };

  const executeAction = async () => {
    setIsExecuting(true);

    try {
      const res = await confirmedAction();
      setIsExecuting(false);
      handleClose();
      handleSuccess && handleSuccess(res);
    } catch (error) {
      setIsExecuting(false);
      handleError && handleError(error);
    }
  };

  const handleConfirm = () => {
    if (confirmMessageAgain && !showConfirmAgain) setShowConfirmAgain(true);
    else executeAction();
  };

  const handleCloseDialog = () => {
    handleClose();

    // reset dialog
    setShowConfirmAgain(false);
    setIsExecuting(false);
  };

  return (
    <>
      <CommonDialog
        open={open}
        handleClose={handleCloseDialog}
        title={showConfirmAgain ? confirmMessageAgain : confirmMessage}
        actions={
          <div className="w-full flex items-center justify-end text-white text-base font-semibold">
            <button
              onClick={handleBack}
              type="button"
              className="button-size bg-gray-btn-secondary mr-6"
            >
              戻る
            </button>
            <button
              onClick={() => {
                handleConfirm();
              }}
              type="button"
              className="button-size bg-blue-btn-primary"
            >
              進む
            </button>
          </div>
        }
      />

      <LoadingBackdrop isOpen={isExecuting} />
    </>
  );
}

export default ConfirmActionDialog;
