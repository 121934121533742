import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Bar,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Legend,
  Tooltip,
} from "recharts";
import MainContentLayout from "app/layouts/MainContentLayout";
import DefaultLayout from "app/layouts/DefaultLayout";
import LoadingProgress from "app/components/commonUI/LoadingProgress";
import LoadInitError from "app/components/commonUI/LoadInitError";
import { gettFirstDayOfThisMonth } from "app/utils";
import { defaultDateFormat } from "app/constants";
import Path from "app/route/Path";
import { getStatisticsTurnover } from "app/services/api";
import SelectRangeToAnalyze from "../components/SelectRangeToAnalyze";

function TurnoverAnalyzePage() {
  const [rangeToAnalyze, setRangeToAnalyze] = useState(() => ({
    start: gettFirstDayOfThisMonth(),
    end: gettFirstDayOfThisMonth().add(1, "months"),
  }));

  const [loadDataState, setLoadDataState] = useState({
    isLoading: true,
    data: "",
    error: "",
  });

  const loadData = useCallback(() => {
    setLoadDataState((oldState) => ({ ...oldState, isLoading: true }));

    getStatisticsTurnover(
      rangeToAnalyze.start.format(defaultDateFormat),
      rangeToAnalyze.end.format(defaultDateFormat)
    )
      .then((res) => {
        setLoadDataState((oldState) => ({
          ...oldState,
          data: res.data,
          isLoading: false,
        }));
      })
      .catch((error) => {
        console.log("error", error);
        setLoadDataState((oldState) => ({
          ...oldState,
          error: error,
          isLoading: false,
        }));
      });
  }, [rangeToAnalyze]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "",
      }}
    >
      <MainContentLayout pageTitle="経営分析">
        <div className="w-full h-full mx-auto relative max-h-full flex flex-wrap pt-12 pb-8">
          <div className="w-full h-8 flex items-center justify-between absolute inset-x-0 top-0">
            <h2 className="text-base lg:text-xl font-semibold">回転率</h2>
            <div className=""></div>
          </div>

          <div className="w-full max-h-full">
            <SelectRangeToAnalyze
              startProp={rangeToAnalyze.start}
              endProp={rangeToAnalyze.end}
              setStartProp={(newStart) =>
                setRangeToAnalyze((oldState) => ({
                  start: newStart,
                  end: oldState.end,
                }))
              }
              setEndProp={(newEnd) =>
                setRangeToAnalyze((oldState) => ({
                  start: oldState.start,
                  end: newEnd,
                }))
              }
            />
            <div className="w-full h-[calc(100%-80px)] py-5">
              <div className="w-full h-full rounded-md border border-gray-400 p-6 relative">
                {Array.isArray(loadDataState.data) ? (
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                      data={loadDataState.data}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="label" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="value" fill="#82ca9d" />
                    </BarChart>
                  </ResponsiveContainer>
                ) : null}

                {loadDataState.isLoading ? (
                  <div className="absolute inset-0 w-full h-full rounded-md bg-gray-700 bg-opacity-25">
                    <LoadingProgress />
                  </div>
                ) : loadDataState.error ? (
                  <div className="absolute inset-0 w-full h-full rounded-md bg-gray-700 bg-opacity-25">
                    <LoadInitError />
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="w-full h-8 flex items-center justify-end text-base font-semibold absolute inset-x-0 bottom-0">
            <Link
              to={Path.analyzeManagement}
              className="button-size bg-gray-btn-secondary"
            >
              戻る
            </Link>
          </div>
        </div>
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default TurnoverAnalyzePage;
