import React from "react";
import { useNavigate } from "react-router-dom";
import Path from "app/route/Path";
import AnalyzeBox from "./AnalyzeBox";
import CustomPieChart from "../../components/CustomPieChart";

function ProductivityAnalyze() {
  const navigate = useNavigate();

  return (
    <AnalyzeBox
      title="生産性"
      onClickTitle={() => navigate(Path.productivityAnalyzeManagement)}
    >
      <CustomPieChart rate={0.32} />
    </AnalyzeBox>
  );
}

export default ProductivityAnalyze;
