import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DefaultLayout from "app/layouts/DefaultLayout";
import MainMenuItem from "app/components/MainMenuItem";
import CommonConfirmDialog from "app/components/CommonDialog";
import Path from "app/route/Path";
import CustomerVisitMenuItem from "./CustomerVisitMenuItem";
import SystemManagementMenuItem from "./SystemManagementMenuItem";
import { useMediaQuery } from "@mui/material";

export function ResponsiveMenuItem(props) {
  const { onClick, itemTitle, isMobile, isItemImgMode, itemImgUrl } = props;

  // ? "!border-[#666FC8]"
  // : "!border-[#726966]"
  let imgUrl = "https://placehold.jp/1D7874/ffffff/150x150.png";

  if (isItemImgMode) {
    //imgUrl = itemImgUrl;
    return (
      <img
        onClick={onClick}
        src={imgUrl}
        className="w-full h-20 rounded-md relative border-[#726966] group group-hover:border-[#666FC8] hover:border-[#666FC8] cursor-pointer"
      />
      // <button
      //   style={{
      //     backgroundImage: `url("${imgUrl}")`,
      //     // backgroundImage: `url(${process.env.PUBLIC_URL + '/image.png'})`
      //     backgroundSize: "cover",
      //     backgroundRepeat: "no-repeat",
      //     backgroundPosition: "center ",
      //   }}
      //   onClick={onClick}
      //   className="w-full h-20 rounded-md relative border-[#726966] group group-hover:border-[#666FC8] hover:border-[#666FC8]"
      // >
      //   {/* <div className="w-full h-16 absolute inset-x-0 bottom-0 flex items-center justify-center bg-[#726966] group group-hover:bg-[#666FC8] hover:bg-[#666FC8] text-white">
      //     <div>{itemTitle}</div>
      //   </div> */}
      // </button>
    );
  }

  if (isMobile)
    return (
      <button
        onClick={onClick}
        className="w-full h-20 rounded-md border-4 relative border-[#726966] group group-hover:border-[#666FC8] hover:border-[#666FC8]"
      >
        <div className="w-full h-16 absolute inset-x-0 bottom-0 flex items-center justify-center bg-[#726966] group group-hover:bg-[#666FC8] hover:bg-[#666FC8] text-white text-sm">
          <div>{itemTitle}</div>
        </div>
      </button>
    );
  return (
    <MainMenuItem
      itemTitle={
        <p className="text-xs font-semibold md:text-sm lg:text-base">
          {itemTitle}
        </p>
      }
      onClick={onClick}
      size="100%"
    />
  );
}

function Home() {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:680px)");
  const isItemImgMode = false;

  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "",
      }}
    >
      <div className="w-full h-full overflow-auto">
        <div
          className="w-full grid grid-cols-3 sm:grid-cols-5 px-4 gap-x-10-pct sm:gap-x-14 justify-items-center"
          // style={{ columnGap: "10%" , }}
        >
          <div className="flex items-center justify-center sm:aspect-square w-full h-full mb-6 sm:mb-0 sm:p-[10%]">
            <ResponsiveMenuItem
              isMobile={isMobile}
              isItemImgMode={isItemImgMode}
              itemTitle={
                <>
                  <p>予約を</p>
                  <p>確認する</p>
                </>
              }
              onClick={() => navigate(Path.reservationManagement)}
              size="100%"
            />
          </div>
          <div className="flex items-center justify-center sm:aspect-square w-full h-full mb-6 sm:mb-0 sm:p-[10%]">
            <ResponsiveMenuItem
              isMobile={isMobile}
              itemTitle={
                <>
                  <p>お客様の</p>
                  <p>情報</p>
                </>
              }
              onClick={() => navigate(Path.customerManagement)}
              size="100%"
            />
          </div>
          <div className="flex items-center justify-center sm:aspect-square w-full h-full mb-6 sm:mb-0 sm:p-[10%]">
            <ResponsiveMenuItem
              isMobile={isMobile}
              itemTitle={<p>頭皮ラボ</p>}
              onClick={() => navigate(Path.scalpLabManagement)}
              size="100%"
            />
          </div>
          <div className="flex items-center justify-center sm:aspect-square w-full h-full mb-6 sm:mb-0 sm:p-[10%]">
            <CustomerVisitMenuItem isMobile={isMobile} />
          </div>
          <div className="flex items-center justify-center sm:aspect-square w-full h-full mb-6 sm:mb-0 sm:p-[10%]">
            <ResponsiveMenuItem
              isMobile={isMobile}
              itemTitle={
                <>
                  <p>本日の</p>
                  <p>施術内容</p>
                </>
              }
              onClick={() => navigate(Path.customersUndergoingTreatment)}
              size="100%"
            />
          </div>

          <div className="flex items-center justify-center sm:aspect-square w-full h-full mb-6 sm:mb-0 sm:p-[10%]">
            <ResponsiveMenuItem
              isMobile={isMobile}
              itemTitle={
                <>
                  <p>お客様の</p>
                  <p>お帰り</p>
                </>
              }
              onClick={() => navigate(Path.toDayVisitList)}
              size="100%"
            />
          </div>
          <div className="flex items-center justify-center sm:aspect-square w-full h-full mb-6 sm:mb-0 sm:p-[10%]">
            <ResponsiveMenuItem
              isMobile={isMobile}
              itemTitle={<p>レジ機能</p>}
              onClick={() => navigate(Path.cashierManagement)}
              size="100%"
            />
          </div>
          <div className="flex items-center justify-center sm:aspect-square w-full h-full mb-6 sm:mb-0 sm:p-[10%]">
            <ResponsiveMenuItem
              isMobile={isMobile}
              itemTitle={
                <>
                  <p>在庫を</p>
                  <p>確認する</p>
                </>
              }
              onClick={() => navigate(Path.inventoryManagement)}
              size="100%"
            />
          </div>
          <div className="flex items-center justify-center sm:aspect-square w-full h-full mb-6 sm:mb-0 sm:p-[10%]">
            <ResponsiveMenuItem
              isMobile={isMobile}
              itemTitle={
                <>
                  <p>ダイレクト</p>
                  <p>メール</p>
                </>
              }
              onClick={() => navigate(Path.dmsManagement)}
              size="100%"
            />
          </div>
          <div className="flex items-center justify-center sm:aspect-square w-full h-full mb-6 sm:mb-0 sm:p-[10%]">
            <ResponsiveMenuItem
              isMobile={isMobile}
              itemTitle={
                <>
                  <p>勤怠を</p>
                  <p>入力する</p>
                </>
              }
              onClick={() => navigate(Path.staffManagement)}
              size="100%"
            />
          </div>
          <div className="flex items-center justify-center sm:aspect-square w-full h-full mb-6 sm:mb-0 sm:p-[10%]">
            <ResponsiveMenuItem
              isMobile={isMobile}
              itemTitle={
                <>
                  <p>売上を</p>
                  <p>入力する</p>
                </>
              }
              onClick={() => navigate(Path.saleManagement)}
              size="100%"
            />
          </div>
          <div className="flex items-center justify-center sm:aspect-square w-full h-full mb-6 sm:mb-0 sm:p-[10%]">
            <ResponsiveMenuItem
              isMobile={isMobile}
              itemTitle={
                <>
                  <p>広告を</p>
                  <p>管理する</p>
                </>
              }
              onClick={() => console.log("test........")}
              size="100%"
            />
          </div>
          <div className="flex items-center justify-center sm:aspect-square w-full h-full mb-6 sm:mb-0 sm:p-[10%]">
            <ResponsiveMenuItem
              isMobile={isMobile}
              itemTitle={<p>経営分析</p>}
              onClick={() => navigate(Path.analyzeManagement)}
              size="100%"
            />
          </div>
          <div className="flex items-center justify-center sm:aspect-square w-full h-full mb-6 sm:mb-0 sm:p-[10%]">
            <ResponsiveMenuItem
              isMobile={isMobile}
              itemTitle={
                <>
                  <p>システム設定</p>
                </>
              }
              onClick={() => navigate(Path.systemManagement)}
              size="100%"
            />
          </div>
          <div className="flex items-center justify-center sm:aspect-square w-full h-full mb-6 sm:mb-0 sm:p-[10%]"></div>
        </div>
      </div>

      <CommonConfirmDialog
        open={open}
        handleClose={() => setOpen(false)}
        title="Q. お客様は頭皮ラボの会員ですか？"
      />
    </DefaultLayout>
  );
}

export default Home;
