import { Grid } from "@mui/material";
import React from "react";
import { payment_type_and_method } from "../../../constants";
import MainMenuItem from "../../../components/MainMenuItem";

function InputPaymentMethod(props) {
  const {
    isPaymentByCash,
    paymentType,
    paymentMethod,
    handleSelectPaymentMethod,
  } = props;

  return (
    <div className="w-ffull h-auto mb-8">
      {isPaymentByCash ? (
        <div className="w-full h-auto flex items-center flex-wrap sm:flex-nowrap">
          <div className="w-auto sm:w-5/12">
            <div className="flex flex-col items-end">
              <div className="bg-stone-500 text-white font-medium p-3 rounded-full flex justify-center mb-3 w-[160px]">
                {payment_type_and_method.cash.payment_type_label}
              </div>
            </div>
          </div>
          <div className="w-auto sm:w-7/12 pl-4">
            <div className="flex gap-4 mb-3 ">
              {payment_type_and_method.cash.payment_methods.map(
                (payment_method) => (
                  <MainMenuItem
                    itemTitle={
                      <p className="text-xs font-medium">
                        {payment_method.payment_method_label}
                      </p>
                    }
                    onClick={() => {
                      handleSelectPaymentMethod({
                        paymentType: payment_type_and_method.cash.payment_type,
                        paymentMethod: payment_method.payment_method,
                      });
                    }}
                    borderSize={4}
                    size={50}
                    isActive={
                      paymentType ===
                        payment_type_and_method.cash.payment_type &&
                      paymentMethod === payment_method.payment_method
                    }
                  />
                )
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full h-auto flex items-center flex-wrap">
          <div className="w-full sm:w-5/12">
            <div className="flex flex-col items-start sm:items-end">
              <div className="bg-stone-500 text-white font-medium p-3 rounded-full flex justify-center mb-3 w-[160px]">
                {payment_type_and_method.credit_card.payment_type_label}
              </div>
            </div>
          </div>
          <div className="w-full sm:w-7/12 pl-4">
            <div className="flex gap-4 mb-3 ">
              {payment_type_and_method.credit_card.payment_methods.map(
                (payment_method) => (
                  <MainMenuItem
                    itemTitle={
                      <p className="text-xs font-medium">
                        {payment_method.payment_method_label}
                      </p>
                    }
                    onClick={() => {
                      handleSelectPaymentMethod({
                        paymentType:
                          payment_type_and_method.credit_card.payment_type,
                        paymentMethod: payment_method.payment_method,
                      });
                    }}
                    borderSize={4}
                    size={50}
                    isActive={
                      paymentType ===
                        payment_type_and_method.credit_card.payment_type &&
                      paymentMethod === payment_method.payment_method
                    }
                  />
                )
              )}
            </div>
          </div>

          <div className="w-full sm:w-5/12">
            <div className="flex flex-col items-start sm:items-end">
              <div className="bg-stone-500 text-white font-medium p-3 rounded-full flex justify-center mb-3 w-[160px]">
                {payment_type_and_method.qr_code.payment_type_label}
              </div>
            </div>
          </div>
          <div className="w-full sm:w-7/12 pl-4">
            <div className="flex gap-4 mb-3 ">
              {payment_type_and_method.qr_code.payment_methods.map(
                (payment_method) => (
                  <MainMenuItem
                    itemTitle={
                      <p className="text-xs font-medium">
                        {payment_method.payment_method_label}
                      </p>
                    }
                    onClick={() => {
                      handleSelectPaymentMethod({
                        paymentType:
                          payment_type_and_method.qr_code.payment_type,
                        paymentMethod: payment_method.payment_method,
                      });
                    }}
                    borderSize={4}
                    size={50}
                    isActive={
                      paymentType ===
                        payment_type_and_method.qr_code.payment_type &&
                      paymentMethod === payment_method.payment_method
                    }
                  />
                )
              )}
            </div>
          </div>

          <div className="w-full sm:w-5/12">
            <div className="flex flex-col items-start sm:items-end">
              <div className="bg-stone-500 text-white font-medium p-3 rounded-full flex justify-center mb-3 w-[160px]">
                {payment_type_and_method.eletronic_payment.payment_type_label}
              </div>
            </div>
          </div>
          <div className="w-full sm:w-7/12 pl-4">
            <div className="flex gap-4 mb-3 ">
              {payment_type_and_method.eletronic_payment.payment_methods.map(
                (payment_method) => (
                  <MainMenuItem
                    itemTitle={
                      <p className="text-xs font-medium">
                        {payment_method.payment_method_label}
                      </p>
                    }
                    onClick={() => {
                      handleSelectPaymentMethod({
                        paymentType:
                          payment_type_and_method.eletronic_payment
                            .payment_type,
                        paymentMethod: payment_method.payment_method,
                      });
                    }}
                    borderSize={4}
                    size={50}
                    isActive={
                      paymentType ===
                        payment_type_and_method.eletronic_payment
                          .payment_type &&
                      paymentMethod === payment_method.payment_method
                    }
                  />
                )
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default InputPaymentMethod;
