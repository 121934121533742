import React, { useState } from "react";
import { Popover } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { YearCalendar } from "@mui/x-date-pickers/YearCalendar";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

function YearPickerPopover(props) {
  const { anchorEl, handleClose, year, setYear } = props;

  const [value, setvalue] = useState(year);

  return (
    <Popover
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <YearCalendar
          value={value}
          onChange={(newDate) => {
            setvalue(newDate);
          }}
          referenceDate={moment("2022-04-01")}
          views={["year", "year"]}
        />
      </LocalizationProvider>
      <div className="py-4 px-6 flex items-center justify-end">
        <button
          type="button"
          onClick={() => {
            setYear(value);
            handleClose();
          }}
          className="button-size bg-blue-btn-primary"
        >
          OK
        </button>
      </div>
    </Popover>
  );
}

function SelectYear(props) {
  const { year, setYear } = props;
  const [showSelectYearEl, setShowSelectYearEl] = useState(null);
  return (
    <>
      <div className="w-32 h-8 pr-8 relative">
        <button
          onClick={(e) => setShowSelectYearEl(e.currentTarget)}
          className="w-full h-full rounded-md pl-4 pr-8 flex items-center relative border border-gray-400"
        >
          <p>
            {year ? (
              year.format("YYYY")
            ) : (
              <span className="text-gray-500">YYYY</span>
            )}
          </p>

          <div
            className={`absolute right-1 top-1 w-6 h-6 flex items-center justify-center transition-all ${
              showSelectYearEl ? "rotate-180" : ""
            }`}
          >
            <FontAwesomeIcon icon={faChevronDown} />
          </div>
        </button>

        <div className="absolute w-8 h-8 right-0 inset-y-0 flex items-center justify-center">
          年
        </div>
      </div>

      {showSelectYearEl ? (
        <YearPickerPopover
          anchorEl={showSelectYearEl}
          handleClose={() => setShowSelectYearEl(null)}
          year={year}
          setYear={setYear}
        />
      ) : null}
    </>
  );
}

export default SelectYear;
