import React, { forwardRef, useEffect, useMemo, useState } from "react";
import Dialog from "@mui/material/Dialog";
import logo from "app/assets/images/logo.png";
import LoadingBackdrop from "../commonUI/LoadingBackdrop";
import {
  defaultDateTimeFormat,
  payment_type_and_method,
} from "../../constants";
import { formatPrice } from "../../utils";
import StarWebPrintTrader from "../../assets/js/StarWebPrintTrader";
import StarWebPrintBuilder from "../../assets/js/StarWebPrintBuilder";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import moment from "moment";
import { getSalonDetail } from "../../services/api/18";

function ReceiptContentToPrint(props) {
  const {
    serviceList,
    saleList,
    totalDiscount,
    paymentMethod,
    totalWithoutDiscount,
  } = props;

  const [loadSalonInfo, setLoadSalonInfo] = useState({
    isLoading: true,
    data: null,
    error: null,
  });

  const handleLoadSalonInfo = () => {
    setLoadSalonInfo({
      isLoading: true,
      data: null,
      error: null,
    });

    getSalonDetail()
      .then((res) => {
        setLoadSalonInfo({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        setLoadSalonInfo({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  };

  const drawReceipt = (
    serviceList,
    saleList,
    totalDiscount,
    paymentMethod,
    totalWithoutDiscount,
    salonInfo
  ) => {
    var canvas = document.getElementById("canvasPaper");
    const leftPosition = 0;
    const centerPosition = (canvas.width - leftPosition) / 2;
    const rightPosition = canvas.width - leftPosition;

    const fontSize = 28;
    const lineSpace = 32;
    const receiptWidth = 384;

    const salonName = salonInfo.name;
    const salonPhone = salonInfo.phone;
    const paymentTime = moment().tz("Asia/Tokyo").format(defaultDateTimeFormat);
    const salonAddress = `${
      salonInfo.building ? `${salonInfo.building} ` : ""
    }${salonInfo.street ? `${salonInfo.street}, ` : ""}`;
    const linkWeb = window.location.hostname;

    const DrawRightText = (text, cursor) => {
      if (canvas.getContext) {
        var context = canvas.getContext("2d");

        context.textAlign = "right";

        context.fillText(text, rightPosition, cursor);

        context.textAlign = "start";
      }
    };

    const DrawCenterText = (text, cursor) => {
      if (canvas.getContext) {
        var context = canvas.getContext("2d");

        context.textAlign = "center";

        context.fillText(text, centerPosition, cursor);

        context.textAlign = "start";
      }
    };

    function DrawLeftText(text, cursor) {
      if (canvas.getContext) {
        var context = canvas.getContext("2d");

        context.textAlign = "left";

        context.fillText(text, leftPosition, cursor);

        context.textAlign = "start";
      }
    }

    if (canvas.getContext) {
      var context = canvas.getContext("2d");

      context.clearRect(0, 0, canvas.width, canvas.height);

      context.textBaseline = "top";

      var font = "";

      font += fontSize + "px ";

      font += "Cambria";

      context.font = font;

      let cursor = 50;

      canvas.setAttribute("height", cursor);
      canvas.setAttribute("width", receiptWidth);

      (() => {
        let height = cursor;
        height += 7 * lineSpace;

        if (Array.isArray(serviceList) && serviceList.length > 0) {
          serviceList.forEach((serviceItem) => {
            height += lineSpace;
          });
        }

        if (Array.isArray(saleList) && saleList.length > 0) {
          saleList.forEach((saleItem) => {
            height += lineSpace;
          });
        }

        height += 12 * lineSpace;

        canvas.setAttribute("height", height);
        canvas.setAttribute("width", 384);
      })();

      context.font = font;
      DrawCenterText(salonName, cursor);
      cursor += 2 * lineSpace;

      DrawCenterText(salonAddress, cursor);
      cursor += lineSpace;
      DrawCenterText(salonPhone, cursor);
      cursor += 2 * lineSpace;

      DrawCenterText(paymentTime, cursor);
      cursor += 2 * lineSpace;

      if (Array.isArray(serviceList) && serviceList.length > 0) {
        serviceList.forEach((serviceItem) => {
          DrawLeftText(serviceItem.name, cursor);
          DrawRightText(formatPrice(serviceItem.priceTaxInc), cursor);
          cursor += lineSpace;
        });
      }

      if (Array.isArray(saleList) && saleList.length > 0) {
        saleList.forEach((saleItem) => {
          DrawLeftText(saleItem.name, cursor);
          DrawRightText(
            `${saleItem.amount}x${formatPrice(saleItem.price)}`,
            cursor
          );
          cursor += lineSpace;
        });
      }

      cursor += lineSpace;
      context.fillRect(
        leftPosition,
        cursor - 2,
        receiptWidth - 2 * leftPosition,
        2
      );
      cursor += lineSpace;

      DrawLeftText("小計", cursor);
      DrawRightText(formatPrice(totalWithoutDiscount), cursor);
      cursor += lineSpace;

      DrawLeftText("割引", cursor);
      DrawRightText(formatPrice(totalDiscount), cursor);
      cursor += lineSpace;

      DrawLeftText("ご使用クーポン", cursor);
      DrawRightText("", cursor);
      cursor += lineSpace;

      DrawLeftText("小計", cursor);
      DrawRightText(
        formatPrice(
          totalWithoutDiscount - totalDiscount > 0
            ? totalWithoutDiscount - totalDiscount
            : 0
        ),
        cursor
      );

      cursor += lineSpace;
      context.fillRect(
        leftPosition,
        cursor - 2,
        receiptWidth - 2 * leftPosition,
        2
      );
      cursor += lineSpace;

      DrawCenterText("当サロンをご利用いただき", cursor);
      cursor += lineSpace;
      DrawCenterText("誠にありがとうございます。", cursor);
      cursor += lineSpace;
      DrawCenterText(linkWeb, cursor);
      cursor += 3*lineSpace;

      // end of receipt
      context.font = "10px Cambria";
      DrawCenterText(".", cursor);

      const img = document.getElementById("receipt_image");
      const receiptImage = canvas.toDataURL();
      img.src = receiptImage;
    }
  };

  useEffect(() => {
    handleLoadSalonInfo();
  }, []);

  useEffect(() => {
    if (loadSalonInfo.data)
      drawReceipt(
        serviceList,
        saleList,
        totalDiscount,
        paymentMethod,
        totalWithoutDiscount,
        loadSalonInfo.data
      );
  }, [
    loadSalonInfo.data,
    paymentMethod,
    saleList,
    serviceList,
    totalDiscount,
    totalWithoutDiscount,
  ]);

  return (
    <>
      <div className="w-full h-full max-h-[calc(100vh-160px)] relative overflow-x-hidden overflow-y-auto md:px-6">
        <div className="w-full h-full absolute -z-10 overflow-hidden">
          <canvas id="canvasPaper" width="384" height="200">
            Your browser does not support Canvas!
          </canvas>
        </div>

        <div className="w-full h-full bg-white">
          <img id="receipt_image" className="w-full h-auto" alt="" />
        </div>
      </div>
      <LoadingBackdrop isOpen={loadSalonInfo.isLoading} />
    </>
  );
}

function PrintReceiptDialog(props) {
  const { open, handleClose, receiptInfo } = props;

  const selectedPrinter = useSelector(
    (state) => state.printers.selectedPrinter
  );

  const handlePrint = () => {
    var trader = new StarWebPrintTrader({
      url: selectedPrinter,
      papertype: "normal",
    });

    trader.onReceive = function (response) {
      toast.success("請求書が印刷されました。");
      handleClose();
    };

    trader.onError = function (response) {
      toast.error(
        "請求書の印刷に失敗しました。プリンターの接続をもう一度確認してください。"
      );
    };

    try {
      var canvas = document.getElementById("canvasPaper");

      if (canvas.getContext) {
        var context = canvas.getContext("2d");

        var builder = new StarWebPrintBuilder();

        var request = "";

        request += builder.createInitializationElement();

        request += builder.createBitImageElement({
          context: context,
          x: 0,
          y: 0,
          width: canvas.width,
          height: canvas.height,
        });

        request += builder.createCutPaperElement({ feed: true });

        trader.sendMessage({ request: request });
      }
    } catch (e) {
      toast.error(
        "請求書の印刷に失敗しました。プリンターの接続をもう一度確認してください。"
      );
    }
  };

  const handlePrintDefaultDevice = () => {
    const iframe = document.createElement("iframe");

    // Make it hidden
    iframe.style.height = 0;
    iframe.style.visibility = "hidden";
    iframe.style.width = 0;
    iframe.style.padding = 0;
    iframe.style.margin = 0;

    // Set the iframe's source
    iframe.setAttribute("srcdoc", "<html><body></body></html>");

    document.body.appendChild(iframe);

    iframe.contentWindow.addEventListener("afterprint", function () {
      iframe.parentNode.removeChild(iframe);
    });

    iframe.addEventListener("load", function () {
      // Clone the image
      const image = document.getElementById("receipt_image").cloneNode();
      image.style.width = "50mm";
      image.style.margin = 0;


      // Append the image to the iframe's body
      const body = iframe.contentDocument.body;
      body.style.textAlign = "center";
      body.style.padding = 0;
      body.style.margin = 0;
      body.appendChild(image);

      image.addEventListener("load", function () {
        // Invoke the print when the image is ready
        iframe.contentWindow.print();
      });
    });
  };

  const totalWithoutDiscount = useMemo(() => {
    let total = 0;
    if (Array.isArray(receiptInfo.visitMenus)) {
      receiptInfo.visitMenus.forEach((item) => (total += item.priceTaxInc));
    }

    if (Array.isArray(receiptInfo.sales)) {
      receiptInfo.sales.forEach((item) => (total += item.price * item.amount));
    }
    return total;
  }, [receiptInfo]);

  const totalDiscount = useMemo(() => {
    let total = 0;
    if (Array.isArray(receiptInfo.discounts)) {
      receiptInfo.discounts.forEach((item) => (total += item.discountAmount));
    }
    return total;
  }, [receiptInfo]);

  const paymentMethodStr = useMemo(() => {
    if (
      !receiptInfo.payment ||
      !receiptInfo.payment.paymentType ||
      !receiptInfo.payment.paymentMethod
    )
      return { payment_method: "", payment_method_label: "" };

    const paymentTypeObj =
      payment_type_and_method[receiptInfo.payment.paymentType];
    const paymentMethodObj = Array.isArray(paymentTypeObj?.payment_methods)
      ? paymentTypeObj.payment_methods.find(
          (item) => item.payment_method === receiptInfo.payment.paymentMethod
        )
      : undefined;
    return paymentMethodObj?.payment_method_label || "";
  }, [receiptInfo]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
      }}
      PaperProps={{
        style: {
          backgroundColor: "#F0F0F0",
        },
      }}
    >
      <div className="w-full h-full py-5 bg-slate-200">
        {open ? (
          <ReceiptContentToPrint
            receiptInfo={receiptInfo}
            serviceList={receiptInfo.visitMenus}
            saleList={receiptInfo.sales}
            totalDiscount={totalDiscount}
            paymentMethod={paymentMethodStr}
            totalWithoutDiscount={totalWithoutDiscount}
          />
        ) : null}

        <div className="w-full h-12 flex items-end justify-end px-6">
          <button
            className="button-size bg-blue-btn-primary"
            onClick={selectedPrinter ? handlePrint : handlePrintDefaultDevice}
          >
            印刷する
          </button>
        </div>
      </div>
    </Dialog>
  );
}

export default PrintReceiptDialog;
