import { useMediaQuery } from "@mui/material";
import React from "react";

function MainMenuItem(props) {
  const { size, borderSize, itemTitle, onClick, isActive, disabled } = props;
  const isMobile = useMediaQuery("(max-width:680px)");

  return (
    <button
      type="button"
      className={`rounded-md sm:rounded-full overflow-hidden group relative shrink-0 ${
        disabled
          ? isActive
            ? "!border-[#666FC8]"
            : "!border-[#726966]"
          : isActive
          ? "!border-[#666FC8]"
          : "border-[#726966]"
      } hover:border-[#666FC8]`}
      style={{
        width: typeof size === "number" ? `${size}px` : size,
        height:
          typeof size === "number" ? `${isMobile ? size * 0.7 : size}px` : size,
      }}
      onClick={() => {
        onClick && !disabled && onClick();
      }}
    >
      <div
        className={`w-full h-full rounded-md sm:rounded-full
        ${
          disabled
            ? isActive
              ? "!border-[#666FC8] cursor-default"
              : "!border-[#726966] cursor-default"
            : isActive
            ? "!border-[#666FC8] cursor-pointer"
            : "border-[#726966] cursor-pointer"
        } group-hover:border-[#666FC8]`}
        style={{
          borderWidth:
            typeof borderSize === "number"
              ? `${borderSize}px`
              : typeof borderSize === "string"
              ? borderSize
              : "5px",
        }}
      ></div>
      <div className="absolute inset-0 rounded-md sm:rounded-full overflow-hidden text-white  ">
        <div className="w-full h-[26%] sm:h-[36%] bg-transparent"></div>
        <div
          className={`w-full h-[74%] sm:h-[64%] bg-[#726966] group-hover:bg-[#666FC8] pb-[10%] flex flex-col items-center justify-center
        ${
          disabled
            ? isActive
              ? "!bg-[#666FC8]"
              : "!bg-[#726966]"
            : isActive
            ? "!bg-[#666FC8]"
            : "bg-[#726966]"
        } group-hover:bg-[#666FC8]
        `}
        >
          {itemTitle}
        </div>
      </div>
    </button>
  );
}

MainMenuItem.defaultProps = {
  size: 120,
};

export default MainMenuItem;
