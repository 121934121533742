import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";

import DefaultLayout from "app/layouts/DefaultLayout";
import MainContentLayout from "app/layouts/MainContentLayout";
import LoadInitError from "app/components/commonUI/LoadInitError";
import LoadingProgress from "app/components/commonUI/LoadingProgress";
import ConfirmDeleteDialog from "app/components/CommonDialog/ConfirmDeleteDialog";
import { defaultDateFormat } from "app/constants";
import { getNameFromFirstLast } from "app/utils";
import Path from "app/route/Path";
import ReservationDetail from "../components/ReservationDetail";
import { getReservationDetail, deleteReservation } from "app/services/api";

// TODO-T: check cannot be edited or deleted
function ReservationDetailPageContent(props) {
  const { reservationId, reservationInfo } = props;
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const navigate = useNavigate();

  const handleDeleteReservation = async () => {
    return await deleteReservation(reservationId);
  };

  const confirmDeleteMessage = useMemo(() => {
    return `${dayjs(reservationInfo.visitDate, defaultDateFormat).format(
      "DD/MM"
    )} ${reservationInfo.startTime} ${getNameFromFirstLast(
      reservationInfo.customer
    )}`;
  }, [reservationInfo]);

  const backToHome = () => {
    navigate(Path.home);
  };

  return (
    <>
      <div className="w-full h-16 lg:h-12 pl-20 lg:pl-0 flex items-center justify-end absolute inset-x-0 top-0 bg-white">
        <button
          onClick={() =>
            navigate(Path.contactToCustomer(reservationInfo.customer.id))
          }
          type="button"
          className="button-size bg-blue-btn-primary mr-4 lg:mr-6"
        >
          連絡
        </button>
        <button
          type="button"
          onClick={() =>
            navigate(Path.reservationManagementEdit(reservationInfo.id))
          }
          className="button-size bg-gray-btn-secondary mr-4 lg:mr-6"
        >
          修正
        </button>

        <button
          type="button"
          onClick={() => setShowConfirmDelete(true)}
          className="button-size bg-red-500"
        >
          予約をキャンセルする
        </button>
      </div>

      <div className="w-full h-full">
        <ReservationDetail reservationInfo={reservationInfo} />
      </div>

      <ConfirmDeleteDialog
        open={showConfirmDelete}
        handleClose={() => setShowConfirmDelete(false)}
        confirmTitle="以下の予約を削除しますか？"
        confirmMessage={confirmDeleteMessage}
        confirmTitleAgain="本当に削除してもよろしいですか？"
        confirmMessageAgain={confirmDeleteMessage}
        deleteCompleteTitle="予約を削除しました"
        deleteCompleteBtnLabel="終る"
        deleteCompleteAction={backToHome}
        deleteAction={handleDeleteReservation}
        confirmCancleLabel="キャンセル"
        confirmCancleAgainLabel="キャンセル"
        confirmLabel="削除"
        confirmAgainLabel="削除"
      />
    </>
  );
}

function ReservationDetailPage() {
  const [fetchDetailReservationState, setFetchDetailReservationState] =
    useState({
      isLoading: false,
      data: null,
      error: null,
    });

  const { reservationId } = useParams();

  const loadToDayReservation = useCallback(() => {
    if (!reservationId) return;
    setFetchDetailReservationState({
      isLoading: true,
      data: null,
      error: null,
    });

    getReservationDetail(reservationId)
      .then((res) => {
        setFetchDetailReservationState({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        setFetchDetailReservationState({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  }, [reservationId]);

  useEffect(() => {
    loadToDayReservation();
  }, [loadToDayReservation]);

  return (
    <DefaultLayout>
      <MainContentLayout
        pageTitle={
          <>
            <p>お客様の</p>
            <p>お帰り</p>
          </>
        }
      >
        <div className="w-full h-full pb-8 relative pt-16 lg:pt-12">
          <div className="w-full h-full py-3">
            <div className="w-full h-full overflow-auto flex flex-col justify-center">
              <div className="w-full h-auto max-w-[960px] mx-auto max-h-full">
                {fetchDetailReservationState.isLoading ? (
                  <LoadingProgress />
                ) : null}

                {!fetchDetailReservationState.isLoading &&
                fetchDetailReservationState.error ? (
                  <LoadInitError error={fetchDetailReservationState.error} />
                ) : null}

                {!fetchDetailReservationState.isLoading &&
                !fetchDetailReservationState.error &&
                fetchDetailReservationState.data ? (
                  <>
                    <ReservationDetailPageContent
                      reservationId={reservationId}
                      reservationInfo={fetchDetailReservationState.data}
                    />
                  </>
                ) : null}
              </div>
            </div>
          </div>

          <div className="w-full h-8 flex items-center justify-end text-white text-base font-semibold absolute inset-x-0 bottom-0">
            <Link to={-1} className="button-size bg-gray-btn-secondary">
              戻る
            </Link>
          </div>
        </div>
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default ReservationDetailPage;
