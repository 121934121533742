import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { QrReader } from "react-qr-reader";
import CommonDialog from "app/components/CommonDialog";
import { toast } from "react-toastify";
import {
  CircularProgress,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

import { checkCustomerQrCode, checkScalpLabMember } from "app/services/api";
import Path from "app/route/Path";

const dialogTypes = {
  CHECK_FIRST_TIME: "CHECK_FIRST_TIME",
  CHECK_SCALP_LAB_MEMBER: "CHECK_SCALP_LAB_MEMBER",
  SCAN_QR_CODE: "SCAN_QR_CODE",
};

const checkFirstTimeOptions = {
  FIRST_TIME: "FIRST_TIME",
  NOT_FIRST_TIME: "NOT_FIRST_TIME",
};

const checkScalpLabMemberOptions = {
  IS_MEMBER: "IS_MEMBER",
  NOT_A_MEMBER: "NOT_A_MEMBER",
};

function ConfirmFistTimeContent(props) {
  const { value, handleSelect } = props;
  const handleChange = (event) => {
    handleSelect(event.target.value);
  };

  return (
    <div className="">
      <FormControl>
        <RadioGroup value={value} onChange={handleChange}>
          <FormControlLabel
            value={checkFirstTimeOptions.FIRST_TIME}
            control={<Radio />}
            label="初めてのご来店"
          />
          <FormControlLabel
            value={checkFirstTimeOptions.NOT_FIRST_TIME}
            control={<Radio />}
            label="２回目以上"
          />
        </RadioGroup>
      </FormControl>
    </div>
  );
}

function ConfirmScalpLabMemberContent(props) {
  const { value, handleSelect } = props;
  const handleChange = (event) => {
    handleSelect(event.target.value);
  };

  return (
    <div className="">
      <FormControl>
        <RadioGroup value={value} onChange={handleChange}>
          <FormControlLabel
            value={checkScalpLabMemberOptions.IS_MEMBER}
            control={<Radio />}
            label="はい。会員です。"
          />
          <FormControlLabel
            value={checkScalpLabMemberOptions.NOT_A_MEMBER}
            control={<Radio />}
            label="いいえ。会員ではありません。"
          />
        </RadioGroup>
      </FormControl>
    </div>
  );
}

function ViewFinder() {
  return (
    <div className="absolute inset-0">
      <svg
        width="50"
        viewBox="0 0 100 100"
        className="absolute inset-0 box-border z-10 w-full h-full border-[32px] border-[#0000004d]"
      >
        <path
          fill="none"
          stroke="rgba(255, 0, 0, 0.5)"
          strokeWidth="5"
          d="M13 0H0v13M0 87v13h13M87 100h13V87M100 13V0H87"
        />
      </svg>
    </div>
  );
}

function ScanQrCodeContent(props) {
  const { handleQrCodeDataChange } = props;
  const [browserSupportCamera, setBrowserSupportCamera] = useState(false);

  useEffect(() => {
    if (
      "mediaDevices" in navigator &&
      "getUserMedia" in navigator.mediaDevices
    ) {
      const constraints = {
        video: {},
      };

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(() => {
          setBrowserSupportCamera(true);
        })
        .catch((err) => {
          setBrowserSupportCamera(false);

          if (err.name === "NotAllowedError") {
            toast.error(
              "このデバイスにはカメラがないか、カメラへのアクセス許可がありません。"
            );
          } else {
            toast.error(
              "このデバイスにはカメラがないか、カメラへのアクセス許可がありません。"
            );
          }
        });
    } else {
      setBrowserSupportCamera(false);
      toast.error(
        "このデバイスにはカメラがないか、カメラへのアクセス許可がありません。"
      );
    }
  }, []);

  return (
    <div className="w-full h-full flex items-center justify-center">
      <div className="min-w-[240px] min-h-[240px] max-w-[400px] max-h-[400px] w-1/2 aspect-square mb-8">
        {browserSupportCamera ? (
          <QrReader
            constraints={{ facingMode: "environment" }}
            onResult={(data) => handleQrCodeDataChange(data)}
            videoStyle={{ objectFit: "cover" }}
            ViewFinder={ViewFinder}
          />
        ) : (
          <div className="w-full h-full flex items-center justify-center">
            <CircularProgress className="w-8 h-8" />
          </div>
        )}
      </div>
    </div>
  );
}

function ConfirmCustomerInfoDialog(props) {
  const { openDialog, handleCloseDialog } = props;
  const navigate = useNavigate();

  const [customerInfo, setCustomerInfo] = useState({
    isFirstTime: "",
    isScalpLabMember: "",
    scalpLabMemberId: "",
  });

  const [showDialogType, setShowDialogType] = useState(
    dialogTypes.CHECK_FIRST_TIME
  );

  const [isCheckingMemberId, setIsCheckingMemberId] = useState(false);

  const handleClose = () => {
    // reset dialog state
    setCustomerInfo({
      isFirstTime: "",
      isScalpLabMember: "",
      scalpLabMemberId: "",
    });
    setShowDialogType(dialogTypes.CHECK_FIRST_TIME);

    // close dialog
    handleCloseDialog();
  };

  const handleBack = () => {
    if (showDialogType === dialogTypes.CHECK_FIRST_TIME) {
      handleCloseDialog();
    } else if (showDialogType === dialogTypes.CHECK_SCALP_LAB_MEMBER) {
      setCustomerInfo((info) => ({
        ...info,
        isScalpLabMember: "",
        scalpLabMemberId: "",
      }));
      setShowDialogType(dialogTypes.CHECK_FIRST_TIME);
    } else if (showDialogType === dialogTypes.SCAN_QR_CODE) {
      setCustomerInfo((info) => ({
        ...info,
        scalpLabMemberId: "",
      }));
      setShowDialogType(dialogTypes.CHECK_SCALP_LAB_MEMBER);
    }
  };

  const handleConfim = () => {
    if (showDialogType === dialogTypes.CHECK_FIRST_TIME) {
      if (customerInfo.isFirstTime === checkFirstTimeOptions.FIRST_TIME) {
        setShowDialogType(dialogTypes.CHECK_SCALP_LAB_MEMBER);
      } else if (
        customerInfo.isFirstTime === checkFirstTimeOptions.NOT_FIRST_TIME
      ) {
        setShowDialogType(dialogTypes.SCAN_QR_CODE);
      }
    } else if (showDialogType === dialogTypes.CHECK_SCALP_LAB_MEMBER) {
      if (
        customerInfo.isScalpLabMember === checkScalpLabMemberOptions.IS_MEMBER
      ) {
        setShowDialogType(dialogTypes.SCAN_QR_CODE);
      } else if (
        customerInfo.isScalpLabMember ===
        checkScalpLabMemberOptions.NOT_A_MEMBER
      ) {
        navigate(Path.createCustomerInfo);
      }
    }
  };

  const handleQrCodeDataChange = (data) => {
    if (isCheckingMemberId || !data || !data.text) return;

    setIsCheckingMemberId(true);
    checkCustomerQrCode(data.text)
      .then((res) => {
        const data = res?.data;
        console.log("checkCustomerQrCode", data);
        if (data?.customerId && data?.reservationId) {
          navigate(Path.confirmReservationInfo(data?.reservationId));
        } else if (data?.customerId) {
          navigate(Path.confirmCustomerInfo(data?.customerId));
        } else {
          toast.error("会員IDが存在しません");
        }
      })
      .catch((err) => {
        console.log("error: ", err);
        toast.error("会員IDが存在しません");
      })
      .finally(() => {
        setIsCheckingMemberId(false);
      });
  };

  return (
    <CommonDialog
      open={openDialog}
      handleClose={handleClose}
      handleBack={handleBack}
      handleConfim={handleConfim}
      title={
        showDialogType === dialogTypes.CHECK_FIRST_TIME
          ? "Q. お客様のご来店は初めてですか？"
          : showDialogType === dialogTypes.CHECK_SCALP_LAB_MEMBER
          ? "Q. お客様は頭皮ラボの会員ですか？"
          : ""
      }
    >
      {showDialogType === dialogTypes.CHECK_FIRST_TIME ? (
        <ConfirmFistTimeContent
          value={customerInfo.isFirstTime}
          handleSelect={(newValue) =>
            setCustomerInfo({
              isFirstTime: newValue,
              isScalpLabMember: "",
              scalpLabMemberId: "",
            })
          }
        />
      ) : null}

      {showDialogType === dialogTypes.CHECK_SCALP_LAB_MEMBER ? (
        <ConfirmScalpLabMemberContent
          value={customerInfo.isScalpLabMember}
          handleSelect={(newValue) =>
            setCustomerInfo({
              isFirstTime: checkFirstTimeOptions.FIRST_TIME,
              isScalpLabMember: newValue,
              scalpLabMemberId: "",
            })
          }
        />
      ) : null}

      {showDialogType === dialogTypes.SCAN_QR_CODE ? (
        <ScanQrCodeContent handleQrCodeDataChange={handleQrCodeDataChange} />
      ) : null}
    </CommonDialog>
  );
}

export default ConfirmCustomerInfoDialog;
