import React, { useEffect, useState } from "react";
import { v4 } from "uuid";
import { Grid, MenuItem } from "@mui/material";
import { faBars, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CommonPopoverMenu from "app/components/commonUI/CommonPopoverMenu";
import SelectServiceDialog from "app/components/SelectServiceDialog";
import { product_category_and_label } from "app/constants";
import { formatPrice } from "app/utils";

function SelectedServiceItem(props) {
  const { menuItem, handleDelete } = props;
  const [showEditOptionEl, setShowEditOptionEl] = useState(null);
  return (
    <>
      <div className="w-full h-12 rounded-md bg-slate-100 mb-2 pr-12 pl-2 relative">
        <div className="w-full h-full flex">
          <div className="w-5/12 flex items-center justify-start">
            <CategoryItem
              title={menuItem.name}
              onClick={() => {}}
              extraClasses="!h-10"
            />
          </div>
          <div className="w-3/12 flex items-center justify-end">
            <p>{menuItem.durationString}</p>
          </div>
          <div className="w-4/12 flex items-center justify-end">
            <p>{formatPrice(menuItem.priceTaxInc)}</p>
          </div>
        </div>

        <button
          type="button"
          onClick={(e) => setShowEditOptionEl(e.currentTarget)}
          className="absolute top-2 right-2 w-8 h-8 flex items-center justify-center rounded hover:bg-slate-300"
        >
          <FontAwesomeIcon icon={faBars} />
        </button>
      </div>

      <CommonPopoverMenu
        open={!!showEditOptionEl}
        anchorEl={showEditOptionEl}
        onClose={() => setShowEditOptionEl(null)}
      >
        <MenuItem
          onClick={() => {
            handleDelete();
            setShowEditOptionEl(null);
          }}
          disableRipple
        >
          <span className="text-red-500 mr-3">
            <FontAwesomeIcon icon={faTrashCan} />
          </span>
          <span>消去</span>
        </MenuItem>
      </CommonPopoverMenu>
    </>
  );
}

function CategoryItem(props) {
  const { title, onClick, extraClasses } = props;
  return (
    <button
      type="button"
      className={`w-44 h-12 flex items-center justify-center rounded-full bg-[#736966] hover:bg-[#5e5553] overflow-hidden text-lg text-white shrink-0 ${
        extraClasses || ""
      }`}
      onClick={() => onClick && onClick()}
    >
      {title}
    </button>
  );
}

function Step2SelectMenu(props) {
  const { handleNextToStep3, handleBackToStep1, step2Data } = props;

  const [menuList, setMenuList] = useState([]);
  const [showSelectMenu, setShowSelectMenu] = useState("");

  useEffect(() => {
    setMenuList(step2Data);
  }, [step2Data]);

  const handleSelectService = (serviceInfo) => {
    setMenuList((oldList) => [...oldList, { ...serviceInfo, tempId: v4() }]);
  };
  const handleDeleteItem = (serviceInfo) => {
    setMenuList((oldList) =>
      oldList.filter((item) => item.tempId !== serviceInfo.tempId)
    );
  };

  const handleClickNext = () => {
    if (menuList.length === 0) return;

    handleNextToStep3(menuList);
  };

  return (
    <>
      <div className="w-full h-full pb-8 relative">
        <div className="w-full h-full py-3">
          <div className="w-full h-full md:flex overflow-auto">
            <div className="w-full h-auto py-4 md:py-0 md:w-5/12 md:h-full md:pr-4">
              <div className="w-full h-full flex flex-col items-center justify-center gap-6">
                <h3 className="text-lg font-semibold text-center">
                  予約するメニューを選んでください
                </h3>

                <div className="flex flex-col items-center justify-center gap-6">
                  {product_category_and_label.map((category) => (
                    <CategoryItem
                      key={category.key}
                      title={category.label}
                      onClick={() => {
                        setShowSelectMenu(category.key);
                      }}
                    />
                  ))}
                </div>
              </div>
            </div>
            <div className="w-full h-auto md:w-7/12 md:h-full md:overflow-auto">
              <div className="w-full h-full min-h-[200px] border border-gray-400 rounded-md px-2 pt-4 pb-16 relative">
                <div className="w-full h-full overflow-auto">
                  <div className="w-full">
                    {menuList.map((item) => (
                      <SelectedServiceItem
                        menuItem={item}
                        handleDelete={() => handleDeleteItem(item)}
                      />
                    ))}
                  </div>
                </div>

                <div className="w-full h-14 absolute inset-x-0 bottom-0 px-2">
                  <div className="w-full h-full border-t-2 border-gray-400">
                    <div className="w-full h-12 rounded-md mb-2 pr-12 pl-2 font-semibold">
                      <div className="w-full h-full flex">
                        <div className="w-5/12 flex items-center justify-end">
                          合計
                        </div>
                        <div className="w-1/3 flex items-center justify-end">
                          <p>
                            {menuList.reduce((a, b) => a + b.durationNumber, 0)}{" "}
                            分
                          </p>
                        </div>
                        <div className="w-1/4 flex items-center justify-end">
                          <p>
                            {formatPrice(
                              menuList.reduce((a, b) => a + b.priceTaxInc, 0)
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full h-8 flex items-center justify-end text-white text-base font-semibold absolute inset-x-0 bottom-0">
          <button
            className="button-size bg-gray-btn-secondary mr-4"
            onClick={handleBackToStep1}
          >
            戻る
          </button>

          <button
            className={`button-size rounded ${
              menuList.length > 0
                ? "bg-blue-btn-primary"
                : "bg-gray-btn-secondary cursor-default"
            }  `}
            onClick={handleClickNext}
          >
            次へ
          </button>
        </div>
      </div>

      {/* <div className="w-full h-full pb-12 relative">
        <Grid container spacing={2} className="w-full h-full">
          <Grid item xs={5}>
            <div className="w-full h-full flex flex-col items-center justify-center gap-6">
              <h3 className="text-lg font-semibold text-center">
                予約するメニューを選んでください
              </h3>

              <div className="flex flex-col items-center justify-center gap-6">
                {product_category_and_label.map((category) => (
                  <CategoryItem
                    key={category.key}
                    title={category.label}
                    onClick={() => {
                      setShowSelectMenu(category.key);
                    }}
                  />
                ))}
              </div>
            </div>
          </Grid>
          <Grid item xs={7} className="h-full overflow-hidden">
            <div className="w-full h-full border border-gray-400 rounded-md px-2 pt-4 pb-16 relative">
              <div className="w-full h-full overflow-auto">
                <div className="w-full">
                  {menuList.map((item) => (
                    <SelectedServiceItem
                      menuItem={item}
                      handleDelete={() => handleDeleteItem(item)}
                    />
                  ))}
                </div>
              </div>

              <div className="w-full h-14 absolute inset-x-0 bottom-0 px-2">
                <div className="w-full h-full border-t-2 border-gray-400">
                  <div className="w-full h-12 rounded-md mb-2 pr-12 pl-2 font-semibold">
                    <div className="w-full h-full flex">
                      <div className="w-5/12 flex items-center justify-end">
                        合計
                      </div>
                      <div className="w-1/3 flex items-center justify-end">
                        <p>
                          {menuList.reduce((a, b) => a + b.durationNumber, 0)}{" "}
                          分
                        </p>
                      </div>
                      <div className="w-1/4 flex items-center justify-end">
                        <p>
                          {formatPrice(
                            menuList.reduce((a, b) => a + b.priceTaxInc, 0)
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
        <div className="w-full h-8 flex items-center justify-end text-white text-base font-semibold absolute inset-x-0 bottom-0">
          <button
            className="button-size bg-gray-btn-secondary mr-4"
            onClick={handleBackToStep1}
          >
            戻る
          </button>

          <button
            className={`button-size ${
              menuList.length > 0
                ? "bg-blue-btn-primary"
                : "bg-gray-btn-secondary cursor-default"
            }  `}
            onClick={handleClickNext}
          >
            次へ
          </button>
        </div>
      </div> */}

      <SelectServiceDialog
        menuType={showSelectMenu}
        handleClose={() => setShowSelectMenu("")}
        handleSelectService={handleSelectService}
      />
    </>
  );
}

export default Step2SelectMenu;
