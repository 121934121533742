import React, { useCallback, useMemo, useState } from "react";

import moment from "moment";
import { defaultDateFormat } from "app/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

function DateItem(props) {
  const {
    day,
    dayIndex,
    staffShiftPlans,
    selectedDates,
    tempShiftPlans,
    handleSelectDate,
    handleDeleteShiftPlanTepms,
    readonly,
  } = props;

  const staffShiftPlanOfDate = useMemo(() => {
    if (!Array.isArray(staffShiftPlans) || staffShiftPlans.length === 0)
      return null;

    return staffShiftPlans.find(
      (item) =>
        item.workDate &&
        item.workDate.format(defaultDateFormat) ===
          day.format(defaultDateFormat)
    );
  }, [day, staffShiftPlans]);

  const staffShiftPlanOfDateTemp = useMemo(() => {
    if (!Array.isArray(tempShiftPlans) || tempShiftPlans.length === 0)
      return null;

    return tempShiftPlans.find(
      (item) =>
        item.workDate &&
        item.workDate.format(defaultDateFormat) ===
          day.format(defaultDateFormat)
    );
  }, [day, tempShiftPlans]);

  const isSelectedDate = useMemo(() => {
    if (!Array.isArray(selectedDates) || selectedDates.length === 0)
      return false;

    return selectedDates.find(
      (item) => item.format(defaultDateFormat) === day.format(defaultDateFormat)
    );
  }, [day, selectedDates]);

  const now = moment();

  const handleClickDate = () => {
    if (readonly) return;
    if (staffShiftPlanOfDate || staffShiftPlanOfDateTemp) return;
    handleSelectDate(day);
    console.log("asdfasdfasdfasdfasdfasdfs");
  };

  return (
    <button
      type="button"
      className={`block w-full h-full relative pt-7 px-1 ${
        staffShiftPlanOfDate
          ? "!bg-slate-300 cursor-default"
          : "bg-slate-100 hover:bg-slate-200"
      }
      ${readonly ? "!cursor-default" : ""}`}
      onClick={handleClickDate}
    >
      <span
        className={`w-5 h-5 absolute top-0.5 left-1 flex items-center justify-center text-xs font-semibold rounded-full ${
          dayIndex === 0
            ? "text-red-500"
            : dayIndex === 6
            ? "text-blue-400"
            : "text-gray-600"
        } ${
          now.format(defaultDateFormat) === day.format(defaultDateFormat)
            ? "bg-blue-500 !text-white"
            : ""
        }`}
      >
        {day.format("DD")}
      </span>
      {isSelectedDate ? (
        <span className="w-6 h-6 absolute top-1 right-1 flex items-center justify-center rounded-full text-xs text-blue-400">
          <FontAwesomeIcon icon={faCircleCheck} size="xl" />
        </span>
      ) : null}

      {staffShiftPlanOfDate ? (
        <div className="text-xs">
          {staffShiftPlanOfDate.startTime} - {staffShiftPlanOfDate.finishTime}
        </div>
      ) : null}
      {staffShiftPlanOfDateTemp ? (
        <>
          <button
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleDeleteShiftPlanTepms(staffShiftPlanOfDateTemp);
            }}
            className="w-6 h-6 absolute top-1 right-1 flex items-center justify-center rounded-full text-xs text-red-400"
          >
            <FontAwesomeIcon icon={faTrash} size="lg" />
          </button>
          <div className="text-xs">
            {staffShiftPlanOfDateTemp.startTime} -{" "}
            {staffShiftPlanOfDateTemp.finishTime}
          </div>
        </>
      ) : null}
    </button>
  );
}

function CustomDatePickerContent(props) {
  const {
    showMonth,
    selectedDates,
    handleSelectDate,
    tempShiftPlans,
    staffShiftPlans,
    handleDeleteShiftPlanTepms,
    readonly,
    daySize,
  } = props;

  const listDateOfMonth = useMemo(() => {
    if (!showMonth) return [];

    const isExtraDays = (week, date) => {
      if (week === 0 && date > 10) {
        return true;
      } else if (week === 5 && date < 10) {
        return true;
      } else if (week === 4 && date < 10) {
        return true;
      } else {
        return false;
      }
    };

    const result = [];

    const startDate = moment([showMonth.year(), showMonth.month()])
      .clone()
      .startOf("month")
      .startOf("week");

    const endDate = moment([showMonth.year(), showMonth.month()])
      .clone()
      .endOf("month");

    const day = startDate.clone().subtract(1, "day");

    while (day.isBefore(endDate, "day")) {
      result.push(
        Array(7)
          .fill(0)
          .map(() => {
            const tempDate = day.add(1, "day").clone();
            return !isExtraDays(result.length, Number(tempDate.format("DD")))
              ? tempDate
              : null;
          })
      );
    }
    return result;
  }, [showMonth]);

  return listDateOfMonth.map((week) => (
    <tr className="">
      {week.map((day, dayIndex) => (
        <td
          className="w-16 h-16 border-2 border-white"
          style={{ width: `${daySize}px`, height: `${daySize}px` }}
        >
          {day ? (
            <DateItem
              key={dayIndex}
              day={day}
              dayIndex={dayIndex}
              handleSelectDate={handleSelectDate}
              selectedDates={selectedDates}
              staffShiftPlans={staffShiftPlans}
              tempShiftPlans={tempShiftPlans}
              handleDeleteShiftPlanTepms={handleDeleteShiftPlanTepms}
              readonly={readonly}
            />
          ) : null}
        </td>
      ))}
    </tr>
  ));
}

function CustomDatePicker(props) {
  const {
    showMonth,
    selectedDates,
    handleSelectDate,
    tempShiftPlans,
    staffShiftPlans,
    handleDeleteShiftPlanTepms,
    readonly,
    daySize = 64,
  } = props;

  return showMonth ? (
    <div className="">
      <div className="w-full h-auto flex justify-start-start px-3 mb-2">
        <h2 className="text-xl font-bold mx-3">
          {showMonth ? showMonth.format("YYYY年MM月") : ""}
        </h2>
      </div>
      <div className="w-full h-full flex items-center justify-center">
        <table>
          <tr className="">
            {["日", "月", "火", "水", "木", "金", "土"].map(
              (dayTitle, dayIndex) => (
                <td
                  className="w-16 h-8"
                  style={{ width: `${daySize}px`, height: `${daySize / 2}px` }}
                >
                  <p className="w-full h-full flex items-center justify-center font-semibold">
                    <span
                      className={`text-gray-600 ${
                        dayIndex === 0
                          ? "!text-red-500"
                          : dayIndex === 6
                          ? "!text-blue-400"
                          : ""
                      }`}
                    >
                      {dayTitle}
                    </span>
                  </p>
                </td>
              )
            )}
          </tr>
          <CustomDatePickerContent
            showMonth={showMonth}
            selectedDates={selectedDates}
            handleSelectDate={handleSelectDate}
            tempShiftPlans={tempShiftPlans}
            staffShiftPlans={staffShiftPlans}
            handleDeleteShiftPlanTepms={handleDeleteShiftPlanTepms}
            readonly={readonly}
            daySize={daySize}
          />
        </table>        
      </div>

    </div>
  ) : null;
}

export default CustomDatePicker;
