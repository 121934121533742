import React, { useState } from "react";
import { Grid } from "@mui/material";
import MainMenuItem from "app/components/MainMenuItem";
import PaymentDiscountDialog from "./PaymentDiscountDialog";

function InputPaymentDiscount(props) {
  const { discount, setDiscount } = props;

  const [showDiscountDialog, setShowDiscountDialog] = useState(false);

  const handleCloseDiscountDialog = () => {
    setShowDiscountDialog(false);
  };

  const handleOpenDiscountDialog = () => {
    setShowDiscountDialog(true);
  };

  return (
    <>
      <div className="w-full h-auto flex items-center flex-wrap">
        <div className="w-auto sm:w-5/12">
          <div className="flex flex-col items-end">
            <div className="bg-stone-500 text-white font-medium p-3 rounded-full flex justify-center mb-3 w-[160px]">
              割引き
            </div>
          </div>
        </div>
        <div className="w-auto sm:w-7/12 pl-4">
          <div className="flex gap-4 mb-3 ">
            <MainMenuItem
              itemTitle={
                <>
                  <p className="text-xs font-medium">詳細へ</p>
                </>
              }
              onClick={handleOpenDiscountDialog}
              borderSize={4}
              size={50}
              isActive={showDiscountDialog}
            />
          </div>
        </div>
      </div>

      <PaymentDiscountDialog
        open={showDiscountDialog}
        handleClose={handleCloseDiscountDialog}
        discountProps={discount}
        setDiscountProps={setDiscount}
      />
    </>
  );
}

export default InputPaymentDiscount;
