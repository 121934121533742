import React, { useCallback, useEffect, useRef, useState } from "react";
import { CircularProgress, Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faXmark } from "@fortawesome/free-solid-svg-icons";

import { searchCustomerInfo } from "../../../services/api";
import { getNameFromFirstLast } from "../../../utils";

function SearchResultItem(props) {
  const { customInfo, handleClickSelect } = props;

  return (
    <div className="w-full h-10 mb-1 flex items-center gap-3 px-2 bg-slate-200 rounded-md">
      <p className="flex-1 truncate">{getNameFromFirstLast(customInfo)}</p>

      <button
        type="button"
        onClick={handleClickSelect}
        className="h-6 w-16 shrink-0 flex items-center justify-center text-sm text-white rounded bg-blue-btn-primary"
      >
        次へ
      </button>
    </div>
  );
}

function SearchResultBox(props) {
  const { searchText, handleSelectCustomer } = props;
  const [callSearchState, setCallSearchState] = useState({
    isLoading: true,
    data: [],
    error: null,
  });

  const handleCallSearch = useCallback(() => {
    setCallSearchState((oldState) => ({ ...oldState, isLoading: true }));

    searchCustomerInfo(searchText)
      .then((res) => {
        setCallSearchState({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        setCallSearchState({
          isLoading: false,
          data: [],
          error: error,
        });
      });
  }, [searchText]);

  useEffect(() => {
    handleCallSearch();
  }, [handleCallSearch]);

  if (callSearchState.isLoading)
    return (
      <div className="w-full h-full flex items-center justify-center">
        <CircularProgress size={40} />
      </div>
    );

  if (Array.isArray(callSearchState.data) && callSearchState.data.length === 0)
    return (
      <div className="w-full h-full flex items-center justify-center">
        <p className="text-base font-semibold">NotFound Data</p>
      </div>
    );

  if (Array.isArray(callSearchState.data) && callSearchState.data.length > 0)
    return (
      <div className="w-full h-full overflow-auto">
        {callSearchState.data.map((customer) => (
          <SearchResultItem
            key={customer.id}
            customInfo={customer}
            handleClickSelect={() => handleSelectCustomer(customer)}
          />
        ))}
      </div>
    );
}

function Step1SelectCustomer(props) {
  const { handleNextToStep2 } = props;
  const [searchText, setSearchText] = useState("");
  const [inputName, setInputName] = useState("");
  const [focusSearch, setFocusSearch] = useState(false);

  const setSearchTextTimeoutRef = useRef(null);
  const searchBoxInputRef = useRef(null);

  useEffect(() => {
    const handleClick = (e) => {
      const searchBox = document.getElementById("search-box");
      const searchResult = document.getElementById("search-result");
      const resetSearchBtn = document.getElementById("reset-search-btn");
      if (!searchBox || !searchResult || !resetSearchBtn) return;

      const clickFocusSearch =
        searchBox.contains(e.target) || searchResult.contains(e.target);
      const clickReset = resetSearchBtn.contains(e.target);

      if (!clickFocusSearch || clickReset) {
        setFocusSearch(false);
      }
    };
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  const clearSearchText = () => {
    setSearchText("");
    if (searchBoxInputRef.current) searchBoxInputRef.current.value = "";
  };

  const handleSearchInputChange = (e) => {
    if (setSearchTextTimeoutRef.current) {
      clearTimeout(setSearchTextTimeoutRef.current);
    }
    const newValue = e.currentTarget.value;
    setSearchTextTimeoutRef.current = setTimeout(
      () => setSearchText(newValue),
      500
    );
  };

  const handleSelectCustomer = (customerInfo) => {
    handleNextToStep2(customerInfo);
  };

  return (
    <div className="w-full h-full flex flex-col items-center gap-y-4">
      <div className="w-full h-auto flex flex-col items-center">
        <div className="text-lg font-semibold mb-8">
          予約するお客様を選択してください。
        </div>
        <div className="w-full max-w-[460px] h-10 relative" id="search-box">
          <div className="absolute top-1 left-1 w-8 h-8 flex items-center justify-center">
            <FontAwesomeIcon icon={faMagnifyingGlass} />
          </div>
          <input
            onChange={handleSearchInputChange}
            ref={searchBoxInputRef}
            className="w-full h-full px-10 py-1 border border-gray-300 focus:shadow-md focus:border-gray-500 rounded-full"
            onFocus={() => setFocusSearch(true)}
          />

          <button
            id="reset-search-btn"
            onClick={clearSearchText}
            className={`absolute right-2.5 top-2.5 w-5 h-5 flex items-center justify-center bg-gray-800 text-white rounded-full ${
              searchText ? "block" : "hidden"
            }`}
          >
            <FontAwesomeIcon icon={faXmark} />
          </button>
        </div>
      </div>

      <div
        className={`flex-1 flex flex-col items-center justify-center ${
          focusSearch ? "hidden" : "flex"
        }`}
      >
        <div className="text-lg font-semibold mb-8">
          新規のお客様は氏名を入力して下さい。
        </div>
        <div className="w-full max-w-[460px] h-8 flex items-center justify-between">
          <input
            type="text"
            value={inputName}
            onChange={(e) => setInputName(e.target.value)}
            className="w-[calc(100%-100px)] h-full px-4 py-1 border border-gray-300 focus:shadow-md focus:border-gray-500 rounded"
          />

          <Tooltip
            title={
              inputName && inputName.trim()
                ? ""
                : "お客様のお名前を入力してください"
            }
          >
            <button
              type="button"
              onClick={() => {
                if (inputName && inputName.trim())
                  handleSelectCustomer({
                    firstNameKata: inputName,
                  });
              }}
              className={`w-20 h-8 flex items-center justify-center rounded ${
                inputName && inputName.trim()
                  ? "bg-blue-btn-primary"
                  : "bg-gray-btn-secondary"
              } text-white`}
            >
              次へ
            </button>
          </Tooltip>
        </div>
      </div>

      <div
        className={`flex-1 w-full max-w-[460px] relative ${
          !focusSearch ? "hidden" : "block"
        }`}
      >
        <div
          id="search-result"
          className="absolute inset-0 w-full h-full p-2 bg-slate-100 rounded-md"
        >
          <SearchResultBox
            searchText={searchText}
            handleSelectCustomer={handleSelectCustomer}
          />
        </div>
      </div>
    </div>
  );
}

export default Step1SelectCustomer;
