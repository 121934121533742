import React, { useEffect, useMemo, useState } from "react";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import CommonDialog from "../../../components/CommonDialog";
import InputNumberVirtualKeyBoard from "../../../components/InputNumberVirtualKeyBoard";
import { formatPrice } from "../../../utils";
import { discount_type } from "../../../constants";

function PaymentDiscountType(props) {
  const { discount, setDiscount } = props;
  const handleChange = (event) => {
    setDiscount((state) => ({
      discountType: event.target.value,
      discountAmount: state?.discountAmount || 0,
    }));
  };

  return (
    <RadioGroup key={discount?.discountType} value={discount?.discountType} onChange={handleChange}>
      <FormControlLabel
        value={discount_type.point_usage}
        control={<Radio />}
        label="ポイント使用"
      />
      <FormControlLabel
        value={discount_type.speical_discount}
        control={<Radio />}
        label="特別割引"
      />
      <FormControlLabel
        value={discount_type.other_discount}
        control={<Radio />}
        label="その他の割引"
      />
    </RadioGroup>
  );
}

function PaymentDiscountAmount(props) {
  const { backToSelectType, handleConfirmDiscount, discount, setDiscount } =
    props;
  const discountAmount = useMemo(
    () => discount?.discountAmount || 0,
    [discount]
  );

  const handleChange = (newAmount) => {
    setDiscount({ ...discount, discountAmount: newAmount });
  };
  return (
    <div className="w-full h-auto flex flex-col">
      <div className="w-full h-auto flex items-center justify-between text-3xl font-semibold pt-2 pb-10">
        <p className="font-semibold shrink-0">特別割引</p>

        <p className="font-bold text-green-500">
          <span>-</span>
          <span>{formatPrice(discountAmount)}</span>
        </p>
      </div>

      <div className="w-full h-auto flex items-end justify-center sm:justify-between flex-wrap">
        {/* calculator */}
        <div className="w-full sm:w-auto flex justify-center mb-6 sm:mb-0">
          <InputNumberVirtualKeyBoard
            value={discountAmount}
            handleChange={handleChange}
          />
        </div>

        {/* action */}
        <div className="w-full sm:w-auto flex items-center justify-end text-white text-base font-semibold">
          <button
            onClick={backToSelectType}
            type="button"
            className="button-size bg-gray-btn-secondary mr-4 lg:mr-6"
          >
            戻る
          </button>
          <button
            onClick={handleConfirmDiscount}
            type="button"
            className="button-size bg-blue-btn-primary"
          >
            進む
          </button>
        </div>
      </div>
    </div>
  );
}

function PaymentDiscountDialog(props) {
  const { open, handleClose, discountProps, setDiscountProps } = props;

  const [discount, setDiscount] = useState({
    discountType: "",
    discountAmount: 0,
  });
  const [showDiscountAmountInput, setShowDiscountAmountInput] = useState(false);

  useEffect(() => {
    if (open) {
      setDiscount(discountProps || { discountType: "", discountAmount: 0 });
    }
  }, [open]);

  const handleNextToInputAmount = () => {
    if (discount.discountType) {
      setShowDiscountAmountInput(true);
    }
  };

  const resetDialog = () => {
    setShowDiscountAmountInput(false);
    setDiscount({
      discountType: "",
      discountAmount: 0,
    });
  };

  const handleConfirmDiscount = () => {
    setDiscountProps(discount);
    handleClose();
    resetDialog();
  };

  return (
    <CommonDialog
      open={open}
      handleClose={() => {
        resetDialog();
        handleClose();
      }}
      title={
        showDiscountAmountInput
          ? "割引額を確認および入力してください、"
          : "割引きを選択してください。"
      }
      actions={
        showDiscountAmountInput ? (
          <div> </div>
        ) : (
          <div className="w-full flex items-center justify-end text-white text-base font-semibold">
            <button
              onClick={handleNextToInputAmount}
              type="button"
              className="button-size bg-blue-btn-primary"
            >
              進む
            </button>
          </div>
        )
      }
    >
      <div className="w-full h-auto py-4">
        {!discount.discountType || !showDiscountAmountInput ? (
          <PaymentDiscountType discount={discount} setDiscount={setDiscount} />
        ) : (
          <PaymentDiscountAmount
            discount={discount}
            setDiscount={setDiscount}
            backToSelectType={() => setShowDiscountAmountInput(false)}
            handleNextToInputAmount={handleNextToInputAmount}
            handleConfirmDiscount={handleConfirmDiscount}
          />
        )}
      </div>
    </CommonDialog>
  );
}

export default PaymentDiscountDialog;
