import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { fake_history_order_list } from "../../../../services/api/fake_services/fake_data";
import Path from "../../../../route/Path";
import DefaultLayout from "../../../../layouts/DefaultLayout";
import MainContentLayout from "../../../../layouts/MainContentLayout";
import LoadingProgress from "../../../../components/commonUI/LoadingProgress";
import LoadInitError from "../../../../components/commonUI/LoadInitError";
import InputField from "../../../../components/commonUI/InputField";
import { getHistoryOfOrderingDetail } from "../../../../services/api/1C";
import moment from "moment";
import NoData from "../../../../components/commonUI/NoData";
import TableHistoryOrdering from "../TableHistoryOrdering";
import TableScalpDataList from "./TableScalpDataList";

function HistoryOfOrderDetailPage() {
  const customerNo = useParams();

  var isDetail = true;
  const [fetchHistoryOrderDetail, setFetchHistoryOrderDetail] = useState({
    isLoading: false,
    data: null,
    error: null,
  });

  const loadHistoryOrderDetail = () => {
    setFetchHistoryOrderDetail({
      isLoading: true,
      data: null,
      error: null,
    });
    getHistoryOfOrderingDetail(customerNo.customerNo)
      .then((res) => {
        setFetchHistoryOrderDetail({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        setFetchHistoryOrderDetail({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  };

  useEffect(() => {
    loadHistoryOrderDetail();
  }, []);

  return (
    <DefaultLayout>
      <MainContentLayout
        pageTitle={
          <>
            <p>ラボ</p>
          </>
        }
      >
        <div className="w-full h-full mx-auto relative max-h-full flex flex-wrap pt-16 pb-8 lg:py-12">
          <div className="w-full h-16 lg:h-12 pl-20 lg:pl-0 flex items-center justify-start absolute inset-x-0 top-0 bg-white">
            <h2 className="text-base lg:text-xl font-semibold">頭皮ラボ詳細</h2>
          </div>
          <div className="w-full h-full py-3">
            <div className="w-full h-full pr-3 border border-gray-400 rounded-2xl p-4">
              <div className="w-full h-full overflow-auto">
                {fetchHistoryOrderDetail.isLoading ? <LoadingProgress /> : null}

                {!fetchHistoryOrderDetail.isLoading &&
                fetchHistoryOrderDetail.error &&
                fetchHistoryOrderDetail.data?.inspectionKitId ? (
                  <LoadInitError error={fetchHistoryOrderDetail.error} />
                ) : null}
                {!fetchHistoryOrderDetail.isLoading &&
                !fetchHistoryOrderDetail.error ? (
                  Array.isArray(fetchHistoryOrderDetail.data) &&
                  fetchHistoryOrderDetail.data.length > 0 ? (
                    <TableScalpDataList
                      allHistoryOrdering={fetchHistoryOrderDetail.data}
                    />
                  ) : (
                    <NoData />
                  )
                ) : (
                  <NoData />
                )}
              </div>
            </div>
          </div>

          {/* <div className="w-full h-full py-3">
            <div className="w-full h-full pr-3 border border-gray-400 rounded-2xl p-4">
              <div className="w-full h-full overflow-auto">
                {fetchHistoryOrderDetail.isLoading ? <LoadingProgress /> : null}

                {!fetchHistoryOrderDetail.isLoading &&
                fetchHistoryOrderDetail.error &&
                fetchHistoryOrderDetail.data?.inspectionKitId ? (
                  <LoadInitError error={fetchHistoryOrderDetail.error} />
                ) : null}
                {!fetchHistoryOrderDetail.isLoading &&
                !fetchHistoryOrderDetail.error ? (
                  <div className="lg:flex gap-6 h-full">
                    <div className="w-full lg:w-1/2 flex flex-col h-1/2 lg:h-full gap-6">
                      <InputField
                        label="お客様No."
                        name="customerNo"
                        value={fetchHistoryOrderDetail.data?.customerNo}
                        disabled={true}
                        labelClasses="!w-24"
                      />
                      <InputField
                        label="お客様名"
                        name="customerName"
                        value={
                          fetchHistoryOrderDetail.data?.scalpData.customerName
                        }
                        disabled={true}
                        labelClasses="!w-24"
                      />
                      <InputField
                        label="発送時間"
                        name="shippingDateTime"
                        value={moment(
                          fetchHistoryOrderDetail.data?.scalpData?.deliverDate
                        ).format("YYYY-MM-DD HH:mm")}
                        disabled={true}
                        labelClasses="!w-24"
                      />
                      <InputField
                        label="状況"
                        name="situation"
                        value={
                          // fetchHistoryOrderDetail.data?.scalpData
                          //   ?.analyseStatus
                          //   ? fetchHistoryOrderDetail.data?.scalpData
                          //       ?.analyseStatus
                          //   : "pending"
                          fetchHistoryOrderDetail.data?.scalpData
                            .analyseStatus == "analyse_not_started"
                            ? "未分析"
                            : fetchHistoryOrderDetail.data?.scalpData
                                .analyseStatus == "analyse_in_progress"
                            ? "分析中"
                            : fetchHistoryOrderDetail.data?.scalpData
                                .analyseStatus == "analyse_completed"
                            ? "分析完了"
                            : ""
                        }
                        disabled={true}
                        labelClasses="!w-24"
                      />
                    </div>

                    <div className="w-full h-1/2 lg:h-full rounded-md border border-2 p-4 overflow-auto">
                      {fetchHistoryOrderDetail.data?.scalpData
                        ?.analyseStatus === "analyse_completed" ? (
                        <>
                          <p className="whitespace-nowrap px-6 py-4">
                            クラスター　：　
                            {fetchHistoryOrderDetail.data?.scalpData.factorCluster.toFixed(
                              1
                            )}
                          </p>
                          <p className="whitespace-nowrap px-6 py-4">
                            細胞面積　：　
                            {fetchHistoryOrderDetail.data?.scalpData.factorCellArea.toFixed(
                              1
                            )}
                          </p>
                          <p className="whitespace-nowrap px-6 py-4">
                            多重剥離　：　
                            {fetchHistoryOrderDetail.data?.scalpData.factorPeeling.toFixed(
                              1
                            )}
                          </p>
                          <p className="whitespace-nowrap px-6 py-4">
                            CP　：　
                            {fetchHistoryOrderDetail.data?.scalpData.factorCP.toFixed(
                              1
                            )}
                          </p>
                          <p className="whitespace-nowrap px-6 py-4">
                            総合　：　
                            {fetchHistoryOrderDetail.data?.scalpData.factorScore.toFixed(
                              1
                            )}
                          </p>
                        </>
                      ) : (
                        "まだ分析終わってないか、また検体がないか"
                      )}
                    </div>
                  </div>
                ) : (
                  <NoData />
                )}
              </div>
            </div>
          </div> */}
          <div className="w-full h-8 flex items-center justify-end text-white text-base font-semibold absolute inset-x-0 bottom-0">
            <Link to={-1} className="button-size bg-gray-btn-secondary">
              戻る
            </Link>
          </div>
        </div>
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default HistoryOfOrderDetailPage;
