import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import { v4 } from "uuid";

import DefaultLayout from "app/layouts/DefaultLayout";
import MainContentLayout from "app/layouts/MainContentLayout";
import LoadingBackdrop from "app/components/commonUI/LoadingBackdrop";
import LoadingProgress from "app/components/commonUI/LoadingProgress";
import LoadInitError from "app/components/commonUI/LoadInitError";
import CreateCompleteDialog from "app/components/CommonDialog/CreateCompleteDialog";
import defaultAvatar from "app/assets/svg/default_avatar.svg";

import { defaultDateFormat, showMonthStrFormat } from "app/constants";
import { convertTimeToNumber } from "app/utils";
import Path from "app/route/Path";
import {
  getAllStaffShiftPlans,
  registerStaffShiftPlans,
} from "app/services/api";

import InputTimeFieldLarge from "../components/InputTimeFieldLarge";
import RegisterShiftPlanCalendar from "../components/RegisterShiftPlanCalendar";

function StaffManagementRegisterShiftPlanPageContent(props) {
  const { showMonth, staffShiftPlans, staffId } = props;
  const [inputShiftPlanTime, setInputShiftPlanTime] = useState({
    startTime: "",
    finishTime: "",
  });

  const [selectedDates, setSelectedDates] = useState([]);
  const [tempShiftPlans, setTempShiftPlans] = useState([]);
  const [callRegisterStatus, setCallRegisterStatus] = useState("idle");

  const navigate = useNavigate();

  const disableAddNewShiftPlanTepms = useMemo(() => {
    if (
      selectedDates.length === 0 ||
      !inputShiftPlanTime.startTime ||
      !inputShiftPlanTime.finishTime ||
      convertTimeToNumber(inputShiftPlanTime.startTime) >=
        convertTimeToNumber(inputShiftPlanTime.finishTime)
    )
      return true;
    return false;
  }, [selectedDates, inputShiftPlanTime]);

  const isNotValidTimeRange = useMemo(() => {
    if (
      inputShiftPlanTime.startTime &&
      inputShiftPlanTime.finishTime &&
      convertTimeToNumber(inputShiftPlanTime.startTime) >=
        convertTimeToNumber(inputShiftPlanTime.finishTime)
    )
      return true;
    return false;
  }, [inputShiftPlanTime]);

  const handleSelectDate = (day) => {
    setSelectedDates((oldList) => {
      if (
        oldList.find(
          (item) =>
            item.format(defaultDateFormat) === day.format(defaultDateFormat)
        )
      )
        return oldList.filter(
          (item) =>
            item.format(defaultDateFormat) !== day.format(defaultDateFormat)
        );
      return [...oldList, day];
    });
  };

  const handleAddNewShiftPlanTepms = () => {
    if (
      selectedDates.length === 0 ||
      !inputShiftPlanTime.startTime ||
      !inputShiftPlanTime.finishTime
    )
      return;

    const newShiftPlanTepms = selectedDates.map((date) => ({
      tempId: v4(),
      workDate: date,
      startTime: inputShiftPlanTime.startTime,
      finishTime: inputShiftPlanTime.finishTime,
    }));

    setTempShiftPlans((oldList) => [...oldList, ...newShiftPlanTepms]);
    setSelectedDates([]);
    setInputShiftPlanTime({
      startTime: "",
      finishTime: "",
    });
  };

  const handleDeleteShiftPlanTepms = (shiftPlanTepm) => {
    if (!shiftPlanTepm || !shiftPlanTepm.tempId) return;

    setTempShiftPlans((oldList) =>
      oldList.filter((item) => item.tempId !== shiftPlanTepm.tempId)
    );
  };

  const handleCallRegister = () => {
    if (tempShiftPlans.length === 0) return;
    const newShiftPlans = tempShiftPlans.map((item) => ({
      startTime: item.startTime,
      finishTime: item.finishTime,
      workDate: item.workDate.format(defaultDateFormat),
    }));
    setCallRegisterStatus("loading");

    registerStaffShiftPlans(staffId, newShiftPlans)
      .then(() => {
        toast.success("シフト登録成功");
        setCallRegisterStatus("success");
      })
      .catch((error) => {
        setCallRegisterStatus("シフト登録に失敗しました");
        setCallRegisterStatus("eror");
      });
  };

  return (
    <>
      <div className="w-full h-full flex justify-between overflow-auto min-w-[460px] flex-wrap lg:flex-nowrap">
        <div className="w-full h-auto lg:w-auto lg:h-full shrink-0 px-4 py-8 flex flex-col items-center">
          <img
            alt=""
            className="w-28 h-28 rounded-full overflow-hidden mb-6 object-cover"
            src={defaultAvatar}
          />

          <p className="w-28 h-auto truncate text-center">Staff A</p>
        </div>
        <div className="w-full h-auto lg:w-auto lg:h-full shrink-0">
          <h2 className="w-full h-auto text-2xl font-bold text-center mb-6">
            １．日を選択してください
          </h2>
          {/* <RegisterShiftPlanCalendar showMonth={{ year: 2023, month: 9 }} /> */}

          <RegisterShiftPlanCalendar
            showMonth={showMonth}
            selectedDates={selectedDates}
            handleSelectDate={handleSelectDate}
            staffShiftPlans={staffShiftPlans}
            tempShiftPlans={tempShiftPlans}
            handleDeleteShiftPlanTepms={handleDeleteShiftPlanTepms}
          />
        </div>
        <div className="w-full h-auto lg:flex-1 lg:w-full lg:h-full min-w-[200px] lg:max-w-[320px] ml-4 pt-8 lg:pt-0">
          <h2 className="w-full h-auto text-2xl font-bold text-center lg:mb-20">
            ２．時間を設定してください
          </h2>

          <div className="w-full h-auto flex flex-col mb-8 pt-3">
            <div className="w-full flex items-center mb-8">
              <div className="w-24 text-xl font-semibold">開始</div>
              <div className="w-[calc(100%)- 96px]">
                <InputTimeFieldLarge
                  time={inputShiftPlanTime.startTime}
                  setTime={(newStartTime) =>
                    setInputShiftPlanTime((oldState) => ({
                      ...oldState,
                      startTime: newStartTime,
                    }))
                  }
                  error={isNotValidTimeRange}
                />
              </div>
            </div>

            <div className="w-full h-10 flex items-center">
              <div className="w-24 text-xl font-semibold">終了</div>
              <div className="w-[calc(100%)- 96px]">
                <InputTimeFieldLarge
                  time={inputShiftPlanTime.finishTime}
                  setTime={(newFinishTime) =>
                    setInputShiftPlanTime((oldState) => ({
                      ...oldState,
                      finishTime: newFinishTime,
                    }))
                  }
                  error={isNotValidTimeRange}
                />
              </div>
            </div>
          </div>

          <div className="w-full h-auto flex  justify-end">
            <button
              type="button"
              onClick={handleAddNewShiftPlanTepms}
              className={`button-size ${
                !disableAddNewShiftPlanTepms
                  ? "bg-blue-btn-primary"
                  : "bg-gray-btn-secondary"
              }`}
            >
              追加
            </button>
          </div>
        </div>
      </div>

      <div className="w-full h-8 flex items-center justify-end absolute inset-x-0 bottom-0 bg-white">
        <Link
          to={Path.staffManagementSelectStaffRegisterShiftPlan}
          className="button-size bg-gray-btn-secondary mr-4"
        >
          戻る
        </Link>
        <button
          className={`button-size ${
            tempShiftPlans.length === 0
              ? "bg-gray-btn-secondary"
              : "bg-blue-btn-primary"
          }   text-white`}
          onClick={handleCallRegister}
        >
          登録を完了させる
        </button>
      </div>

      <LoadingBackdrop isOpen={callRegisterStatus === "loading"} />

      <CreateCompleteDialog
        open={callRegisterStatus === "success"}
        createCompleteTitle="シフトを登録しました"
        createCompleteBtnLabel2="終る"
        createCompleteAction2={() => {
          navigate(Path.staffManagementListShiftPlan(staffId));
        }}
      />
    </>
  );
}

function StaffManagementRegisterShiftPlanPage() {
  const [fetchInitState, setFetchInitState] = useState({
    isLoading: false,
    data: null,
    error: null,
  });

  const { staffId } = useParams();
  let [searchParams] = useSearchParams();

  const showMonth = useMemo(() => {
    const showMonthStr = searchParams.get("month");
    if (!showMonthStr) return moment();
    return moment(showMonthStr, showMonthStrFormat);
  }, [searchParams]);

  const loadAllShiftPlan = useCallback(() => {
    if (!showMonth) return;
    setFetchInitState({
      isLoading: true,
      data: null,
      error: null,
    });

    const startDateOfMonth =
      showMonth.format(defaultDateFormat.slice(0, 7)) + "-01";
    const startDateOfNextMonth =
      showMonth.clone().add(1, "M").format(defaultDateFormat.slice(0, 7)) +
      "-01";

    getAllStaffShiftPlans(staffId, startDateOfMonth, startDateOfNextMonth)
      .then((res) => {
        const data = res.data;

        setFetchInitState({
          isLoading: false,
          data: data.map((item) => ({
            ...item,
            workDate: moment(item.workDate),
          })),
          error: null,
        });
      })
      .catch((error) => {
        setFetchInitState({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  }, [showMonth, staffId]);

  useEffect(() => {
    loadAllShiftPlan();
  }, [loadAllShiftPlan]);

  return (
    <DefaultLayout>
      <MainContentLayout pageTitle={<p>勤怠</p>}>
        <div className="w-full h-full pt-16 pb-8 lg:py-12 relative">
          <div className="w-full h-16 lg:h-12 pl-20 lg:pl-0 flex items-center justify-start absolute inset-x-0 top-0 bg-white">
            <h2 className="text-base lg:text-xl font-semibold">シフト作成</h2>
          </div>

          <div className="w-full h-full py-3">
            <div className="w-full h-full overflow-auto">
              {fetchInitState.isLoading ? <LoadingProgress /> : null}

              {!fetchInitState.isLoading && fetchInitState.error ? (
                <LoadInitError error={fetchInitState.error} />
              ) : null}
              {!fetchInitState.isLoading &&
              !fetchInitState.error &&
              Array.isArray(fetchInitState.data) ? (
                <StaffManagementRegisterShiftPlanPageContent
                  staffShiftPlans={fetchInitState.data}
                  showMonth={showMonth}
                  staffId={staffId}
                />
              ) : null}
            </div>
          </div>
        </div>
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default StaffManagementRegisterShiftPlanPage;
