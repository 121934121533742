import React from "react";
import RegisterMenuFormPage from "../../RegisterMenuFormPage";
import { useParams } from "react-router-dom";

function EditMenuFormPage() {
  const menuId = useParams();
  return <RegisterMenuFormPage menuId={menuId.menuId} />;
}

export default EditMenuFormPage;
