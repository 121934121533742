export const defaultDateFormat = "YYYY-MM-DD";
export const defaultDateTimeFormat = "YYYY-MM-DD HH:mm";
export const jpDateFormat = "YYYY年MM月DD日";
export const jpDateTimeFormat = "YYYY年MM月DD日 HH:mm";
export const defaultTimeFormat = "HH:mm";
export const showMonthStrFormat = "YYYY-MM";

export const chartDataTypeOptions = [
  {
    key: "by_year",
    label: "年別",
    timeFormat: "YYYY",
  },
  {
    key: "by_month",
    label: "月別",
    timeFormat: "YYYY-MM",
  },
  {
    key: "by_day",
    label: "日別",
    timeFormat: "YYYY-MM-DD",
  },
  {
    key: "by_week",
    label: "年別",
    timeFormat: "YYYY-MM-DD", // TODO-T:
  },
  {
    key: "by_day_of_week",
    label: "曜日別",
    timeFormat: "YYYY-MM-DD", // TODO-T:
  },
  {
    key: "by_hour",
    label: "時間別",
    timeFormat: "YYYY-MM-DD HH",
  },
];

export const jpWeekDayLabel = ["日", "月", "火", "水", "木", "金", "土"];

export const account_status = {
  active: "active",
  frozen: "frozen",
};

export const beauty_salon_account_type = {
  admin: "admin",
  staff: "staff",
};

export const scalp_lab_account_type = {
  manager: "manager",
  staff: "staff",
  agent: "agent",
  dcr: "dcr",
};

export const payment_type = {
  cash: "cash",
  credit_card: "credit_card",
  qr_code: "qr_code",
  eletronic_payment: "eletronic_payment",
};

export const payment_type_and_method = {
  cash: {
    payment_type: payment_type.cash,
    payment_type_label: "現金",
    payment_methods: [{ payment_method: "cash", payment_method_label: "現金" }],
  },
  credit_card: {
    payment_type: payment_type.credit_card,
    payment_type_label: "クレジットカード",
    payment_methods: [
      { payment_method: "visa", payment_method_label: "VISA" },
      { payment_method: "jbc", payment_method_label: "JBC" },
      { payment_method: "master", payment_method_label: "Master" },
      { payment_method: "amex", payment_method_label: "AMEX" },
      { payment_method: "other", payment_method_label: "Other" },
    ],
  },
  qr_code: {
    payment_type: payment_type.qr_code,
    payment_type_label: "QR決済",
    payment_methods: [
      { payment_method: "paypay", payment_method_label: "PayPay" },
      { payment_method: "line_pay", payment_method_label: "LINE Pay" },
      {
        payment_method: "rakuten_pay",
        payment_method_label: "楽天Pay",
      },
      { payment_method: "qr_code1", payment_method_label: "メルベイ" }, //TODO-T: change payment_method value
      { payment_method: "other", payment_method_label: "Other" },
    ],
  },
  eletronic_payment: {
    payment_type: payment_type.eletronic_payment,
    payment_type_label: "電子マネー",
    payment_methods: [
      { payment_method: "eletronic_payment1", payment_method_label: "交通係" }, //TODO-T: change payment_method value
      { payment_method: "eletronic_payment2", payment_method_label: "流通係" }, //TODO-T: change payment_method value
      { payment_method: "other", payment_method_label: "Other" },
    ],
  },
};

export const discount_type = {
  point_usage: "point_usage",
  speical_discount: "speical_discount",
  other_discount: "other_discount",
};

export const publicity_status = {
  public: "public",
  private: "private",
};

export const impression_parts = {
  head: "head",
  upper_body: "upper_body",
  lower_body: "lower_body",
  other: "other",
};

export const product_category = {
  cut: "cut",
  color: "color",
  perm: "perm",
  straight: "straight",
  spa: "spa",
  treatment: "treatment",
  other: "other",
};

export const product_category_and_label = [
  { key: product_category.cut, label: "カット" },
  { key: product_category.color, label: "カラー" },
  { key: product_category.perm, label: "パーマ" },
  { key: product_category.straight, label: "ストレート" },
  { key: product_category.spa, label: "スパ" },
  { key: product_category.treatment, label: "トリートメント" },
  { key: product_category.other, label: "その他" },
];

export const menu_category = [
  {
    key: product_category.cut,
    label: "カット",
    dialogTitle: "カットコースを選んでください",
  },
  {
    key: product_category.color,
    label: "color",
    dialogTitle: "カットコースを選んでください",
  },
  {
    key: product_category.perm,
    label: "パーマ",
    dialogTitle: "パーマコースを選んでください",
  },
  {
    key: product_category.straight,
    label: "ストレート",
    dialogTitle: "ストレートコースを選んでください",
  },
  {
    key: product_category.spa,
    label: "スパ",
    dialogTitle: "スパコースを選んでください",
  },
  {
    key: product_category.treatment,
    label: "トリートメント",
    dialogTitle: "トリートメントコースを選んでください",
  },
  {
    key: product_category.other,
    label: "その他",
    dialogTitle: "コースを選んでください",
  },
];

export const scalp_lab_dm_status = {
  pending: "pending",
  sent: "sent",
};

export const contract_status = {
  creating: "creating",
  pending_reply: "pending_reply",
  expired: "expired",
  finished: "finished",
  abandoned: "abandoned",
};

export const accountTypes = {
  MANAGER: 1,
  STAFF: 2,
};

export const permissionCodes = {
  admin: "admin",
  login: "login",
  reservation: "reservation",
  customer: "customer",
  sales_confirm: "sales_confirm",
  sales_input: "sales_input",
  cash_register: "cash_register",
  cash_update: "cash_update",
  cash_delete: "cash_delete",
  cash_history: "cash_history",
  inventory: "inventory",
  attendance_input: "attendance_input",
  scalp_lab: "scalp_lab",
  dm_delivery_distribution: "dm_delivery_distribution",
};
