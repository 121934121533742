import * as React from "react";

import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "moment/locale/ja";

import { jpDateFormat } from "app/constants";

export default function SmallInputDateField(props) {
  const { value, setValue, disabled, ...rest } = props;

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="ja">
      <DatePicker
        value={value}
        onChange={(newValue) => setValue(newValue)}
        format={jpDateFormat}
        disabled={disabled}
        sx={{
          "& .MuiInputBase-input": {
            padding: "4px 0px 4px 8px !important",
            lineHeight: "24px !important",
            height: "24px !important",
            fontSize: "14px",
          },
          "& .Mui-disabled": {
            backgroundColor: "#f3f4f6 !important",
          },
          "& input.Mui-disabled ": {
            color: "#000 !important",
            "-webkit-text-fill-color": "#000 !important",
          },
        }}
        {...rest}
      />
    </LocalizationProvider>
  );
}
