import React from "react";
import AnalyzeBox from "./AnalyzeBox";
import CustomBarChart from "../../components/CustomBarChart";
import { useNavigate } from "react-router-dom";
import Path from "app/route/Path";

function CustomerVisitAnalyze(props) {
  const navigate = useNavigate();

  return (
    <AnalyzeBox
      title="来店客数"
      onClickTitle={() => navigate(Path.customerVisitAnalyzeManagement)}
    >
      <CustomBarChart />
    </AnalyzeBox>
  );
}

export default CustomerVisitAnalyze;
