import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LoadingProgress from "../../../../components/commonUI/LoadingProgress";
import LoadInitError from "../../../../components/commonUI/LoadInitError";
import DefaultLayout from "../../../../layouts/DefaultLayout";
import MainContentLayout from "../../../../layouts/MainContentLayout";
import { formatPaymentType, formatPrice } from "../../../../utils";
import Path from "../../../../route/Path";
import MainMenuItem from "../../../../components/MainMenuItem";
import {
  getDetailNonVisitChargedPayment,
  getDetailVisitChargedPayment,
} from "../../../../services/api/1E";
import moment from "moment";
import NoData from "../../../../components/commonUI/NoData";

function CashierManagementNonVisitDetailPage() {
  const cashierId = useParams();
  const navigate = useNavigate();

  const [fetchCashierDetailNonVisit, setFetchCashierDetailNonVisit] = useState({
    isLoading: false,
    data: null,
    error: null,
  });

  const loadHistoryOrderDetailNonVisit = () => {
    setFetchCashierDetailNonVisit({
      isLoading: true,
      data: null,
      error: null,
    });
    getDetailNonVisitChargedPayment(cashierId.cashierId)
      .then((res) => {
        setFetchCashierDetailNonVisit({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        setFetchCashierDetailNonVisit({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  };

  useEffect(() => {
    loadHistoryOrderDetailNonVisit();
  }, []);
  return (
    <DefaultLayout>
      <MainContentLayout
        pageTitle={
          <>
            <p>お客様の</p>
            <p>お帰り</p>
          </>
        }
      >
        <div className="w-full h-full pt-20 pb-8  lg:py-12 relative">
          <div className="w-full h-16 lg:h-12 pl-20 lg:pl-0 flex items-center justify-start absolute inset-x-0 top-0 bg-white">
            <h2 className="w-24 text-base lg:text-xl font-semibold">
              会計詳細
            </h2>
          </div>

          <div className="w-full max-h-full pr-3  p-4">
            <div className="w-full h-full ">
              {fetchCashierDetailNonVisit.isLoading ? (
                <LoadingProgress />
              ) : null}

              {!fetchCashierDetailNonVisit.isLoading &&
              fetchCashierDetailNonVisit.error ? (
                <LoadInitError error={fetchCashierDetailNonVisit.error} />
              ) : null}
              {!fetchCashierDetailNonVisit.isLoading &&
              !fetchCashierDetailNonVisit.error ? (
                <div className="w-full h-full flex flex-col gap-6">
                  <div className="w-full flex flex-col lg:flex-row justify-between border border-2 border-black">
                    <div className="flex items-center gap-2">
                      <div className="w-[112px] bg-gray-400 py-2 px-4 font-semibold">
                        会計日時
                      </div>
                      <div>
                        {moment(
                          fetchCashierDetailNonVisit.data?.paymentDate
                        ).format("YYYY-MM-DD HH:mm")}
                      </div>
                    </div>
                    {/* <div className="flex items-center gap-2">
                        <div className="w-[112px] bg-gray-400 py-2 px-4 font-semibold">
                          お客様名
                        </div>
                        <div>
                          {
                            fetchCashierDetailNonVisit.data?.salonCustomer?.customer
                              ?.firstNameKata
                          }{" "}
                          {
                            fetchCashierDetailNonVisit.data?.salonCustomer?.customer
                              ?.lastNameKata
                          }
                        </div>
                      </div>
                      <div className="flex items-center gap-2">
                        <div className="w-[112px] bg-gray-400 py-2 px-4 font-semibold">
                          レジ担当者
                        </div>
                        <div className="pr-2">
                          {fetchCashierDetailNonVisit.data?.staff?.firstNameKata}{" "}
                          {fetchCashierDetailNonVisit.data?.staff?.lastNameKata}
                        </div>
                      </div> */}
                  </div>
                  <div className="w-full h-full py-3">
                    <div className="w-full h-full pr-3">
                      <div className="w-full h-full flex flex-col lg:flex-row overflow-auto">
                        {/* <div className="w-full lg:w-1/2 h-auto flex flex-col pr-8 gap-4 gap-2">
                            {fetchCashierDetailNonVisit.data?.visitmenus.map((menu) => (
                              <div className="flex justify-between h-auto items-center ">
                                <button className="button-size text-white bg-[#667074]">
                                  {menu.name}
                                </button>
                                <div>{formatPrice(menu.priceTaxInc)}</div>
                              </div>
                            ))}
                          </div> */}

                        <div className="w-full  h-auto flex flex-col ">
                          <div className="w-full h-auto flex items-center justify-between mb-3 relative pr-9">
                            <div className="w-1/3 font-medium">小計</div>
                            <div>
                              {formatPrice(
                                fetchCashierDetailNonVisit.data?.paymentAmount
                              )}
                            </div>
                          </div>

                          {/* <div className="w-full h-auto flex items-center justify-between mb-3 relative pr-9">
                              <div className="">ご使用クーポン</div>
                              <div className="flex-1 text-end"></div>
                            </div> */}

                          <div className="border-2 mb-4" />

                          <div className="w-full h-auto flex items-center justify-between mb-8 relative text-lg font-bold pr-9">
                            <div className="w-1/3 font-medium">合計</div>
                            <div className="flex-1 text-end">
                              {formatPrice(
                                fetchCashierDetailNonVisit.data?.paymentAmount
                              )}
                            </div>
                          </div>
                          <div className="flex items-center justify-between mb-3">
                            <div className="w-1/3 font-medium">お支払方法</div>

                            <div className="flex items-center justify-end">
                              <MainMenuItem
                                itemTitle={
                                  <p className="text-xs font-medium">
                                    {formatPaymentType(
                                      fetchCashierDetailNonVisit.data
                                        ?.paymentType
                                    )}
                                  </p>
                                }
                                borderSize={4}
                                size={50}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <NoData />
              )}
            </div>
          </div>

          <div className="w-full h-8 flex items-center justify-end text-white text-base font-semibold absolute inset-x-0 bottom-0">
            <button
              className="button-size bg-gray-btn-secondary"
              onClick={() => navigate(Path.cashierManagementAll)}
            >
              戻る
            </button>
          </div>
        </div>
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default CashierManagementNonVisitDetailPage;
