import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { v4 } from "uuid";
import {
  faFilePen,
  faPencil,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import NoData from "app/components/commonUI/NoData";
import DefaultLayout from "app/layouts/DefaultLayout";
import MainContentLayout from "app/layouts/MainContentLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { defaultDateFormat, jpDateFormat } from "app/constants";
import { useNavigate, useParams } from "react-router-dom";
import LoadInitError from "app/components/commonUI/LoadInitError";
import LoadingProgress from "app/components/commonUI/LoadingProgress";
import {
  addCounselingSheetToVisit,
  getAllCoundelingSheetByVisit,
} from "app/services/api";
import CreateCompleteDialog from "app/components/CommonDialog/CreateCompleteDialog";
import LoadingBackdrop from "app/components/commonUI/LoadingBackdrop";
import { toast } from "react-toastify";

function CounselingSheetDetailAndEdit(props) {
  const { counseling, handleSaveCounselingSheet } = props;
  const [showModeEdit, setShowModeEdit] = useState(false);
  const [counselingInput, setCounselingInput] = useState("");

  useEffect(() => {
    setCounselingInput(counseling?.memo);
    setShowModeEdit(false);
  }, [counseling]);

  const handleSave = () => {
    if (!counselingInput || !counselingInput.trim()) return;
    handleSaveCounselingSheet(counseling, counselingInput);
    setShowModeEdit(false);
  };

  if (!showModeEdit)
    return (
      <div className="w-full h-full border border-gray-600 min-h-[360px] rounded-2xl py-3 pt-12 relative">
        <div className="absolute inset-x-0 top-0 h-7 flex items-end justify-between pt-1 px-4 font-semibold">
          {moment(counseling.counsellingDate, defaultDateFormat).format(
            jpDateFormat
          )}

          <button
            type="button"
            onClick={() => setShowModeEdit(true)}
            className="w-7 h-7 rounded-md"
          >
            <FontAwesomeIcon icon={faPencil} />
          </button>
        </div>

        <div className="w-full h-full px-6 overflow-auto">
          {counseling.memo}
        </div>
      </div>
    );

  return (
    <div className="w-full h-full border border-gray-600 min-h-[360px] rounded-2xl py-12 relative">
      <div className="absolute inset-x-0 top-0 h-7 flex items-end pt-1 px-4 font-semibold">
        {moment(counseling.counsellingDate, defaultDateFormat).format(
          jpDateFormat
        )}
      </div>

      <div className="w-full h-full px-6 overflow-auto">
        <textarea
          value={counselingInput}
          onChange={(e) => setCounselingInput(e.target.value)}
          className="w-full h-full rounded-2xl px-4 py-3 border border-gray-400 block"
        />
      </div>

      <div className="absolute inset-x-0 bottom-0 h-12 flex items-center justify-end pt-1 px-4 font-semibold">
        <button
          type="button"
          onClick={handleSave}
          className="button-size bg-blue-btn-primary"
        >
          保存
        </button>
      </div>
    </div>
  );
}

function CounselingSheetMemo(props) {
  const { handleCancel, handleAddNewCounseling } = props;

  const [counselingInfo, setCounselingInfo] = useState("");

  const handleSaveCounselingSheet = () => {
    if (!counselingInfo || !counselingInfo.trim()) return;
    handleAddNewCounseling(counselingInfo);
  };

  return (
    <div className="w-full h-full pt-16 lg:pt-12 pb-8 relative">
      <div className="absolute inset-x-0 top-0 w-full h-16 lg:h-12 pl-20 lg:pt-0 flex items-center bg-white">
        <div className="w-full h-auto flex items-center justify-start">
          <h2 className="text-base lg:text-xl font-semibold">
            カウンセリングメモ入力
          </h2>
        </div>
      </div>

      <div className="w-full h-full max-w-[960px] mx-auto relative max-h-full py-3">
        <div className="w-full h-full border border-gray-600 min-h-[360px] rounded-2xl">
          <textarea
            value={counselingInfo}
            onChange={(e) => setCounselingInfo(e.target.value)}
            className="w-full h-full rounded-2xl px-4 py-3"
          />
        </div>
      </div>

      <div className="absolute inset-x-0 bottom-0 w-full h-8 flex items-end justify-end bg-white">
        <button
          onClick={handleCancel}
          type="button"
          className="button-size bg-gray-btn-secondary mr-4 lg:mr-6"
        >
          戻る
        </button>
        <button
          onClick={handleSaveCounselingSheet}
          type="button"
          className="button-size bg-blue-btn-primary"
        >
          完了
        </button>
      </div>
    </div>
  );
}

function CounselingSheetHistoryItem(props) {
  const { counselingInfo, handleClick, handleClickDelete } = props;
  return (
    <button
      type="button"
      onClick={() => handleClick(counselingInfo)}
      className="w-full h-20 border mb-4 border-gray-600 rounded-2xl px-4 py-1.5 pt-6 relative flex flex-col justify-end hover:shadow-md transition-all"
    >
      <div className="absolute inset-x-0 top-0 h-7 flex items-end pt-1 px-4 font-semibold">
        {moment(counselingInfo.counsellingDate, defaultDateFormat).format(
          jpDateFormat
        )}
      </div>

      {counselingInfo?.tempId ? (
        <button
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            handleClickDelete(counselingInfo?.tempId);
          }}
          className="w-6 h-6 absolute top-1 right-2 flex items-center justify-center rounded-full opacity-70 hover:opacity-100 hover:bg-gray-200 transition-all"
        >
          <FontAwesomeIcon icon={faTrash} color="red" />
        </button>
      ) : null}

      <p className="w-full h-auto overflow-hidden text-sm font line-clamp-2 text-start">
        {counselingInfo.memo}
      </p>
    </button>
  );
}

function CounselingSheetHistory(props) {
  const {
    handleClickAdd,
    handleClickCounselingItem,
    detailCounselingSheet,
    handleNextStep,
    handleClickDelete,
    newCounselings,
    handleSaveCounselingSheet,
  } = props;

  const navigate = useNavigate();

  return (
    <div className="w-full h-full pt-16 lg:pt-12 pb-8 relative">
      <div className="absolute inset-x-0 top-0 w-full h-16 pl-20 lg:pl-0 lg:h-12 flex items-center bg-white">
        <div className="w-full h-auto flex items-center justify-between">
          <h2 className="text-base lg:text-xl font-semibold">
            カウンセリングシート
          </h2>

          <button
            type="button"
            className="button-size flex items-center justify-center gap-2 bg-[#6670c8]"
            onClick={handleClickAdd}
          >
            <FontAwesomeIcon icon={faFilePen} color="white" />
            <span className="text-white">新規メモ入力</span>
          </button>
        </div>
      </div>
      <div className="w-full h-full py-3">
        {newCounselings.length === 0 ? (
          <NoData message="まだ相談シートはありません！" />
        ) : (
          <div className="w-full h-full max-w-[960px] mx-auto relative flex">
            <div className="w-1/2 py-6 pr-1 md:pr-4 pl-0 max-h-full">
              {detailCounselingSheet ? (
                <CounselingSheetDetailAndEdit
                  counseling={detailCounselingSheet}
                  handleSaveCounselingSheet={handleSaveCounselingSheet}
                />
              ) : null}
            </div>

            <div className="w-1/2 p-6 pl-1 lg:pl-4 pr-0 flex flex-col max-h-full overflow-auto">
              <div className="w-full max-h-full overflow-auto">
                <div className="flex flex-col">
                  {newCounselings.map((counseling) => (
                    <CounselingSheetHistoryItem
                      key={counseling?.id || counseling?.tempId}
                      counselingInfo={counseling}
                      handleClick={handleClickCounselingItem}
                      handleClickDelete={handleClickDelete}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="absolute inset-x-0 bottom-0 w-full h-8 flex items-end justify-end bg-white">
        {Array.isArray(newCounselings) && newCounselings.length > 0 ? (
          <button
            onClick={handleNextStep}
            type="button"
            className="button-size bg-blue-btn-primary"
          >
            作成する
          </button>
        ) : (
          <button
            onClick={() => {
              navigate(-1);
            }}
            type="button"
            className="button-size bg-gray-btn-secondary"
          >
            戻る
          </button>
        )}
      </div>
    </div>
  );
}

function AddCounselingPageContent(props) {
  const { counselingHistory, visitId } = props;

  const [showInputMode, setShowInputMode] = useState(false);
  const [newCounselings, setNewCounselings] = useState([]);
  const [detailCounselingSheet, setDetailCounselingSheet] = useState(null);
  const [saveCounselingStatus, setSaveCounselingStatus] = useState("ilde");

  const navigate = useNavigate();

  useEffect(() => {
    const counselings = counselingHistory.map((item) => ({
      tempId: v4(),
      counsellingDate: moment(item.counsellingDate)
        .tz("Asia/Tokyo")
        .format(defaultDateFormat),
      memo: item.memo,
    }));
    setNewCounselings(counselings);
    if (counselings[0]) {
      setDetailCounselingSheet(counselings[0]);
    }
  }, [counselingHistory]);

  const handleAddNewCounseling = (memo) => {
    const newList = [
      {
        tempId: v4(),
        counsellingDate: moment().format(defaultDateFormat),
        memo,
      },
      ...newCounselings,
    ];
    setNewCounselings(newList);

    if (!detailCounselingSheet) setDetailCounselingSheet(newList[0]);
    setShowInputMode(false);
  };

  const handleClickCounselingItem = (counseling) => {
    setDetailCounselingSheet(counseling);
  };

  const handleNextStep = () => {
    if (saveCounselingStatus === "loading") return;
    if (newCounselings.length === 0) {
      navigate(-1);
      return;
    }

    setSaveCounselingStatus("loading");

    addCounselingSheetToVisit(visitId, newCounselings)
      .then((res) => {
        setSaveCounselingStatus("success");
      })
      .catch((error) => {
        toast.error("新しいカウンセリングの追加に失敗する!");
        console.log("error: ", error);
        setSaveCounselingStatus("idle");
      });
  };

  const handleClickDelete = (tempId) => {
    const newCounselingsList = newCounselings.filter(
      (item) => item.tempId !== tempId
    );
    setNewCounselings(newCounselingsList);
    if (detailCounselingSheet.tempId === tempId) {
      setDetailCounselingSheet(newCounselingsList[0]);
    }
  };

  const handleSaveCounselingSheet = (counseling, memo) => {
    const newCounselingsList = [...newCounselings];
    const index = newCounselingsList.findIndex(
      (item) => item.tempId === counseling.tempId
    );
    if (index >= 0) {
      newCounselingsList[index] = {
        ...counseling,
        memo: memo,
      };
    }
    setNewCounselings(newCounselingsList);
  };

  if (!showInputMode)
    return (
      <>
        <CounselingSheetHistory
          handleClickAdd={() => setShowInputMode(true)}
          handleClickCounselingItem={handleClickCounselingItem}
          detailCounselingSheet={detailCounselingSheet}
          handleClickDelete={handleClickDelete}
          handleNextStep={handleNextStep}
          newCounselings={newCounselings}
          handleSaveCounselingSheet={handleSaveCounselingSheet}
        />

        <CreateCompleteDialog
          open={saveCounselingStatus === "success"}
          createCompleteTitle="新しいカウンセリングの追加が成功しました"
          createCompleteBtnLabel2="終る"
          createCompleteAction2={() => {
            navigate(-1);
          }}
        />

        <LoadingBackdrop isOpen={saveCounselingStatus === "loading"} />
      </>
    );

  return (
    <CounselingSheetMemo
      handleCancel={() => setShowInputMode(false)}
      handleAddNewCounseling={handleAddNewCounseling}
    />
  );
}

function AddCounselingPage() {
  const [loadInitState, setLoadInitState] = useState({
    isLoading: true,
    data: null,
    error: null,
  });
  const { visitId } = useParams();

  const loadInit = useCallback(() => {
    setLoadInitState({
      isLoading: true,
      data: null,
      error: null,
    });

    getAllCoundelingSheetByVisit(visitId)
      .then((res) => {
        setLoadInitState({
          isLoading: false,
          data: {
            counselingHistory: res.data,
          },
          error: null,
        });
      })
      .catch((error) => {
        setLoadInitState({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  }, [visitId]);

  useEffect(() => {
    loadInit();
  }, [loadInit]);

  return (
    <DefaultLayout>
      <MainContentLayout
        pageTitle={
          <>
            <p>お客様の</p>
            <p>ご来店</p>
          </>
        }
      >
        {loadInitState.error ? (
          <LoadInitError error={loadInitState.error} />
        ) : null}

        {loadInitState.isLoading ? <LoadingProgress /> : null}

        {!loadInitState.isLoading &&
        !loadInitState.error &&
        loadInitState.data &&
        loadInitState.data?.counselingHistory ? (
          <div className="w-full h-full flex items-center justify-center">
            <AddCounselingPageContent
              visitId={visitId}
              counselingHistory={loadInitState.data.counselingHistory || []}
            />
          </div>
        ) : null}
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default AddCounselingPage;
