import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Avatar, CircularProgress, MenuItem } from "@mui/material";
import {
  faBars,
  faMagnifyingGlass,
  faPencil,
  faTrashCan,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CommonPopoverMenu from "app/components/commonUI/CommonPopoverMenu";
import defaultAvatar from "app/assets/svg/default_avatar.svg";
import InputDateField from "../../../../components/commonUI/InputDateField";
import InputTimeField from "../../../../components/commonUI/InputTimeField";
import {
  defaultDateFormat,
  jpDateFormat,
  product_category_and_label,
} from "../../../../constants";
import dayjs from "dayjs";
import { formatPrice, getNameFromFirstLast } from "../../../../utils";
import SelectServiceDialog from "app/components/SelectServiceDialog";
import CommonDialog from "../../../../components/CommonDialog";
import {
  getAllSalonStaffs,
  searchCustomerInfo,
} from "../../../../services/api";
import LoadingProgress from "../../../../components/commonUI/LoadingProgress";

function SearchResultItem(props) {
  const { customInfo, handleClickSelect } = props;

  return (
    <div className="w-full h-10 mb-1 flex items-center gap-3 px-2 bg-slate-200 rounded-md">
      <p className="flex-1 truncate">{getNameFromFirstLast(customInfo)}</p>

      <button
        type="button"
        onClick={handleClickSelect}
        className="h-6 w-16 shrink-0 flex items-center justify-center text-sm text-white rounded bg-blue-btn-primary"
      >
        次へ
      </button>
    </div>
  );
}

function SearchResultBox(props) {
  const { searchText, handleSelectCustomer } = props;
  const [callSearchState, setCallSearchState] = useState({
    isLoading: true,
    data: [],
    error: null,
  });

  const handleCallSearch = useCallback(() => {
    setCallSearchState((oldState) => ({ ...oldState, isLoading: true }));

    searchCustomerInfo(searchText)
      .then((res) => {
        setCallSearchState({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        setCallSearchState({
          isLoading: false,
          data: [],
          error: error,
        });
      });
  }, [searchText]);

  useEffect(() => {
    handleCallSearch();
  }, [handleCallSearch]);

  if (callSearchState.isLoading)
    return (
      <div className="w-full h-full flex items-center justify-center">
        <CircularProgress size={40} />
      </div>
    );

  if (Array.isArray(callSearchState.data) && callSearchState.data.length === 0)
    return (
      <div className="w-full h-full flex items-center justify-center">
        <p className="text-base font-semibold">NotFound Data</p>
      </div>
    );

  if (Array.isArray(callSearchState.data) && callSearchState.data.length > 0)
    return (
      <div className="w-full h-full overflow-auto">
        {callSearchState.data.map((customer) => (
          <SearchResultItem
            key={customer.id}
            customInfo={customer}
            handleClickSelect={() => handleSelectCustomer(customer)}
          />
        ))}
      </div>
    );
}

function SelectCustomerDialog(props) {
  const { open, handleClose, handleSelectCustomer } = props;

  const [searchText, setSearchText] = useState("");

  const setSearchTextTimeoutRef = useRef();

  const handleSearchInputChange = (e) => {
    if (setSearchTextTimeoutRef.current) {
      clearTimeout(setSearchTextTimeoutRef.current);
    }
    const newValue = e.currentTarget.value;
    setSearchTextTimeoutRef.current = setTimeout(
      () => setSearchText(newValue),
      500
    );
  };

  const clearSearchText = () => {
    setSearchText("");
  };

  return (
    <CommonDialog
      open={open}
      handleClose={handleClose}
      title=""
      actions={<div></div>}
    >
      <div className="w-full h-[460px] pt-5 overflow-hidden">
        <div className="w-full h-10 relative">
          <div className="absolute top-1 left-1 w-8 h-8 flex items-center justify-center">
            <FontAwesomeIcon icon={faMagnifyingGlass} />
          </div>
          <input
            onChange={handleSearchInputChange}
            className="w-full h-full px-10 py-1 border border-gray-300 focus:shadow-md focus:border-gray-500 rounded-full"
          />

          <button
            id="reset-search-btn"
            onClick={clearSearchText}
            className={`absolute right-2.5 top-2.5 w-5 h-5 flex items-center justify-center bg-gray-800 text-white rounded-full ${
              searchText ? "block" : "hidden"
            }`}
          >
            <FontAwesomeIcon icon={faXmark} />
          </button>
        </div>

        <div className="w-full h-[calc(100%-40px)] py-6 flex-1">
          <div className="w-full h-full overflow-auto">
            <SearchResultBox
              searchText={searchText}
              handleSelectCustomer={handleSelectCustomer}
            />
          </div>
        </div>
      </div>
    </CommonDialog>
  );
}

function SelectStaffDialogContent(props) {
  const { handleSelectStaff } = props;
  const [loadAllStaffState, setLoadAllStaffState] = useState({
    isLoading: true,
    data: [],
    error: null,
  });

  const handleCallSearch = useCallback(() => {
    setLoadAllStaffState((oldState) => ({ ...oldState, isLoading: true }));

    getAllSalonStaffs()
      .then((res) => {
        setLoadAllStaffState({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        setLoadAllStaffState({
          isLoading: false,
          data: [],
          error: error,
        });
      });
  }, []);

  useEffect(() => {
    handleCallSearch();
  }, [handleCallSearch]);

  if (loadAllStaffState.isLoading) return <LoadingProgress />;

  if (!loadAllStaffState.isLoading && Array.isArray(loadAllStaffState.data))
    return (
      <div className="w-full h-full flex flex-wrap items-center justify-center gap-4">
        {loadAllStaffState.data.map((staff) => (
          <button
            key={staff.id}
            onClick={() => handleSelectStaff(staff)}
            className="w-24 h-36 rounded-md bg-slate-100 hover:bg-slate-200 flex flex-col items-center justify-center gap-5"
          >
            <img
              alt=""
              className="w-16 h-16 rounded-full"
              src={staff.imageUrl || defaultAvatar}
            />
            <p className="w-full truncate font-semibold px-1">
              {getNameFromFirstLast(staff)}
            </p>
          </button>
        ))}
      </div>
    );
  return null;
}

function SelectStaffDialog(props) {
  const { open, handleClose, handleSelectStaff } = props;

  return (
    <CommonDialog
      open={open}
      handleClose={handleClose}
      title="従業員を選択してください"
      actions={<div></div>}
    >
      <div className="w-full h-[460px] pt-5 overflow-hidden">
        <SelectStaffDialogContent handleSelectStaff={handleSelectStaff} />
      </div>
    </CommonDialog>
  );
}
function ReservationMenuItem(props) {
  const { menuItem, handleDeleteMenuItem, confirmMode } = props;
  const [showEditOptionEl, setShowEditOptionEl] = useState(null);
  return (
    <>
      <div
        className={`w-full h-10 bg-slate-200 rounded-md mb-2 px-2 relative ${
          !confirmMode ? "pr-12" : "pr-2"
        }`}
      >
        <div className="w-full h-full flex">
          <div className="w-1/2 flex items-center justify-start">
            <div className="w-auto h-8 px-5 flex items-center justify-center rounded-full bg-[#736966] text-sm font-semibold text-white">
              {menuItem.name}
            </div>
          </div>
          <div className="w-1/4 flex items-center justify-end">
            <p> {menuItem.durationString}</p>
          </div>
          <div className="w-1/4 flex items-center justify-end">
            <p>{formatPrice(menuItem.priceTaxInc)}</p>
          </div>
        </div>
        {!confirmMode ? (
          <button
            type="button"
            onClick={(e) => {
              setShowEditOptionEl(e.currentTarget);
            }}
            className="absolute top-1 right-2 w-8 h-8 flex items-center justify-center rounded hover:bg-slate-300"
          >
            <FontAwesomeIcon icon={faBars} />
          </button>
        ) : null}
      </div>

      <CommonPopoverMenu
        open={!!showEditOptionEl && !confirmMode}
        anchorEl={showEditOptionEl}
        onClose={() => setShowEditOptionEl(null)}
      >
        <MenuItem
          onClick={() => {
            handleDeleteMenuItem(menuItem);
            setShowEditOptionEl(null);
          }}
          disableRipple
        >
          <span className="text-red-500 mr-3">
            <FontAwesomeIcon icon={faTrashCan} />
          </span>
          <span>消去</span>
        </MenuItem>
      </CommonPopoverMenu>
    </>
  );
}

function ReservationForm(props) {
  const {
    editingData,
    confirmMode,
    allStaffAvailable,
    handleSelectService,
    handleDeleteMenuItem,
    handleChangeVisitDate,
    handleChangeStartTime,
    handleChangeStaff,
    handleChangeCustomer,
  } = props;

  const [showSelectCategoryService, setShowSelectCategoryService] =
    useState("");
  const [showSelectCustomer, setShowSelectCustomer] = useState(false);
  const [showSelectStaff, setShowSelectStaff] = useState(false);

  const totalMenuAmount = useMemo(() => {
    const reservationMenus = editingData.reservationMenus;
    if (!Array.isArray(reservationMenus)) return formatPrice(0);
    return formatPrice(
      reservationMenus.reduce((sum, item) => sum + item.priceTaxInc, 0)
    );
  }, [editingData]);

  const totalMenuTime = useMemo(() => {
    const reservationMenus = editingData.reservationMenus;
    if (!Array.isArray(reservationMenus)) return formatPrice(0);
    const totalMinute = reservationMenus.reduce(
      (sum, item) => sum + item.durationNumber,
      0
    );
    const numberOfHours = totalMinute / 60;
    return `${Math.floor(numberOfHours)}時間${Math.round(
      (numberOfHours % 1) * 60
    )}分`;
  }, [editingData]);

  return (
    <>
      <div className="w-full h-auto">
        <div className="w-full flex justify-between mb-0.5 flex-wrap">
          <div className="bg-[#A5A5A5] text-white h-9 w-full md:w-32 px-4 py-1.5 text-sm font-semibold flex items-center ">
            お客様No.
          </div>
          <div className="bg-[#F0F0F0] text-gray-900 h-9 w-full md:w-[calc(100%-130px)] px-4 py-1.5">
            {editingData?.customer?.id || ""}
          </div>
        </div>

        <div className="w-full flex justify-between mb-0.5 flex-wrap">
          <div className="bg-[#A5A5A5] text-white h-9 w-full md:w-32 px-4 py-1.5 text-sm font-semibold flex items-center">
            <p>お客様名</p>
          </div>
          <div className="bg-[#F0F0F0] text-gray-900 h-9 w-full md:w-[calc(100%-130px)] px-4 py-1.5 pr-10 relative">
            {editingData.customer
              ? getNameFromFirstLast(editingData.customer)
              : ""}

            {!confirmMode ? (
              <button
                type="button"
                onClick={() => setShowSelectCustomer(true)}
                className="w-8 h-8 flex items-center justify-center rounded absolute right-2 top-1/2 -translate-y-1/2 bg-slate-300"
              >
                <FontAwesomeIcon icon={faPencil} size="lg" />
              </button>
            ) : null}
          </div>
        </div>

        <div className="w-full flex justify-between mb-0.5 flex-wrap">
          <div className="bg-[#A5A5A5] text-white h-9 w-full md:w-32 px-4 py-1.5 text-sm font-semibold flex items-center">
            <p>日付</p>
          </div>
          <div className="bg-[#F0F0F0] text-gray-900 h-9 w-full md:w-[calc(100%-130px)] px-4 flex items-center">
            {confirmMode ? (
              editingData.visitDate ? (
                editingData.visitDate.format(jpDateFormat)
              ) : null
            ) : (
              <InputDateField
                value={editingData.visitDate}
                setValue={handleChangeVisitDate}
              />
            )}
          </div>
        </div>

        <div className="w-full flex justify-between mb-0.5 flex-wrap">
          <div className="bg-[#A5A5A5] text-white h-9 w-full md:w-32 px-4 py-1.5 text-sm font-semibold flex items-center">
            <p>時間</p>
          </div>
          <div className="bg-[#F0F0F0] text-gray-900 h-9 w-full md:w-[calc(100%-130px)] px-4 flex items-center">
            {confirmMode ? (
              editingData.startTime
            ) : (
              <InputTimeField
                time={editingData.startTime}
                setTime={handleChangeStartTime}
                className="w-40"
                minutesStep={1}
              />
            )}
          </div>
        </div>

        <div className="w-full flex justify-between mb-0.5 flex-wrap">
          <div className="bg-[#A5A5A5] text-white h-9 md:h-14 w-full md:w-32 px-4 py-1.5 text-sm font-semibold flex items-center">
            <p>担当</p>
          </div>
          <div className="bg-[#F0F0F0] text-gray-900 h-14 w-full md:w-[calc(100%-130px)] px-4 py-1.5 flex items-center justify-start relative pr-10">
            <Avatar
              alt=""
              src={editingData.staff?.imageUrl || defaultAvatar}
              sx={{
                width: 40,
                height: 40,
                border: "1px solid black",
                marginRight: "16px"
              }}
            />
            <p>
              {editingData.staff ? getNameFromFirstLast(editingData.staff) : ""}
            </p>

            {!confirmMode ? (
              <button
                type="button"
                onClick={() => setShowSelectStaff(true)}
                className="w-8 h-8 flex items-center justify-center rounded absolute right-2 top-1/2 -translate-y-1/2 bg-slate-300"
              >
                <FontAwesomeIcon icon={faPencil} size="lg" />
              </button>
            ) : null}
          </div>
        </div>

        <div className="w-full h-auto flex justify-between mb-0.5 flex-wrap">
          <div className="bg-[#A5A5A5] text-white h-9 md:h-auto w-full md:w-32 px-4 py-1.5 text-sm font-semibold flex items-center">
            <p>メニュー</p>
          </div>
          <div className="bg-[#F0F0F0] text-gray-900 h-auto w-full md:w-[calc(100%-130px)] px-4 py-3 flex justify-between flex-wrap">
            {!confirmMode ? (
              <div className="w-full md:w-48 flex flex-col items-center gap-4 py-2">
                {product_category_and_label.map((category) => (
                  <button
                    key={category.key}
                    type="button"
                    className="w-40 h-10 flex items-center justify-center rounded-full bg-[#736966] hover:bg-[#5e5553] overflow-hidden text-base text-white shrink-0"
                    onClick={() => setShowSelectCategoryService(category.key)}
                  >
                    {category.label}
                  </button>
                ))}
              </div>
            ) : null}

            <div
              className={` border-gray-400 rounded-md p-4 pb-12 relative min-h-[200px] ${
                confirmMode ? "w-full" : "w-full md:w-[calc(100%-208px)] border"
              }`}
            >
              {editingData.reservationMenus.map((menuItem) => (
                <ReservationMenuItem
                  menuItem={menuItem}
                  handleDeleteMenuItem={handleDeleteMenuItem}
                  confirmMode={confirmMode}
                />
              ))}

              <div className="w-full h-12 rounded-b-lg absolute inset-x-0 bottom-0 px-4 pb-4">
                <div className="w-full h-full border-t-2 border-gray-400">
                  <div
                    className={`w-full h-10 rounded-md px-2 relative font-semibold ${
                      !confirmMode ? "pr-12" : "pr-2"
                    }`}
                  >
                    <div className="w-full h-full flex">
                      <div className="w-1/2 flex items-center justify-start"></div>
                      <div className="w-1/4 flex items-center justify-end">
                        <p>{totalMenuTime}</p>
                      </div>
                      <div className="w-1/4 flex items-center justify-end">
                        <p>{totalMenuAmount}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <SelectServiceDialog
        menuType={!confirmMode && showSelectCategoryService}
        handleClose={() => setShowSelectCategoryService("")}
        handleSelectService={handleSelectService}
      />

      <SelectStaffDialog
        open={showSelectStaff && !confirmMode}
        handleClose={() => setShowSelectStaff(false)}
        allStaffAvailable={allStaffAvailable}
        handleSelectStaff={(staff) => {
          console.log("customer", staff);
          handleChangeStaff(staff);
          setShowSelectStaff(false);
        }}
      />

      <SelectCustomerDialog
        open={showSelectCustomer && !confirmMode}
        handleClose={() => setShowSelectCustomer(false)}
        handleSelectCustomer={(customer) => {
          console.log("customer", customer);
          handleChangeCustomer(customer);
          setShowSelectCustomer(false);
        }}
      />
    </>
  );
}

export default ReservationForm;
