import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CommonDialog from "app/components/CommonDialog";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

import Path from "app/route/Path";

const paymentMethods = {
  CASH: "CASH",
  OTHERS: "OTHERS",
};

function ConfirmPaymentMethodDialogContent(props) {
  const { selected, setSelected } = props;
  const handleChange = (event) => {
    setSelected(event.target.value);
  };

  return (
    <div className="">
      <FormControl>
        <RadioGroup value={selected} onChange={handleChange}>
          <FormControlLabel
            value={paymentMethods.CASH}
            control={<Radio />}
            label="現金"
          />
          <FormControlLabel
            value={paymentMethods.OTHERS}
            control={<Radio />}
            label="その他"
          />
        </RadioGroup>
      </FormControl>
    </div>
  );
}

function ConfirmPaymentMethodDialog(props) {
  const { confirmPaymentVisit, handleCloseDialog } = props;
  const navigate = useNavigate();

  const [selected, setSelected] = useState("");

  const resetDialog = () => {
    setSelected("");
  };

  useEffect(() => {
    if (!confirmPaymentVisit) {
      resetDialog();
    }
  }, [confirmPaymentVisit]);

  const handleConfirm = () => {
    if (selected === paymentMethods.CASH) {
      navigate(Path.customerPaymentByCash(confirmPaymentVisit.id));
    } else if (selected === paymentMethods.OTHERS) {
      navigate(Path.customerPaymentByOtherMethod(confirmPaymentVisit.id));
    }
  };

  return (
    <CommonDialog
      open={!!confirmPaymentVisit}
      handleClose={handleCloseDialog}
      title="お支払方法を選択してください。"
      actions={
        <div className="w-full flex items-center justify-end text-white text-base font-semibold">
          <button
            onClick={handleCloseDialog}
            type="button"
            className="button-size bg-gray-btn-secondary mr-4 lg:mr-6"
          >
            戻る
          </button>
          <button
            onClick={handleConfirm}
            type="button"
            className={`button-size mr-6 ${
              selected
                ? "bg-blue-btn-primary cursor-pointer"
                : "bg-gray-btn-secondary cursor-not-allowed"
            }`}
          >
            進む
          </button>
        </div>
      }
    >
      <ConfirmPaymentMethodDialogContent
        selected={selected}
        setSelected={setSelected}
      />
    </CommonDialog>
  );
}

export default ConfirmPaymentMethodDialog;
