import React, { useEffect, useState } from "react";
import DefaultLayout from "../../../layouts/DefaultLayout";
import MainContentLayout from "../../../layouts/MainContentLayout";
import { useNavigate } from "react-router-dom";
import Path from "../../../route/Path";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { getIntroductionOfScalpLab } from "../../../services/api";
import LoadingProgress from "../../../components/commonUI/LoadingProgress";
import LoadInitError from "../../../components/commonUI/LoadInitError";
import NoData from "../../../components/commonUI/NoData";

function ApplicationForm() {
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  const [fetchApplicationForm, setFetchApplicationForm] = useState({
    isLoading: false,
    data: null,
    error: null,
  });

  const loadApplicationForm = () => {
    setFetchApplicationForm({
      isLoading: true,
      data: null,
      error: null,
    });

    getIntroductionOfScalpLab()
      .then((res) => {
        setFetchApplicationForm({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        setFetchApplicationForm({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  };

  useEffect(() => {
    loadApplicationForm();
  }, []);
  return (
    <DefaultLayout>
      <MainContentLayout
        pageTitle={
          <>
            <p>頭皮ラボ</p>
          </>
        }
      >
        <div className="w-full h-full pt-16 pb-8 lg:py-12 relative">
          <div className="w-full h-16 lg:h-12 pl-20 lg:pl-0 flex items-center justify-start absolute inset-x-0 top-0 bg-white">
            <h2 className="text-base lg:text-xl font-semibold">申込書</h2>
          </div>
          <div className="w-full h-full py-3">
            <div className="w-full h-full pr-3 border border-gray-400 rounded-2xl p-4">
              <div className="w-full h-full overflow-y-auto overflow-x-hidden">
                {fetchApplicationForm.isLoading ? <LoadingProgress /> : null}

                {!fetchApplicationForm.isLoading &&
                fetchApplicationForm.error ? (
                  <LoadInitError error={fetchApplicationForm.error} />
                ) : null}
                {!fetchApplicationForm.isLoading &&
                !fetchApplicationForm.error ? (
                  <div className="w-full max-h-full mx-auto overflow-y-hidden overflow-y-scroll overflow-x-hidden">
                    <p className="pr-1">
                      {fetchApplicationForm.data?.content}{" "}
                      {fetchApplicationForm.data?.content}{" "}
                      {fetchApplicationForm.data?.content}{" "}
                      {fetchApplicationForm.data?.content}
                      {fetchApplicationForm.data?.content}
                      {fetchApplicationForm.data?.content}
                      {fetchApplicationForm.data?.content}
                    </p>
                  </div>
                ) : (
                  <NoData />
                )}
              </div>
            </div>
          </div>
          <div className="w-5/6 h-8 flex items-center justify-start text-base font-semibold absolute left-4 bottom-2">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    onChange={() => setChecked(!checked)}
                  />
                }
                label="同意します"
              />
            </FormGroup>
          </div>

          <div className="w-full h-8 flex gap-6 items-center justify-end text-white text-base font-semibold absolute inset-x-0 bottom-0">
            <button
              onClick={() => navigate(Path.scalpLabManagementIntroduction)}
              type="button"
              className="button-size bg-gray-btn-secondary"
            >
              戻る
            </button>
            <button
              disabled={!checked}
              type="button"
              className={`button-size ${
                checked ? " bg-blue-btn-primary" : "bg-gray-btn-secondary"
              } `}
              onClick={() =>
                navigate(Path.scalpLabManagementConfirmApplicationForm)
              }
            >
              内容確認へ
            </button>
          </div>
        </div>
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default ApplicationForm;
