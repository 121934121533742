import client from "../client";

export const getAllHistoryOfOrdering = () => {
  return client.get("/scalp-data/find-scalp-data").then((res) => {
    if (res.data.success) {
      return {
        data: res.data.result,
      };
    }
    return { data: [] };
  });
};

export const getHistoryOfOrderingDetail = (customerNo) => {
  return client
    .get("/scalp-data/find-scalp-data", {
      params: { customerNo: customerNo },
    })
    .then((res) => {
      if (res.data.success) {
        return {
          data: res.data.result,
        };
      }
      return { data: {} };
    });
};

export const createNewScalpData = (newScalpData) => {
  return client.post("/scalp-data/create", newScalpData).then((res) => {
    if (res.data.success) {
      return {
        data: res.data.result,
      };
    }
    return { data: {} };
  });
};

export const createNewOrderKit = (newOrderKitData) => {
  return client
    .post("/inspection-kit-salon-order/create", newOrderKitData)
    .then((res) => {
      if (res.data.success) {
        return {
          data: res.data.result,
        };
      }
      return { data: {} };
    });
};

export const createContact = (newContactData) => {
  return client
    .post("/scalp-lap-contact/create", newContactData)
    .then((res) => {
      if (res.data.success) {
        return {
          data: res.data.result,
        };
      }
      return { data: {} };
    });
};

export const getAllSalonCustomer = () => {
  return client.get("/salon/salon-customer/all").then((res) => {
    if (res.data.success) {
      return {
        data: res.data.result,
      };
    }
    return { data: [] };
  });
};

export const getSalonCustomer = (customerNo) => {
  return client
    .get(`/customer/customer/get-by-customer-no`, {
      params: { customerNo: customerNo },
    })
    .then((res) => {
      if (res.data.success) {
        return {
          data: res.data.result,
        };
      }
      return { data: [] };
    });
};
