import React, { useState } from "react";
import { defaultDateTimeFormat } from "../../../constants";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  InputLabel,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import CommonDialog from "../../../components/CommonDialog";
import { useNavigate } from "react-router-dom";
import Path from "../../../route/Path";
import { createNewDMs } from "../../../services/api/1A";
import moment from "moment";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { toast } from "react-toastify";
const STATUS_DELIVERY = {
  INSTANT_DELIVERY: "immediate",
  RESERVATION_DELIVERY: "reserve",
};

function ConfirmStatusDelivery(props) {
  const { value, handleSelect } = props;
  const handleChange = (event) => {
    handleSelect(event.target.value);
    console.log("e", event.target.value);
  };

  return (
    <div>
      <RadioGroup value={value} onChange={handleChange}>
        <FormControlLabel
          value={STATUS_DELIVERY.INSTANT_DELIVERY}
          control={<Radio />}
          label="即時配信"
        />
        <FormControlLabel
          value={STATUS_DELIVERY.RESERVATION_DELIVERY}
          control={<Radio />}
          label="予約配信"
        />
      </RadioGroup>
    </div>
  );
}
function CreateDmsForm(props) {
  const navigate = useNavigate();
  const { handleBackClick, listCustomer } = props;
  const [selectedDate, setSelectedDate] = useState(null);
  const [member, setMember] = useState("");
  const [status, setStatus] = useState("");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [open, setOpen] = useState(false);
  const [isChangeButton, setIsChangeButton] = useState(false);

  const handleChange = (event) => {
    setMember(event.target.value);
  };
  const handleCreateDms = () => {
    if (status === STATUS_DELIVERY.INSTANT_DELIVERY) {
      createNewDMs({
        targetAudience: member,
        deliveryType: status,
        title: title,
        content: content,
      })
        .then((res) => {
          if (res) {
            setOpen(true);
          }
        })
        .catch((error) => {
          toast.error("メッセージの送信に失敗しました");
        });
    } else {
      const dateTimeStr = moment
        .tz(
          selectedDate.format(defaultDateTimeFormat),
          defaultDateTimeFormat,
          true,
          "Asia/Tokyo"
        )
        .toISOString();

      createNewDMs({
        targetAudience: member,
        deliveryType: status,
        targetDeliverDatetime: dateTimeStr,
        title: title,
        content: content,
      })
        .then((res) => {
          if (res) {
            setOpen(true);
          }
        })
        .catch((error) => {
          toast.error("メッセージの送信に失敗しました");
        });
    }
  };
  console.log("sel", selectedDate);
  return (
    <div className="w-full h-full">
      <div className="w-full h-full border border-gray-400 rounded-2xl p-4 overflow-auto">
        <div className="flex">
          <label className="block shrink-0 font-semibold !w-[130px]">
            配信対象
          </label>
          <FormControl sx={{ minWidth: 150, maxWidth: 200 }} size="small">
            <InputLabel id="demo-select-small-label">メンバー</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={member}
              label="メンバー"
              onChange={handleChange}
              disabled={isChangeButton}
              className="!w-72"
            >
              {listCustomer?.map((customer) => (
                <MenuItem value={customer.code}>{customer.describe}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="flex">
          <label className="block shrink-0 font-semibold !w-[130px]">
            配信方法
          </label>
          <ConfirmStatusDelivery
            value={status}
            handleSelect={(value) => setStatus(value)}
          />
        </div>
        <div className="flex w-full  mb-6 mt-1">
          <label className="font-semibold !w-[118px]">配信日時</label>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              label="Basic date time picker"
              value={selectedDate}
              onChange={(newTime) => setSelectedDate(newTime)}
              disabled={
                isChangeButton || status === STATUS_DELIVERY.INSTANT_DELIVERY
              }
            />
          </LocalizationProvider>
        </div>
        <div className="flex w-full">
          <label className="font-semibold !w-[130px]">タイトル</label>
          <input
            name="title"
            type="text"
            className="w-full h-full p-2 rounded-md border"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            disabled={isChangeButton}
          />
        </div>
        <div className="flex w-full mt-6">
          <label className="font-semibold !w-[130px]">本文</label>
          <textarea
            value={content}
            className="w-full  rounded-md border p-2"
            rows="5"
            onChange={(e) => setContent(e.target.value)}
            disabled={isChangeButton}
          />
        </div>
      </div>
      {!isChangeButton ? (
        <div className=" w-full h-8 flex items-center justify-end text-white text-base font-semibold absolute inset-x-0 bottom-0">
          <button
            onClick={() => setIsChangeButton(true)}
            className={`button-size text-white text-base font-semibold ${
              (member && status && title && content) === ""
                ? "bg-gray-btn-secondary"
                : "bg-blue-btn-primary"
            } `}
            disabled={
              (member && status && title && content) === "" ? true : false
            }
          >
            内容確認
          </button>
        </div>
      ) : (
        <div className="w-full h-8 flex items-center justify-end text-white text-base font-semibold absolute inset-x-0 bottom-0">
          <button
            onClick={() => setIsChangeButton(false)}
            type="button"
            className="button-size bg-gray-btn-secondary mr-4 lg:mr-6"
          >
            戻る
          </button>
          <button
            onClick={handleCreateDms}
            type="button"
            className="button-size rounded bg-blue-btn-primary"
          >
            作成
          </button>
        </div>
      )}

      <CommonDialog
        open={open}
        handleClose={() => setOpen(false)}
        title="ダイレクトメールを作成しました"
        actions={
          <div className="w-full flex gap-6 items-center justify-end text-white text-base font-semibold">
            <button
              onClick={() => navigate(Path.dmsManagementHistory)}
              type="button"
              className="button-size bg-gray-btn-secondary"
            >
              配信履歴へ
            </button>
            <button
              onClick={() => navigate(Path.home)}
              type="button"
              className="button-size bg-blue-btn-primary"
            >
              終了
            </button>
          </div>
        }
      />
    </div>
  );
}

export default CreateDmsForm;
