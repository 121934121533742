import React from "react";
import InputField from "app/components/commonUI/InputField";
import InputDateField from "app/components/commonUI/InputDateField";

function SearchCustomerForm(props) {
  const { searchValues, setSearchFieldValue } = props;
  return (
    <div className="w-full h-auto rounded-lg border border-gray-400 max-w-[680px] mx-auto p-6">
      <div className="w-full h-auto flex items-start mb-6 flex-wrap">
        <div className="w-full md:w-32 h-full flex items-start font-semibold leading-9">
          氏名（カナ）
        </div>
        <div className="w-full md:w-[calc(100%-128px)] h-auto flex">
          <div className="w-1/2 h-auto pr-3">
            <InputField
              label="セイ"
              name="lastNameKata"
              value={searchValues.lastNameKata}
              type="text"
              onChange={(newValue) =>
                setSearchFieldValue("lastNameKata", newValue)
              }
            />
          </div>
          <div className="w-1/2 h-auto pl-3">
            <InputField
              label="メイ"
              name="firstNameKata"
              value={searchValues.firstNameKata}
              type="text"
              onChange={(newValue) => setSearchFieldValue("firstNameKata", newValue)}
            />
          </div>
        </div>
      </div>
      <div className="w-full h-auto flex items-start mb-6 flex-wrap">
        <div className="w-full md:w-32 h-full flex items-start font-semibold leading-9">
          氏名（漢字）
        </div>
        <div className="w-full md:w-[calc(100%-128px)] h-auto flex">
          <div className="w-1/2 h-auto pr-3">
            <InputField
              label="姓"
              name="lastNameKanji"
              value={searchValues.lastNameKanji}
              type="text"
              onChange={(newValue) =>
                setSearchFieldValue("lastNameKanji", newValue)
              }
            />
          </div>
          <div className="w-1/2 h-auto pl-3">
            <InputField
              label="名"
              name="firstNameKanji"
              value={searchValues.firstNameKanji}
              type="text"
              onChange={(newValue) =>
                setSearchFieldValue("firstNameKanji", newValue)
              }
            />
          </div>
        </div>
      </div>

      <div className="w-full h-auto flex items-start mb-6">
        <div className="w-32 h-full flex items-start font-semibold leading-9">
          生年月日
        </div>
        <div className="w-[calc(100%-128px)] h-auto">
          <InputDateField
            value={searchValues.dob}
            setValue={(newValue) =>
              setSearchFieldValue("dob", newValue)
            }
          />
        </div>
      </div>

      <div className="w-full h-auto flex items-start mb-6">
        <div className="w-32 h-full flex items-start font-semibold leading-9">
          電話番号
        </div>
        <div className="w-[calc(100%-128px)] h-auto">
          <InputField
            name="phone"
            value={searchValues.phone}
            type="text"
            onChange={(newValue) =>
              setSearchFieldValue("phone", newValue)
            }
          />
        </div>
      </div>

      <div className="w-full h-auto flex items-start mb-6">
        <div className="w-32 h-full flex items-start font-semibold leading-9">
          メールアドレス
        </div>
        <div className="w-[calc(100%-128px)] h-auto">
          <InputField
            name="email"
            value={searchValues.email}
            type="text"
            onChange={(newValue) =>
              setSearchFieldValue("email", newValue)
            }
          />
        </div>
      </div>

      <div className="w-full h-auto flex items-start">
        <div className="w-32 h-full flex items-start font-semibold leading-9">
          住所
        </div>
        <div className="w-[calc(100%-128px)] h-auto">
          <div className="w-full h-auto mb-4">
            <InputField
              name="postalCode"
              value={searchValues.postalCode}
              type="text"
              onChange={(newValue) =>
                setSearchFieldValue("postalCode", newValue)
              }
            />
          </div>
          <div className="w-full h-auto">
            <InputField
              name="address"
              value={searchValues.address}
              type="text"
              onChange={(newValue) =>
                setSearchFieldValue("address", newValue)
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SearchCustomerForm;
