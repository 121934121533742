import React from "react";
import noData from "app/assets/images/no-data.png";

function NoData(props) {
  const { message, actions } = props;
  return (
    <div className="w-full h-full flex flex-col items-center justify-center">
      <img alt="no data" className="w-20 h-20" src={noData} />

      {message ? (
        <p className="mt-8 text-base font-semibold">{message}</p>
      ) : null}

      {actions ? actions : null}
    </div>
  );
}

export default NoData;
