import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import DefaultLayout from "app/layouts/DefaultLayout";
import MainContentLayout from "app/layouts/MainContentLayout";
import LoadingProgress from "app/components/commonUI/LoadingProgress";
import LoadInitError from "app/components/commonUI/LoadInitError";
import Path from "app/route/Path";
import { getAddPaymentToday } from "app/services/api/1E";
import { payment_type } from "app/constants";
import { formatPrice } from "app/utils";

function CashierManagementSalesTotalToday() {
  const navigate = useNavigate();
  const [fetchTotalSalesToday, setFetchTotalSalesToday] = useState({
    isLoading: false,
    data: null,
    error: null,
  });

  const loadTotalSalesToday = () => {
    setFetchTotalSalesToday({
      isLoading: true,
      data: null,
      error: null,
    });

    getAddPaymentToday()
      .then((res) => {
        setFetchTotalSalesToday({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        setFetchTotalSalesToday({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  };

  useEffect(() => {
    loadTotalSalesToday();
  }, []);

  const totalRevenueToday = useMemo(() => {
    const result = {
      all: {
        numberOfPayment: 0,
        amount: 0,
      },
      [payment_type.cash]: {
        numberOfPayment: 0,
        amount: 0,
      },
      [payment_type.credit_card]: {
        numberOfPayment: 0,
        amount: 0,
      },
      [payment_type.qr_code]: {
        numberOfPayment: 0,
        amount: 0,
      },
      [payment_type.eletronic_payment]: {
        numberOfPayment: 0,
        amount: 0,
      },
    };
    if (
      !Array.isArray(fetchTotalSalesToday.data) ||
      fetchTotalSalesToday.data.length === 0
    )
      return result;

    const allPaymentToday = fetchTotalSalesToday.data;
    const allPaymentByCash = allPaymentToday.filter(
      (item) => item.paymentType === payment_type.cash
    );
    const allPaymentByCreditCard = allPaymentToday.filter(
      (item) => item.paymentType === payment_type.credit_card
    );
    const allPaymentByQrCode = allPaymentToday.filter(
      (item) => item.paymentType === payment_type.qr_code
    );

    result[payment_type.cash] = {
      numberOfPayment: allPaymentByCash.length,
      amount: allPaymentByCash.reduce((a, b) => a + b.paymentAmount, 0),
    };
    result[payment_type.credit_card] = {
      numberOfPayment: allPaymentByCreditCard.length,
      amount: allPaymentByCreditCard.reduce((a, b) => a + b.paymentAmount, 0),
    };
    result[payment_type.qr_code] = {
      numberOfPayment: allPaymentByQrCode.length,
      amount: allPaymentByQrCode.reduce((a, b) => a + b.paymentAmount, 0),
    };
    result[payment_type.eletronic_payment] = {
      numberOfPayment:
        allPaymentToday.length -
        result[payment_type.cash].numberOfPayment -
        result[payment_type.credit_card].numberOfPayment -
        result[payment_type.qr_code].numberOfPayment,
      amount:
        allPaymentToday.reduce((a, b) => a + b.paymentAmount, 0) -
        result[payment_type.cash].amount -
        result[payment_type.credit_card].amount -
        result[payment_type.qr_code].amount,
    };
    result.all = {
      numberOfPayment: allPaymentToday.length,
      amount: allPaymentToday.reduce((a, b) => a + b.paymentAmount, 0),
    };

    return result;
  }, [fetchTotalSalesToday.data]);

  return (
    <DefaultLayout>
      <MainContentLayout
        pageTitle={
          <>
            <p>お客様の</p>
            <p>お帰り</p>
          </>
        }
      >
        <div className="w-full h-full pt-16 pb-8 lg:py-12 relative">
          <div className="w-full h-16 lg:h-12 pl-20 lg:pl-0 flex items-center justify-start absolute inset-x-0 top-0 bg-white">
            <h2 className="text-base lg:text-xl font-semibold">
              本日の売上集計
            </h2>
          </div>
          <div className="w-full h-full pr-3  p-4">
            {fetchTotalSalesToday.isLoading ? <LoadingProgress /> : null}

            {!fetchTotalSalesToday.isLoading && fetchTotalSalesToday.error ? (
              <LoadInitError error={fetchTotalSalesToday.error} />
            ) : null}
            {!fetchTotalSalesToday.isLoading && !fetchTotalSalesToday.error ? (
              <div className="w-full h-full flex flex-col items-center justify-between py-6">
                <div className="w-full flex flex-col items-center gap-8 ">
                  <div className="text-2xl font-medium">会計　{totalRevenueToday.all.numberOfPayment}件</div>
                  <div className="w-full flex justify-center gap-2 text-5xl font-bold">
                    <div className="tracking-widest">
                      {formatPrice(totalRevenueToday.all.amount)}
                    </div>
                  </div>
                </div>
                <div className="w-full flex flex-col">
                  <div className="w-full border-t-2 flex justify-between py-2">
                    <div className="w-[55%] flex justify-between">
                      <div>現金</div>
                      <div>
                        {totalRevenueToday[payment_type.cash].numberOfPayment}件
                      </div>
                    </div>
                    <div>
                      {formatPrice(totalRevenueToday[payment_type.cash].amount)}
                    </div>
                  </div>
                  <div className="w-full border-t-2 flex justify-between py-2">
                    <div className="w-[55%] flex justify-between">
                      <div>クレジットカード</div>
                      <div>
                        {
                          totalRevenueToday[payment_type.credit_card]
                            .numberOfPayment
                        }
                        件
                      </div>
                    </div>
                    <div>
                      {formatPrice(
                        totalRevenueToday[payment_type.credit_card].amount
                      )}
                    </div>
                  </div>
                  <div className="w-full border-t-2 flex justify-between py-2">
                    <div className="w-[55%] flex justify-between">
                      <div>QR決済</div>
                      <div>
                        {
                          totalRevenueToday[payment_type.qr_code]
                            .numberOfPayment
                        }
                        件
                      </div>
                    </div>
                    <div>
                      {formatPrice(
                        totalRevenueToday[payment_type.qr_code].amount
                      )}
                    </div>
                  </div>
                  <div className="w-full border-y-2 flex justify-between py-2">
                    <div className="w-[55%] flex justify-between">
                      <div>電子マネー</div>
                      <div>
                        {
                          totalRevenueToday[payment_type.eletronic_payment]
                            .numberOfPayment
                        }
                        件
                      </div>
                    </div>
                    <div>
                      {formatPrice(
                        totalRevenueToday[payment_type.eletronic_payment].amount
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="w-full h-full flex items-center justify-center">
                No data
              </div>
            )}
          </div>
          <div className="w-full h-8 flex items-center justify-end text-white text-base font-semibold absolute inset-x-0 bottom-0">
            <button
              onClick={() => navigate(Path.cashierManagement)}
              type="button"
              className="button-size bg-gray-btn-secondary"
            >
              戻る
            </button>
          </div>
        </div>
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default CashierManagementSalesTotalToday;
