import moment from "moment";
import { defaultDateFormat } from "../constants";
import "moment-timezone";

export const formatPrice = (priceNumber) => {
  return `${new Intl.NumberFormat("ja-JP").format(priceNumber)}円`;
};

export const convertTimeToNumber = (timeStr) => {
  const timeArr = timeStr.split(":");
  return Number(timeArr[0]) + Number(timeArr[1]) / 60;
};

export const checkNotTimeValid = (
  listRangeNotAvailable,
  rangeToCheck,
  maxTime,
  minTime
) => {
  if (typeof maxTime !== "number") maxTime = 24;
  if (typeof minTime !== "number") minTime = 0;
  if (
    rangeToCheck[0] > maxTime ||
    rangeToCheck[1] > maxTime ||
    rangeToCheck[0] < minTime ||
    rangeToCheck[1] < minTime
  )
    return true;

  const invalidRangeNotAvailable = listRangeNotAvailable.find(
    (range) =>
      (range[0] <= rangeToCheck[0] && rangeToCheck[0] < range[1]) ||
      (range[0] < rangeToCheck[1] && rangeToCheck[1] <= range[1]) ||
      (range[0] >= rangeToCheck[0] && rangeToCheck[1] >= range[1])
  );

  return !!invalidRangeNotAvailable;
};

export const getNameFromFirstLast = (obj) => {
  const { lastNameKata, firstNameKata, lastNameKanji, firstNameKanji } = obj;
  // TODO-T:
  return `${lastNameKata || ""} ${firstNameKata || ""}`;
};

export const showMenuItemPrice = (menuItem) => {
  const { priceTaxExc, priceTaxInc } = menuItem;
  return formatPrice(priceTaxInc);
};

export const gettFirstDayOfThisMonth = () =>
  moment(moment().tz("Asia/Tokyo").format(defaultDateFormat))
    .startOf("month")
    .set("hour", 0);

export const formatPaymentType = (paymentType) => {
  switch (paymentType) {
    case "cash":
      return "現金";
    case "credit_card":
      return "クレジットカード";
    case "qr_code":
      return "QR決済";
    case "eletronic_payment":
      return "電子マネー";
    default:
      return "-";
  }
};
