import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import "moment/locale/ja";
import { Avatar, Popover } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDays,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import DefaultLayout from "app/layouts/DefaultLayout";
import MainContentLayout from "app/layouts/MainContentLayout";
import LoadInitError from "app/components/commonUI/LoadInitError";
import LoadingProgress from "app/components/commonUI/LoadingProgress";
import defaultAvatar from "app/assets/svg/default_avatar.svg";
import { getStoreManagementStatus } from "app/services/api";
import { defaultDateFormat } from "app/constants";
import Path from "app/route/Path";
import { convertTimeToNumber, getNameFromFirstLast } from "../../../utils";

const heightOfAnHourBox = 64;
const widthOfAStaffBox = 96;
const gapBetween2Staff = 16;

const timeListHeader = Array.from(new Array(24), (x, index) => index);

function PopoverCalendar(props) {
  const { showCalendarEl, setShowCalendarEl, date, selectDate } = props;

  const [value, setvalue] = useState(() => {
    if (!date) return moment();
    return moment(date, defaultDateFormat);
  });

  useEffect(() => {
    if (!date) setvalue(moment());
    else setvalue(moment(date, defaultDateFormat));
  }, [date]);

  return (
    <Popover
      open={!!showCalendarEl}
      anchorEl={showCalendarEl}
      onClose={() => setShowCalendarEl(null)}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="ja">
        <StaticDatePicker
          value={value}
          onChange={(newValue) => {
            setvalue(newValue);
          }}
          onAccept={() => {
            selectDate(value.format(defaultDateFormat));
            setShowCalendarEl(null);
          }}
          onClose={() => setShowCalendarEl(null)}
          slots={{ toolbar: "div" }}
        />
      </LocalizationProvider>
    </Popover>
  );
}

function ReservationItem(props) {
  const { reservationInfo, staffIndex } = props;

  const startTimeNumber = convertTimeToNumber(reservationInfo.startTime);
  const finishTimeNumber = convertTimeToNumber(reservationInfo.finishTime);
  const navigate = useNavigate();

  return (
    <div
      onClick={() =>
        navigate(Path.reservationManagementDetail(reservationInfo.id))
      }
      className="flex flex-col items-center justify-center absolute"
      style={{
        height: `${heightOfAnHourBox * (finishTimeNumber - startTimeNumber)}px`,
        width: `${widthOfAStaffBox}px`,
        padding: `0px ${gapBetween2Staff / 2}px`,
        top: `${heightOfAnHourBox * (startTimeNumber - timeListHeader[0])}px`,
        left: `${widthOfAStaffBox * staffIndex}px`,
        cursor: "pointer",
      }}
    >
      <div className="w-full h-full bg-gray-100 border border-gray-300 rounded-md shadow-md text-center text-sm p-1.5">
        {getNameFromFirstLast(reservationInfo.customer)}
      </div>
    </div>
  );
}

function TableReservation(props) {
  const { staffList } = props;

  const staffHeaderRef = useRef(null);
  const timeHeaderRef = useRef(null);

  const handleScroll = (event) => {
    console.log(
      "event.currentTarget.scrollTop :",
      event.currentTarget.scrollTop
    );

    if (staffHeaderRef.current) {
      staffHeaderRef.current.scrollTo(event.currentTarget.scrollLeft, 0);
    }

    if (timeHeaderRef) {
      timeHeaderRef.current.scrollTo(0, event.currentTarget.scrollTop);
    }
  };

  return (
    <div className="w-full h-full pt-20 pl-12 md:pl-20 relative">
      <div
        className="absolute left-0 top-20 bottom-0 w-12 md:w-20 h-[calc(100%-80px)] overflow-auto hidden-scrollbar py-2"
        ref={timeHeaderRef}
      >
        {timeListHeader.map((item) => (
          <div className="w-full h-16 relative border-t-2 border-gray-400">
            <p className="absolute leading-6 -top-3 left-1/2 -translate-x-1/2 w-full text-center text-sm lg:text-base">
              <span className="bg-white">{item + ":00"}</span>
            </p>
          </div>
        ))}
      </div>

      <div
        className="absolute left-20 top-0 bottom-0 h-20 w-[calc(100%-80px)] overflow-auto flex flex-nowrap hidden-scrollbar px-2"
        ref={staffHeaderRef}
      >
        {staffList.map((staff) => (
          <div
            className="w-24 h-full px-2 flex flex-col items-center justify-center shrink-0"
            style={{
              height: "100%",
              width: `${widthOfAStaffBox}px`,
              padding: `0px ${gapBetween2Staff / 2}px`,
              paddingTop: "8px",
            }}
          >
            <Avatar
              sx={{ width: 40, height: 40, marginBottom: "8px" }}
              alt=""
              src={staff.imageUrl || defaultAvatar}
            />
            <span className="text-sm w-full leading-3 overflow-hidden truncate text-center">
              {getNameFromFirstLast(staff)}
            </span>
          </div>
        ))}
      </div>

      <div
        className="w-full h-full overflow-auto p-2 small-scrollbar"
        onScroll={handleScroll}
      >
        <div
          className="relative"
          style={{
            height: `${heightOfAnHourBox * timeListHeader.length}px`,
            width: `${widthOfAStaffBox * staffList.length}px`,
          }}
        >
          {staffList.map((staff, staffIndex) => {
            if (
              Array.isArray(staff.reservationList) &&
              staff.reservationList.length > 0
            ) {
              return staff.reservationList.map((reservation) => (
                <ReservationItem
                  key={reservation.id}
                  staffIndex={staffIndex}
                  reservationInfo={reservation}
                />
              ));
            }
            return null;
          })}

          {staffList.map((staff, staffIndex) => {
            if (staff.availableShiftPlan) {
              const startAvailableNumber = convertTimeToNumber(
                staff.availableShiftPlan.startTime
              );
              const endAvailableNumber = convertTimeToNumber(
                staff.availableShiftPlan.finishTime
              );

              return (
                <div
                  className="absolute -z-[1] rounded-md bg-green-50 cursor-pointer overflow-hidden border border-green-500"
                  style={{
                    height: `${
                      (endAvailableNumber - startAvailableNumber) *
                      heightOfAnHourBox
                    }px`,
                    width: `${widthOfAStaffBox}px`,
                    top: `${heightOfAnHourBox * startAvailableNumber}px`,
                    left: `${widthOfAStaffBox * staffIndex}px`,
                  }}
                ></div>
              );
            }

            return null;
          })}
        </div>
      </div>
    </div>
  );
}

function ReservationManagementTopPage() {
  const navigate = useNavigate();
  const [fetchReservationState, setFetchCustomerBasicInfoState] = useState({
    isLoading: true,
    data: null,
    error: null,
  });
  const [showCalendarEl, setShowCalendarEl] = useState(null);
  const [selectedDate, setSelectedDate] = useState(() => {
    const formatCurrentDate = moment().format(defaultDateFormat);
    return formatCurrentDate;
  });

  const loadReservation = useCallback(() => {
    if (!selectedDate) return;
    setFetchCustomerBasicInfoState({
      isLoading: true,
      data: null,
      error: null,
    });

    getStoreManagementStatus(selectedDate)
      .then((res) => {
        setFetchCustomerBasicInfoState({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        setFetchCustomerBasicInfoState({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  }, [selectedDate]);

  useEffect(() => {
    loadReservation();
  }, [loadReservation]);

  const handleNextDate = () => {
    setSelectedDate((oldDate) => {
      if (!oldDate) return moment().format(defaultDateFormat);

      const oldDateObj = moment(oldDate, defaultDateFormat);
      const newDateObj = oldDateObj.add(1, "day");
      return newDateObj.format(defaultDateFormat);
    });
  };

  const handleBackToYesterday = () => {
    setSelectedDate((oldDate) => {
      if (!oldDate) return moment().format(defaultDateFormat);

      const oldDateObj = moment(oldDate, defaultDateFormat);
      const newDateObj = oldDateObj.subtract(1, "day");
      return newDateObj.format(defaultDateFormat);
    });
  };

  return (
    <DefaultLayout>
      <MainContentLayout
        pageTitle={
          <>
            <p>予約を</p>
            <p>確認する</p>
          </>
        }
      >
        <div className="w-full h-full pt-16 lg:pt-12 relative">
          <div className="w-full h-16 lg:h-12 pl-20 lg:pl-0 flex items-center justify-end absolute inset-x-0 top-0 bg-white">
            <button
              onClick={() => navigate(Path.reservationManagementRegister)}
              type="button"
              className="button-size bg-blue-btn-primary mr-4 lg:mr-6"
            >
              予約を登録する
            </button>

            <button
              type="button"
              className="button-size bg-blue-btn-primary"
              onClick={(e) => setShowCalendarEl(e.currentTarget)}
            >
              <FontAwesomeIcon icon={faCalendarDays} />
            </button>
          </div>

          <div className="w-full h-full pt-5">
            <div className="w-full h-full">
              <div className="w-full h-full rounded-xl border border-gray-400 relative">
                <div className="absolute w-auto h-8 flex items-center justify-center px-4 left-8 -top-5 bg-white z-20">
                  <button
                    type="button"
                    onClick={handleBackToYesterday}
                    className="px-2"
                  >
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </button>
                  <h3 className="text-xl leading-8 font-semibold mx-3">
                    {selectedDate}
                  </h3>
                  <button
                    type="button"
                    onClick={handleNextDate}
                    className="px-2"
                  >
                    <FontAwesomeIcon icon={faChevronRight} />
                  </button>
                </div>
                <div className="w-full h-full overflow-auto px-2 pb-2">
                  {fetchReservationState.isLoading ? <LoadingProgress /> : null}

                  {!fetchReservationState.isLoading &&
                  fetchReservationState.error ? (
                    <LoadInitError error={fetchReservationState.error} />
                  ) : null}
                  {!fetchReservationState.isLoading &&
                  !fetchReservationState.error ? (
                    Array.isArray(fetchReservationState.data) &&
                    fetchReservationState.data.length > 0 ? (
                      <TableReservation
                        staffList={fetchReservationState.data}
                      />
                    ) : (
                      <div>Not Found Reservation To Day</div>
                    )
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>

        <PopoverCalendar
          showCalendarEl={showCalendarEl}
          setShowCalendarEl={setShowCalendarEl}
          date={selectedDate}
          selectDate={setSelectedDate}
        />
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default ReservationManagementTopPage;
