import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import { Avatar } from "@mui/material";

import DefaultLayout from "app/layouts/DefaultLayout";
import MainContentLayout from "app/layouts/MainContentLayout";
import LoadingProgress from "app/components/commonUI/LoadingProgress";
import LoadInitError from "app/components/commonUI/LoadInitError";
import ConfirmUpdateDialog from "app/components/CommonDialog/ConfirmUpdateDialog";
import defaultAvatar from "app/assets/svg/default_avatar.svg";
import { convertTimeToNumber, getNameFromFirstLast } from "app/utils";
import InputTimeField from "app/components/commonUI/InputTimeField";
import {
  defaultDateFormat,
  defaultDateTimeFormat,
  defaultTimeFormat,
} from "app/constants";
import Path from "app/route/Path";
import {
  getDetailSalonStaff,
  getStaffShiftPlanToday,
  staffShiftPlanAndCheckIn,
  updateStaffAttendance,
} from "app/services/api";

function StaffManagementUpdateStaffAttendancePageContent(props) {
  const { staffId, staffInfo } = props;
  const navigate = useNavigate();

  const [showConfirmUpdate, setShowConfirmUpdate] = useState(false);

  const [inputTime, setInputTime] = useState(() =>
    moment().tz("Asia/Tokyo").format(defaultTimeFormat)
  );

  const handleShiftPlanWithCheckIn = async () => {
    const jpToday = moment().tz("Asia/Tokyo");

    return await staffShiftPlanAndCheckIn(staffId, {
      workDate: jpToday,
      startTime: inputTime,
      finishTime: "23:59",
    });
  };

  const disabledUpdateStartTime = false;

  const disabledUpdateFinishTime = true;

  const checkInputTimeIsValid = () => {
    if (!inputTime) {
      toast.error("入力時間が必要です!");
      return false;
    }

    return true;
  };

  const handleClickUpdate = () => {
    if (checkInputTimeIsValid()) setShowConfirmUpdate(true);
  };

  return (
    <>
      <div className="w-full h-full flex items-center justify-center">
        <div className="w-32 h-auto flex flex-col items-center justify-center pr-4">
          <div className="w-28 h-28 rounded-full">
            <Avatar
              alt=""
              src={staffInfo.imageUrl || defaultAvatar}
              sx={{ width: 112, height: 112, border: "2px solid black" }}
            />

            <p className="w-full h-auto text-center truncate mt-6 text-lg font-semibold">
              {staffInfo ? getNameFromFirstLast(staffInfo) : ""}
            </p>
          </div>
        </div>

        <div className="w-[calc(100%-160px)] h-auto flex flex-col items-center">
          <div className=" mb-8">
            <InputTimeField
              time={inputTime}
              setTime={setInputTime}
              sx={{
                "& .MuiInputBase-input": {
                  padding: "4px 0px 4px 8px !important",
                  lineHeight: "48px !important",
                  height: "48px !important",
                  fontSize: "24px",
                  maxWidth: "170px",
                },
                "& .Mui-disabled": {
                  backgroundColor: "#f3f4f6 !important",
                },
                "& input.Mui-disabled ": {
                  color: "#000 !important",
                  "-webkit-text-fill-color": "#000 !important",
                },
              }}
              minutesStep={1}
            />
          </div>

          <div className="w-full h-auto flex items-center justify-center gap-16">
            <button
              type="button"
              onClick={handleClickUpdate}
              className={`w-48 h-28 border border-gray-400 rounded-xl flex items-center justify-center hover:bg-slate-100 hover:shadow-md ${
                disabledUpdateStartTime ? "cursor-default bg-slate-100" : ""
              }`}
              disabled={disabledUpdateStartTime}
            >
              <span className="text-xl font-semibold">出勤</span>
            </button>

            <button
              type="button"
              onClick={handleClickUpdate}
              className={`w-48 h-28 border border-gray-400 rounded-xl flex items-center justify-center hover:bg-slate-100 hover:shadow-md ${
                disabledUpdateFinishTime ? "cursor-default bg-slate-100" : ""
              }`}
              disabled={disabledUpdateFinishTime}
            >
              <span className="text-xl font-semibold">退勤</span>
            </button>
          </div>
        </div>
      </div>

      <ConfirmUpdateDialog
        open={showConfirmUpdate}
        handleClose={() => setShowConfirmUpdate(false)}
        confirmTitle="出勤しますか？"
        confirmMessage=""
        updateCompleteTitle="出勤しました"
        updateCompleteBtnLabel="終る"
        updateCompleteAction={() => {
          navigate(Path.staffManagement);
        }}
        updateAction={handleShiftPlanWithCheckIn}
        confirmCancleLabel="キャンセル"
        confirmLabel="出勤"
      />
    </>
  );
}

function StaffManagementUpdateStaffAttendancePage() {
  const [fetchInitState, setFetchInitState] = useState({
    isLoading: false,
    data: null,
    error: null,
  });

  const { staffId } = useParams();
  const navigate = useNavigate();

  const loadInit = useCallback(() => {
    setFetchInitState({
      isLoading: true,
      data: null,
      error: null,
    });

    Promise.all([getStaffShiftPlanToday(staffId), getDetailSalonStaff(staffId)])
      .then((res) => {
        if (res[0].data) {
          const attendance = res[0].data.attendance;
          console.log("attendance", attendance);

          navigate(
            Path.staffManagementUpdateStaffAttendance(staffId, attendance.id)
          );
          return;
        }
        setFetchInitState({
          isLoading: false,
          data: {
            staffInfo: res[1].data,
          },
          error: null,
        });
      })
      .catch((error) => {
        setFetchInitState({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  }, [staffId]);

  useEffect(() => {
    loadInit();
  }, [loadInit]);

  return (
    <DefaultLayout>
      <MainContentLayout
        pageTitle={
          <>
            <p>予約を</p>
            <p>確認する</p>
          </>
        }
      >
        <div className="w-full h-full pt-16 pb-8 lg:py-12 relative">
          <div className="w-full h-16 lg:h-12 pl-20 lg:pl-0 flex items-center justify-start absolute inset-x-0 top-0 bg-white">
            <h2 className="text-base lg:text-xl font-semibold"></h2>
          </div>

          <div className="w-full h-full py-3">
            <div className="w-full h-full overflow-auto">
              {fetchInitState.isLoading ? <LoadingProgress /> : null}

              {!fetchInitState.isLoading && fetchInitState.error ? (
                <LoadInitError error={fetchInitState.error} />
              ) : null}
              {!fetchInitState.isLoading &&
              !fetchInitState.error &&
              fetchInitState?.data?.staffInfo ? (
                <StaffManagementUpdateStaffAttendancePageContent
                  staffId={staffId}
                  staffInfo={fetchInitState.data.staffInfo}
                />
              ) : null}
            </div>
          </div>

          <div className="w-full h-8 flex items-center justify-end text-white text-base font-semibold absolute inset-x-0 bottom-0">
            <Link
              to={Path.staffManagement}
              className="button-size bg-gray-btn-secondary"
            >
              戻る
            </Link>
          </div>
        </div>
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default StaffManagementUpdateStaffAttendancePage;
