import React from "react";
import { useNavigate } from "react-router-dom";
import Path from "app/route/Path";
import AnalyzeBox from "./AnalyzeBox";
import CustomPieChart from "../../components/CustomPieChart";

function TurnoverAnalyze() {
  const navigate = useNavigate();

  return (
    <AnalyzeBox
      title="回転率"
      onClickTitle={() => navigate(Path.turnoverAnalyzeManagement)}
    >
      <CustomPieChart rate={0.3} />
    </AnalyzeBox>
  );
}

export default TurnoverAnalyze;
