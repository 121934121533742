import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import ErrorIcon from "@mui/icons-material/Error";
import DefaultLayout from "app/layouts/DefaultLayout";
import MainContentLayout from "app/layouts/MainContentLayout";
import LoadingProgress from "app/components/commonUI/LoadingProgress";
import LoadInitError from "app/components/commonUI/LoadInitError";
import defaultAvatar from "app/assets/svg/default_avatar.svg";
import InputDateField from "app/components/commonUI/InputDateField";
import InputField from "app/components/commonUI/InputField";
import { formatPrice, getNameFromFirstLast } from "app/utils";
import Path from "app/route/Path";
import {
  defaultDateFormat,
  jpDateFormat,
  payment_type_and_method,
} from "app/constants";

import {
  addManyNonCustomerPaymentWithStaff,
  getDetailSalonStaff,
} from "../../../services/api";
import { toast } from "react-toastify";

const validationErrMessages = {
  workDateRequired: "日付は必須です。",
  amountRequired: "金額は必須です。",
  paymentTypeRequired: "支払方法は必須です。",
};

function StaffItem(props) {
  const { staffInfo } = props;

  return (
    <div className="w-full flex flex-col items-center px-4">
      <img
        alt={staffInfo.name || ""}
        src={staffInfo?.imageUrl || defaultAvatar}
        className="w-14 h-14 md:w-20 md:h-20 object-cover mb-3 rounded-full overflow-hidden border-2 border-black"
      />
      <div className="w-full h-auto text-sm md:text-base text-center font-bold truncate pb-5">
        {staffInfo ? getNameFromFirstLast(staffInfo) : ""}
      </div>
    </div>
  );
}

function InputResultItem(props) {
  const { inputResult, handleDetele } = props;

  const paymentTypeLabel = useMemo(() => {
    const paymentTypeObj = payment_type_and_method[inputResult.paymentType];
    return paymentTypeObj ? paymentTypeObj.payment_type_label : "";
  }, [inputResult]);

  return (
    <div className="w-full h-auto flex items-center pr-10 pl-3 py-2 bg-slate-100 mb-1 rounded-md relative text-gray-800">
      <div className="w-1/3">
        {moment(inputResult.workDate, defaultDateFormat).format(jpDateFormat)}
      </div>
      <div className="w-1/3">{formatPrice(inputResult.amount)}</div>
      <div className="w-1/3">{paymentTypeLabel}</div>

      <button
        onClick={() => handleDetele(inputResult)}
        className="absolute top-1/2 right-2 -translate-y-1/2 w-8 h-8 rounded hover:bg-slate-300 text-red-500"
      >
        <FontAwesomeIcon icon={faTrashCan} />
      </button>
    </div>
  );
}

function InputSaleStaffPageContent(props) {
  const { staffId } = props;
  const navigate = useNavigate();
  const { staffInfo } = props;
  const [inputResultList, setInputResultList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const now = useMemo(() => moment(), []);

  const validationSchema = Yup.object().shape({
    workDate: Yup.object().required(validationErrMessages.workDateRequired),
    amount: Yup.number().required(validationErrMessages.amountRequired),
    paymentType: Yup.string().required(
      validationErrMessages.paymentTypeRequired
    ),
  });

  const onSubmit = async (values) => {
    setInputResultList((oldList) => [
      ...oldList,
      {
        id: uuidv4(),
        workDate: values.workDate.format(defaultDateFormat),
        amount: Number(values.amount),
        paymentType: values.paymentType,
      },
    ]);

    resetForm();
  };

  const {
    handleSubmit,
    errors,
    values,
    setFieldValue,
    resetForm,
    touched,
    handleBlur,
  } = useFormik({
    initialValues: {
      workDate: null,
      amount: 0,
      paymentType: "",
    },
    onSubmit,
    validationSchema,
  });

  const handleDeteleInputResult = (deleteData) => {
    setInputResultList((oldList) => {
      return oldList.filter((item) => item.id !== deleteData.id);
    });
  };

  const handleSave = () => {
    if (inputResultList.length === 0) {
      navigate(Path.saleOfStaffMemberDetail(staffId));
      return;
    }

    setIsLoading(true);
    addManyNonCustomerPaymentWithStaff(staffId, inputResultList)
      .then((res) => {
        toast.success("成功した販売情報を追加します。");
        navigate(Path.saleOfStaffMemberDetail(staffId));
        setIsLoading(false);
      })
      .catch((error) => {
        toast.error("失敗した販売情報を追加します。");
        setIsLoading(false);
      });
  };

  return (
    <>
      <div className="w-full h-full flex flex-wrap">
        <div className="w-full h-auto md:w-[220px] md:h-full flex flex-col items-center">
          <StaffItem staffInfo={staffInfo} />
        </div>

        <div className="w-full md:w-[calc(100%-240px)] h-full flex flex-col">
          {/* Input Form */}
          <div className="flex flex-col">
            <div className="w-full h-auto mb-4 flex">
              <div className="w-24 h-9">
                <p className="text-base font-semibold leading-9">日付</p>
              </div>
              <div className="w-[calc(100%-96px)]">
                <InputDateField
                  value={values.workDate}
                  setValue={(newValue) => setFieldValue("workDate", newValue)}
                  shouldDisableDate={(date) => now.valueOf() < date.valueOf()}
                />

                {touched.workDate && errors.workDate ? (
                  <p className="w-full !text-red-600 !text-[14px]">
                    <span className="w-4 h-4 mr-1 text-base leading-4 shrink-0">
                      <ErrorIcon
                        fontSize="inherit"
                        className="text-xs text-red-600 font-thin"
                      />
                    </span>
                    <span>{errors.workDate}</span>
                  </p>
                ) : null}
              </div>
            </div>

            <div className="w-full h-auto mb-4 flex">
              <div className="w-24 h-9">
                <p className="text-base font-semibold leading-9">金額</p>
              </div>
              <div className="w-[calc(100%-96px)] flex">
                <div className="w-44">
                  <InputField
                    name="amount"
                    value={`${values.amount}`}
                    type="number"
                    inputProps={{
                      min: 0,
                    }}
                    onChange={(newValue) => {
                      if (Number(newValue) < 0) setFieldValue("amount", 0);
                      setFieldValue("amount", Number(newValue));
                    }}
                    handleBlur={handleBlur}
                    touched={touched.amount}
                    errors={errors.amount}
                  />
                </div>
                <div>
                  <p className="text-base font-semibold leading-9 ml-3">円</p>
                </div>
              </div>
            </div>

            <div className="w-full h-auto mb-4 flex">
              <div className="w-24 h-9">
                <p className="text-base font-semibold leading-9">支払方法</p>
              </div>
              <div className="w-[calc(100%-96px)] flex">
                <div className="w-[calc(100%-96px)] inline-flex flex-wrap">
                  {Object.values(payment_type_and_method).map((paymentType) => (
                    <button
                      className={`w-auto h-9 flex items-center justify-center px-4 rounded-full mr-4 hover:shadow-lg mb-2 ${
                        values.paymentType === paymentType.payment_type
                          ? "bg-blue-btn-primary"
                          : "bg-gray-btn-secondary"
                      }`}
                      onClick={() =>
                        setFieldValue("paymentType", paymentType.payment_type)
                      }
                    >
                      <span className="text-sm text-white">
                        {paymentType.payment_type_label}
                      </span>
                    </button>
                  ))}

                  {touched.paymentType && errors.paymentType ? (
                    <p className="w-full !text-red-600 !text-[14px]">
                      <span className="w-4 h-4 mr-1 text-base leading-4 shrink-0">
                        <ErrorIcon
                          fontSize="inherit"
                          className="text-xs text-red-600 font-thin"
                        />
                      </span>
                      <span>{errors.paymentType}</span>
                    </p>
                  ) : null}
                </div>

                <div className="w-[96px] flex justify-end">
                  <button
                    onClick={handleSubmit}
                    type="button"
                    className="button-size bg-blue-btn-primary"
                  >
                    追加
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* Input Result List */}
          <div className="flex-1 w-full overflow-auto border border-gray-400 rounded-md py-4 px-4">
            {inputResultList.map((item) => (
              <InputResultItem
                key={item.id}
                inputResult={item}
                handleDetele={handleDeteleInputResult}
              />
            ))}
          </div>
        </div>
      </div>

      <div className="w-full h-8 flex items-center justify-end text-white text-base font-semibold absolute inset-x-0 bottom-0">
        <Link
          to={Path.listStaffMember}
          className="button-size bg-gray-btn-secondary mr-4"
        >
          戻る
        </Link>
        <button
          onClick={handleSave}
          type="button"
          className="button-size bg-blue-btn-primary"
        >
          売上を見る
        </button>
      </div>
    </>
  );
}

function InputSaleStaffPage() {
  const [loadInitStatus, setLoadInitStatus] = useState({
    isLoading: true,
    data: null,
    error: null,
  });

  const { staffId } = useParams();

  const handleLoadInit = useCallback(() => {
    setLoadInitStatus({
      isLoading: true,
      data: null,
      error: null,
    });

    getDetailSalonStaff(staffId)
      .then((res) => {
        setLoadInitStatus({
          isLoading: false,
          data: {
            staffInfo: res.data,
          },
          error: null,
        });
      })
      .catch((error) => {
        setLoadInitStatus({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  }, [staffId]);

  useEffect(() => {
    handleLoadInit();
  }, [handleLoadInit]);

  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "",
      }}
    >
      <MainContentLayout pageTitle="売上">
        <div className="w-full h-full pt-16 pb-8 lg:py-12 relative">
          <div className="w-full h-16 lg:h-12 pl-20 lg:pl-0 flex items-center justify-start absolute inset-x-0 top-0 bg-white">
            <h2 className="text-base lg:text-xl font-semibold">売上入力</h2>
          </div>

          <div className="w-full h-full py-3 lg:py-0">
            <div className="w-full h-full overflow-auto">
              {loadInitStatus.isLoading ? <LoadingProgress /> : null}

              {!loadInitStatus.isLoading && loadInitStatus.error ? (
                <LoadInitError error={loadInitStatus.error} />
              ) : null}

              {!loadInitStatus.isLoading &&
              !loadInitStatus.error &&
              loadInitStatus.data?.staffInfo ? (
                <InputSaleStaffPageContent
                  staffInfo={loadInitStatus.data.staffInfo}
                  staffId={staffId}
                />
              ) : null}
            </div>
          </div>
        </div>
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default InputSaleStaffPage;
