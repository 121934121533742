import client from "./client";

import {
  checkScalpLabMember,
  getCustomerInfo,
  updateCustomerInfo,
  getCustomerBasicInfo,
  updateCustomerBasicInfo,
  getReservationInfo,
  getAllCategory,
  getListServiceCategory,
  getSalonMenu,
  getAllCounselingSheet,
  addCounselingSheet,
  editCounselingSheet,
  addNewCustomer,
  addNewReservation,
  getAllCustomerVisitHistory,
  getDetailCustomerVisitHistory,
  getAllReservationToDay,
  getIntroductionOfScalpLab,
} from "./fake_services";

import {
  checkCustomerQrCode,
  getCustomerInfoAndCounselingHistory,
  getAvailableTimeAndStaffToReservation,
  createNewReservationAndVisit,
  getDetailCustomer,
  createNewCustomer,
  getDetailReservation,
  startUseServices,
} from "./11";

import { getAllCoundelingSheetByVisit, addCounselingSheetToVisit } from "./12";

import {
  getAllVisitsToday,
  getVisitDetailForPayment,
  updateVisitDetail,
  getAllSaleProducts,
  paymentForVisit,
  paymentForNonVisit,
} from "./13";

import {
  searchAllCustomer,
  getAllCustomerCounseling,
  updateCounseling,
  deleteCounseling,
  getAllCustomerVisit,
  updateCustomerBaseInfo,
  deleteCustomer,
  createNewFirstImpression,
  getAllFirstImpression,
} from "./14";

import {
  getStoreManagementStatus,
  searchCustomerInfo,
  getAllSalonMenus,
  getReservationDetail,
  updateReservationDetail,
  deleteReservation,
  createNewReservation,
  sendCustomerContact,
  createNewReservationForNonCustomer,
} from "./15";

import {
  getAllStaffWithShiftPlanByDate,
  deleteStaffShiftPlan,
  getAllSalonStaffs,
  getDetailSalonStaff,
  deleteSalonStaff,
  updateSalonStaff,
  getDetailSalonStaffWithAttendance,
  updateStaffAttendance,
  getAllStaffShiftPlans,
  registerStaffShiftPlans,
  getAllStaffAttendances,
  registerSalonStaff,
  getStaffAttendance,
  updateStaffShiftPlan,
  getStaffShiftPlanToday,
  staffShiftPlanAndCheckIn,
  getAllStaffWithShiftPlanByMonth,
  checkLoginNameIsValid,
} from "./16";

import {
  getSalonSaleDetail,
  addManyNonCustomerPaymentWithStaff,
  getSalonStaffSaleDetail,
} from "./17";

import {
  sendConsultation,
  getStatisticsCustomerVisit,
  getStatisticsCustomerRepeatRate,
  getStatisticsTurnover,
  getStatisticsAverageSpendPerCustomer,
  getStatisticsCostRate,
  getStatisticsOperatingProfitRatio,
  getStatisticsProductivity,
} from "./1B";
import axios from "axios";

export const login = (loginName, password) => {
  return client.post("/login", { loginName, password });
};

export const postPrintData = (url = "", data) => {
  return axios({
    url: url,
    method: "post",
    headers: { "Content-Type": "text/xml; charset=UTF-8" },
    data: data,
  });
};

export {
  checkScalpLabMember,
  getCustomerInfo,
  updateCustomerInfo,
  deleteCustomer,
  getCustomerBasicInfo,
  updateCustomerBasicInfo,
  getReservationInfo,
  getAllCategory,
  getListServiceCategory,
  getSalonMenu,
  getAllCounselingSheet,
  addCounselingSheet,
  editCounselingSheet,
  addNewCustomer,
  addNewReservation,
  getAllCustomerVisitHistory,
  getDetailCustomerVisitHistory,
  getAllReservationToDay,
  getIntroductionOfScalpLab,

  // 11**
  checkCustomerQrCode,
  getCustomerInfoAndCounselingHistory,
  getAvailableTimeAndStaffToReservation,
  createNewReservationAndVisit,
  getDetailCustomer,
  createNewCustomer,
  getDetailReservation,
  startUseServices,

  // 12**
  getAllCoundelingSheetByVisit,
  addCounselingSheetToVisit,

  // 13**
  getAllVisitsToday,
  getVisitDetailForPayment,
  updateVisitDetail,
  getAllSaleProducts,
  paymentForVisit,
  paymentForNonVisit,

  // 14**
  searchAllCustomer,
  getAllCustomerCounseling,
  updateCounseling,
  deleteCounseling,
  getAllCustomerVisit,
  updateCustomerBaseInfo,
  createNewFirstImpression,
  getAllFirstImpression,

  // 15**
  getStoreManagementStatus,
  searchCustomerInfo,
  getAllSalonMenus,
  getReservationDetail,
  updateReservationDetail,
  deleteReservation,
  createNewReservation,
  sendCustomerContact,
  createNewReservationForNonCustomer,

  // 16**
  getAllStaffWithShiftPlanByDate,
  deleteStaffShiftPlan,
  getAllSalonStaffs,
  getDetailSalonStaff,
  deleteSalonStaff,
  updateSalonStaff,
  getDetailSalonStaffWithAttendance,
  updateStaffAttendance,
  getAllStaffShiftPlans,
  registerStaffShiftPlans,
  getAllStaffAttendances,
  registerSalonStaff,
  getStaffAttendance,
  updateStaffShiftPlan,
  staffShiftPlanAndCheckIn,
  getStaffShiftPlanToday,
  getAllStaffWithShiftPlanByMonth,
  checkLoginNameIsValid,

  // 17**
  getSalonSaleDetail,
  addManyNonCustomerPaymentWithStaff,
  getSalonStaffSaleDetail,

  // 1B**
  sendConsultation,
  getStatisticsCustomerVisit,
  getStatisticsCustomerRepeatRate,
  getStatisticsTurnover,
  getStatisticsAverageSpendPerCustomer,
  getStatisticsCostRate,
  getStatisticsOperatingProfitRatio,
  getStatisticsProductivity,
};
