import React, { useState } from "react";
import { Link } from "react-router-dom";

import DefaultLayout from "app/layouts/DefaultLayout";
import MainContentLayout from "app/layouts/MainContentLayout";
import SearchCustomerForm from "app/components/customerForms/SearchCustomerForm";
import { searchAllCustomer } from "app/services/api";
import Path from "app/route/Path";
import LoadingProgress from "../../../components/commonUI/LoadingProgress";
import LoadInitError from "../../../components/commonUI/LoadInitError";
import NoData from "../../../components/commonUI/NoData";

function CustomerManagementTopContent(props) {
  const { searchValues, setSearchFieldValue, handleSearch } = props;

  return (
    <div className="w-full h-full pt-16 pb-8 lg:pt-12 lg:pb-8 relative">
      <div className="w-full h-16 lg:h-12 pl-20 lg:pl-0 flex items-center justify-between absolute inset-x-0 top-0 bg-white">
        <h2 className="text-base lg:text-xl font-semibold">お客様情報一覧</h2>

        <Link
          className="button-size gap-2 bg-[#6670c8]"
          to={Path.customerManagementRegisterNew}
        >
          <span className="text-white">新規登録</span>
        </Link>
      </div>

      <div className="w-full h-full py-3">
        <div className="w-full h-full overflow-auto">
          <div className="w-full h-auto min-h-full flex flex-col justify-center items-center">
            <div className="w-full h-auto max-w-[880px]">
              <SearchCustomerForm
                searchValues={searchValues}
                setSearchFieldValue={setSearchFieldValue}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="w-full h-8 flex items-center justify-end absolute inset-x-0 bottom-0">
        <Link
          to={Path.home}
          className="button-size bg-gray-btn-secondary mr-4 lg:mr-6"
        >
          戻る
        </Link>
        <button
          onClick={handleSearch}
          type="button"
          className="button-size bg-blue-btn-primary"
        >
          検索
        </button>
      </div>
    </div>
  );
}

function CustomerResultItem(props) {
  const { customer } = props;
  return (
    <Link
      to={Path.customerManagementDetail(customer.id)}
      className="w-full h-auto block mb-6"
    >
      <div className="w-full h-auto flex flex-col px-4 py-3 lg:px-8 lg:py-4 border border-gray-400 rounded-2xl text-sm md:text-base">
        <div className="w-full h-auto flex items-center">
          <div className="w-28 lg:w-32 shrink-0 py-1 font-semibold">
            氏名（カナ）
          </div>
          <div className="w-[100%-128px]">
            {(customer.lastNameKata || "") +
              " " +
              (customer.firstNameKata || "")}
          </div>
        </div>
        <div className="w-full h-auto flex items-center">
          <div className="w-28 lg:w-32 shrink-0 py-1 font-semibold">
            氏名（漢字）
          </div>
          <div className="w-[100%-128px]">
            {(customer.lastNameKanji || "") +
              " " +
              (customer.firstNameKanji || "")}
          </div>
        </div>
        <div className="w-full h-auto flex items-center">
          <div className="w-28 lg:w-32 shrink-0 py-1 font-semibold">
            生年月日
          </div>
          <div className="w-[100%-128px]">{customer.dob}</div>
        </div>
        <div className="w-full h-auto flex items-center">
          <div className="w-28 lg:w-32 shrink-0 py-1 font-semibold">
            電話番号
          </div>
          <div className="w-[100%-128px]">{customer.phone1}</div>
        </div>
        <div className="w-full h-auto flex items-center">
          <div className="w-28 lg:w-32 shrink-0 py-1 font-semibold">
            メールアドレス
          </div>
          <div className="w-[100%-128px]">{customer.email}</div>
        </div>
      </div>
    </Link>
  );
}

function CustomerManagementSearchResult(props) {
  const { backToShowTopContent, searchState } = props;
  return (
    <div className="w-full h-full pt-16 pb-8 lg:pt-12 lg:pb-8 relative">
      <div className="w-full h-16 lg:h-12 pl-20 lg:pl-0 flex items-center justify-between absolute inset-x-0 top-0 bg-white">
        <h2 className="text-base lg:text-xl font-semibold">お客様情報一覧</h2>

        <Link
          className="button-size gap-2 bg-[#6670c8]"
          to={Path.customerManagementRegisterNew}
        >
          <span className="text-white">新規登録</span>
        </Link>
      </div>

      <div className="w-full h-full py-3">
        <div className="w-full h-full overflow-auto">
          <div className="w-full h-auto min-h-full flex flex-col justify-center items-center">
            <div className="w-full h-auto max-w-[880px]">
              <div className="w-full h-auto px-0 py-4 md:p-6 overflow-auto">
                {searchState.isSearching ? <LoadingProgress /> : null}
                {!searchState.isSearching && searchState.error ? (
                  <LoadInitError />
                ) : null}

                {searchState.isSearching ||
                searchState.error ? null : Array.isArray(searchState.data) &&
                  searchState.data.length > 0 ? (
                  searchState.data.map((customer) => (
                    <CustomerResultItem key={customer.id} customer={customer} />
                  ))
                ) : (
                  <NoData />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full h-8 flex items-center justify-end absolute inset-x-0 bottom-0">
        <button
          onClick={backToShowTopContent}
          type="button"
          className="button-size bg-gray-btn-secondary"
        >
          戻る
        </button>
      </div>
    </div>
  );
}

function CustomerManagementTopPage() {
  const [searchValues, setSearchValues] = useState({
    firstNameKata: "",
    lastNameKata: "",
    firstNameKanji: "",
    lastNameKanji: "",
    dob: null,
    phone: "",
    email: "",
    postalCode: "",
    address: "",
  });
  const [showSearchResult, setShowSearchResult] = useState(false);
  const [searchState, setSearchState] = useState({
    isSearching: false,
    searchConditions: {},
    data: null,
    error: null,
  });

  const setSearchFieldValue = (name, value) => {
    setSearchValues((oldState) => {
      const newState = { ...oldState };
      newState[name] = value;
      return newState;
    });
  };

  const handleSearch = () => {
    setShowSearchResult(true);
    setSearchState({
      isSearching: true,
      searchConditions: searchValues,
      data: null,
      error: null,
    });

    searchAllCustomer(searchValues)
      .then((res) => {
        console.log("res", res);
        setSearchState((oldState) => ({
          isSearching: false,
          searchConditions: oldState.searchValues,
          data: res.data,
          error: null,
        }));
      })
      .catch((error) => {
        setSearchState((oldState) => ({
          isSearching: false,
          searchConditions: oldState.searchValues,
          data: null,
          error: error,
        }));
      });
  };

  const backToShowTopContent = () => {
    setShowSearchResult(false);
  };

  return (
    <DefaultLayout>
      <MainContentLayout
        pageTitle={<span className="text-sm">お客様情報</span>}
      >
        {showSearchResult ? (
          <CustomerManagementSearchResult
            searchValues={searchValues}
            setSearchFieldValue={setSearchFieldValue}
            handleSearch={handleSearch}
            backToShowTopContent={backToShowTopContent}
            searchState={searchState}
          />
        ) : (
          <CustomerManagementTopContent
            searchValues={searchValues}
            setSearchFieldValue={setSearchFieldValue}
            handleSearch={handleSearch}
          />
        )}
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default CustomerManagementTopPage;
