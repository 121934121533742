import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import { Backdrop, CircularProgress } from "@mui/material";
import { getAllCounselingSheet, addCounselingSheet } from "app/services/api";
import Path from "app/route/Path";

function CounselingSheetMemo(props) {
  const { handleCancel, handleReloadCounselingSheet } = props;

  const [isSaving, setIsSaving] = useState(false);
  const [counselingInfo, setCounselingInfo] = useState("");

  const handleSaveCounselingSheet = () => {
    if (!counselingInfo || !counselingInfo.trim()) return;

    setIsSaving(true);
    addCounselingSheet(counselingInfo)
      .then((res) => {
        handleReloadCounselingSheet();
        handleCancel();
      })
      .catch((error) => {
        console.log("error: ", error);
        setIsSaving(false);
      });
  };

  return (
    <>
      <div className="w-full h-auto max-w-[880px] mx-auto relative max-h-full flex flex-wrap py-8">
        <div className="w-full h-8 flex items-center justify-start absolute inset-x-0 top-0">
          <h2 className="text-base lg:text-xl font-semibold">カウンセリングシート</h2>
        </div>
        <div className="w-full h-auto py-6">
          <div className="w-full h-full border border-gray-600 min-h-[360px] rounded-2xl">
            <textarea
              value={counselingInfo}
              onChange={(e) => setCounselingInfo(e.target.value)}
              className="w-full h-full rounded-2xl px-4 py-3"
            />
          </div>
        </div>

        <div className="w-full h-8 flex items-center justify-end text-white text-base font-semibold absolute inset-x-0 bottom-0">
          <button
            onClick={handleCancel}
            type="button"
            className="button-size bg-gray-btn-secondary mr-4 lg:mr-6"
          >
            戻る
          </button>
          <button
            onClick={handleSaveCounselingSheet}
            type="button"
            className="button-size bg-blue-btn-primary"
          >
            完了
          </button>
        </div>
      </div>

      <Backdrop sx={{ color: "#fff" }} open={isSaving} onClick={() => {}}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

function CounselingSheetHistoryItem(props) {
  const { counselingInfo, handleClick } = props;
  return (
    <button
      type="button"
      onClick={() => handleClick(counselingInfo)}
      className="w-full h-20 border mb-4 border-gray-600 rounded-2xl px-4 py-1.5 pt-6 relative flex flex-col justify-end hover:shadow-md transition-all"
    >
      <div className="absolute inset-x-0 top-0 h-7 flex items-end pt-1 px-4 font-semibold">
        {counselingInfo.createdAt}
      </div>
      <p className="w-full h-auto overflow-hidden text-sm font line-clamp-2 text-start">
        {counselingInfo.summary}
      </p>
    </button>
  );
}

function CounselingSheetHistory(props) {
  const {
    customerId,
    handleClickAdd,
    counselingList,
    handleClickCounselingItem,
    detailCounselingSheet,
  } = props;

  const navigate = useNavigate();
  const nextToCreateReservation = () => {
    navigate(Path.createNewReservation(customerId));
  };

  return (
    <div className="w-full h-auto max-w-[880px] mx-auto relative max-h-full flex flex-wrap py-12">
      <div className="w-full h-8 flex items-center justify-between absolute inset-x-0 top-0">
        <h2 className="text-base lg:text-xl font-semibold">カウンセリングシート</h2>

        <button
          type="button"
          className="button-size flex items-center justify-center gap-2 bg-[#6670c8]"
          onClick={handleClickAdd}
        >
          <NoteAddIcon className="text-white text-[16px]" />
          <span className="text-white">新規メモ入力</span>
        </button>
      </div>

      <div className="w-1/2 py-6 pr-4 pl-0 max-h-full">
        <div className="w-full h-full border border-gray-600 min-h-[360px] rounded-2xl py-3 pt-12 relative">
          {detailCounselingSheet ? (
            <>
              <div className="absolute inset-x-0 top-0 h-7 flex items-end pt-1 px-4 font-semibold">
                {detailCounselingSheet.createdAt}
              </div>

              <div className="w-full h-full px-6 overflow-auto">
                {detailCounselingSheet.content}
              </div>
            </>
          ) : (
            <div>No Data</div>
          )}
        </div>
      </div>

      <div className="w-1/2 p-6 pl-4 pr-0 flex flex-col max-h-full">
        <div className="w-full max-h-full overflow-auto">
          <div className="flex flex-col">
            {counselingList.length === 0 ? (
              <div className="w-full h-auto min-h-full flex items-center justify-center text-xl font-semibold">
                No Data
              </div>
            ) : (
              counselingList.map((counseling) => (
                <CounselingSheetHistoryItem
                  counselingInfo={counseling}
                  handleClick={handleClickCounselingItem}
                />
              ))
            )}
          </div>
        </div>
      </div>

      <div className="w-full h-8 flex items-center justify-end text-white text-base font-semibold absolute inset-x-0 bottom-0">
        {/* <button
          onClick={() => {}}
          type="button"
          className="button-size rounded bg-gray-btn-secondary mr-6"
        >
          戻る
        </button> */}
        <button
          onClick={nextToCreateReservation}
          type="button"
          className="button-size rounded bg-blue-btn-primary"
        >
          施術確定
        </button>
      </div>
    </div>
  );
}

function CounselingSheet(props) {
  const [showInputMode, setShowInputMode] = useState(false);
  const [fetchCounselingSheetState, setFetchCounselingSheetState] = useState({
    isloading: false,
    data: null,
    error: null,
  });
  const [detailCounselingSheet, setDetailCounselingSheet] = useState(null);

  const { customerId } = useParams();

  const loadAllCounselingSheet = () => {
    setFetchCounselingSheetState({
      isloading: true,
      data: null,
      error: null,
    });

    getAllCounselingSheet()
      .then((res) => {
        setFetchCounselingSheetState({
          isloading: false,
          data: res.data,
          error: null,
        });
        if (Array.isArray(res.data) && res.data.length > 0) {
          setDetailCounselingSheet(res.data[0]);
        }
      })
      .catch((error) => {
        setFetchCounselingSheetState({
          isloading: false,
          data: null,
          error: error,
        });
      });
  };

  useEffect(() => {
    loadAllCounselingSheet();
  }, []);

  const handleClickCounselingItem = (counseling) => {
    if (counseling.id === detailCounselingSheet.id) return;

    setDetailCounselingSheet(counseling);
  };

  if (fetchCounselingSheetState.isloading)
    return (
      <div className="w-full h-full flex items-center justify-center">
        <CircularProgress size={40} />
      </div>
    );

  if (!showInputMode)
    return (
      <CounselingSheetHistory
        customerId={customerId}
        counselingList={fetchCounselingSheetState.data || []}
        handleClickCounselingItem={handleClickCounselingItem}
        handleClickAdd={() => setShowInputMode(true)}
        detailCounselingSheet={detailCounselingSheet}
      />
    );

  return (
    <CounselingSheetMemo
      handleCancel={() => setShowInputMode(false)}
      handleReloadCounselingSheet={loadAllCounselingSheet}
    />
  );
}

export default CounselingSheet;
