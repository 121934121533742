import React from "react";

function LoadInitError(props) {
  const { error, handleNotFound } = props;
  return (
    <div className="w-full h-full flex flex-col items-center justify-center">
      <p className="text-lh font-semibold mb-8">データが見つかりません</p>

      <button type="button" className="" onClick={handleNotFound}></button>
    </div>
  );
}

export default LoadInitError;
