import React from "react";
import { Link } from "react-router-dom";
import Path from "app/route/Path";

function NotFound() {
  return (
    <div className="w-full h-full min-h-screen flex flex-col items-center justify-center">
      <p className="text-xl font-semibold mb-8 text-center">404 NotFound</p>

      <Link to={Path.home} className="button-size bg-blue-btn-primary">
        メイン画面に戻る
      </Link>
    </div>
  );
}

export default NotFound;
