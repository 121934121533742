import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DefaultLayout from "app/layouts/DefaultLayout";
import MainContentLayout from "app/layouts/MainContentLayout";
import LoadingProgress from "app/components/commonUI/LoadingProgress";
import LoadInitError from "app/components/commonUI/LoadInitError";

import { getAllCustomerVisit } from "app/services/api";
import Path from "app/route/Path";
import NoData from "../../../components/commonUI/NoData";
import moment from "moment";
import {
  defaultDateFormat,
  jpDateFormat,
  payment_type_and_method,
} from "../../../constants";
import { formatPrice, getNameFromFirstLast } from "../../../utils";

function VisitHistoryItem(props) {
  const { visitHistory, handleClick } = props;

  return (
    <div className="w-full h-auto px-8 py-6 mb-6 text-start border border-gray-400 rounded-xl">
      {/* Visit date time */}
      <div className="w-full h-auto mb-2 text-lg font-semibold">
        {moment(visitHistory.visitDate, defaultDateFormat).format(jpDateFormat)}
        &nbsp;&nbsp;
        {visitHistory.startTime}
      </div>

      {/* Person in charge */}
      <div className="w-full h-auto flex items-center mb-2">
        <p className="w-24 text-base font-semibold">担当</p>

        <p className="w-[calc(100%-96px)]">
          {visitHistory.staff ? getNameFromFirstLast(visitHistory.staff) : ""}
        </p>
      </div>

      <div className="w-full h-auto flex items-start">
        <p className="w-24 text-base font-semibold">メニュー</p>

        <div className="w-[calc(100%-96px)]">
          <div className="w-full h-auto mb-2">
            {Array.isArray(visitHistory.visitMenus)
              ? visitHistory.visitMenus.map((item) => item.name).join(", ")
              : ""}
          </div>
        </div>
      </div>

      <div className="w-full h-auto flex justify-end">
        <button
          className="button-size bg-gray-btn-secondary text-white"
          onClick={() => {
            handleClick(visitHistory);
          }}
        >
          来店内容詳細ヘ
        </button>
      </div>
    </div>
  );
}

function DetailVisitHistory(props) {
  const { visitHistory, backToList } = props;

  const durationStr = useMemo(() => {
    if (!Array.isArray(visitHistory.visitMenus)) return "";

    const durationNumber =
      visitHistory.visitMenus.reduce((a, b) => a + b.durationNumber, 0) / 60;

    return `${Math.floor(durationNumber)}時間${Math.round(
      (durationNumber % 1) * 60
    )}分`;
  }, [visitHistory]);

  const totalWithoutDiscount = useMemo(() => {
    let total = 0;
    if (Array.isArray(visitHistory.visitMenus)) {
      visitHistory.visitMenus.forEach((item) => (total += item.priceTaxInc));
    }

    if (Array.isArray(visitHistory.sales)) {
      visitHistory.sales.forEach((item) => (total += item.price * item.amount));
    }
    return total;
  }, [visitHistory]);

  const totalDiscount = useMemo(() => {
    let total = 0;
    if (Array.isArray(visitHistory.discounts)) {
      visitHistory.discounts.forEach((item) => (total += item.discountAmount));
    }
    return total;
  }, [visitHistory]);

  const paymentTypeStr = useMemo(() => {
    if (!visitHistory.payment) return "未払い";

    const paymentType = visitHistory.payment.paymentType;
    return payment_type_and_method[paymentType]?.payment_type_label || "";
  }, [visitHistory]);

  return (
    <div className="w-full h-full max-w-[880px] mx-auto relative max-h-full flex flex-wrap pt-12">
      <div className="w-full h-8 flex items-center justify-start absolute inset-x-0 top-0">
        <h2 className="text-base lg:text-xl font-semibold">来店内容詳細</h2>
      </div>

      <div className="w-full h-full flex items-center ">
        <div className="w-full h-auto flex items-stretch max-h-full">
          <div className="w-7/12 max-h-full border border-gray-400 rounded-xl relative">
            <div className="w-full h-auto flex border-b border-gray-400">
              <div className="w-28 text-center border-r border-gray-400 py-2 text-sm leading-6 font-semibold">
                お支払方法
              </div>
              <div className="w-[calc(100%-112px)] py-2 px-6">
                {moment(visitHistory.visitDate, defaultDateFormat).format(
                  jpDateFormat
                )}
                &nbsp;&nbsp;
                {visitHistory.startTime}
              </div>
            </div>
            <div className="w-full h-auto flex border-b border-gray-400">
              <div className="w-28 text-center border-r border-gray-400 py-2 text-sm leading-6 font-semibold">
                施術時間
              </div>
              <div className="w-[calc(100%-112px)] py-2 px-6">
                {durationStr}
              </div>
            </div>
            <div className="w-full h-auto flex border-b border-gray-400">
              <div className="w-28 text-center border-r border-gray-400 py-2 text-sm leading-6 font-semibold">
                指名
              </div>
              <div className="w-[calc(100%-112px)] py-2 px-6">
                {visitHistory.staff
                  ? getNameFromFirstLast(visitHistory.staff)
                  : ""}
              </div>
            </div>
            <div className="w-full h-auto flex border-b border-gray-400">
              <div className="w-28 text-center border-r border-gray-400 py-2 text-sm leading-6 font-semibold">
                メニュー
              </div>
              <div className="w-[calc(100%-112px)] py-2 px-6">
                {Array.isArray(visitHistory.visitMenus)
                  ? visitHistory.visitMenus.map((item) => (
                      <div className="w-full h-auto flex" key={item.id}>
                        <span className="w-1/2 h-auto">{item.name}</span>
                        <span className="w-1/2 h-auto">
                          {formatPrice(item.priceTaxInc)}
                        </span>
                      </div>
                    ))
                  : null}
              </div>
            </div>
            <div className="w-full h-auto flex border-b border-gray-400">
              <div className="w-28 text-center border-r border-gray-400 py-2 text-sm leading-6 font-semibold">
                購入商品
              </div>
              <div className="w-[calc(100%-112px)] py-2 px-6">
                {Array.isArray(visitHistory.sales)
                  ? visitHistory.sales.map((item) => (
                      <div className="w-full h-auto flex" key={item.id}>
                        <span className="w-1/2 h-auto">{item.name}</span>
                        <span className="w-1/2 h-auto">
                          {`${item.amount}x${formatPrice(item.price)}`}
                        </span>
                      </div>
                    ))
                  : null}
              </div>
            </div>
            <div className="w-full h-auto flex border-b border-gray-400">
              <div className="w-28 text-center border-r border-gray-400 py-2 text-sm leading-6 font-semibold">
                お支払金額
              </div>
              <div className="w-[calc(100%-112px)] py-2 px-6 flex flex-col">
                <div className="w-full h-full flex py-2">
                  <span className="w-1/2 h-auto">合計金額</span>
                  <span className="w-1/2 h-auto">
                    {formatPrice(totalWithoutDiscount)}
                  </span>
                </div>

                <div className="w-full h-full flex">
                  <span className="w-1/2 h-auto">ご利用クーポン</span>
                  <span className="w-1/2 h-auto"></span>
                </div>

                <div className="w-full h-full flex py-2">
                  <span className="w-1/2 h-auto">割引</span>
                  <span className="w-1/2 h-auto text-green-400">
                    {formatPrice(totalDiscount)}
                  </span>
                </div>

                <div className="w-full h-[1px] bg-gray-400 my-3" />

                <div className="w-full h-full flex">
                  <span className="w-1/2 h-auto"></span>
                  <span className="w-1/2 h-auto font-semibold">
                    {formatPrice(totalWithoutDiscount - totalDiscount)}
                  </span>
                </div>
              </div>
            </div>
            <div className="w-full h-auto flex">
              <div className="w-28 text-center border-r border-gray-400 py-2">
                お支払方法{" "}
              </div>
              <div className="w-[calc(100%-112px)] py-2 px-6">
                {paymentTypeStr}
              </div>
            </div>

            <div className="absolute w-[71.43%] max-h-full pl-6 inset-y-0 right-0 translate-x-full">
              <div className="w-full h-[calc(100%-80px)] border border-gray-400 rounded-xl p-4">
                <h3 className="w-full h-10 text-lg font-semibold leading-10">
                  施術メモ
                </h3>
                <div className="w-full h-[calc(100%-40px)] overflow-auto">
                  {Array.isArray(visitHistory.counsellings)
                    ? visitHistory.counsellings.map((item) => (
                        <div key={item.id}>{item.memo}</div>
                      ))
                    : null}
                  <div className=""></div>
                </div>
              </div>

              <div className="w-full h-20 flex items-end justify-end">
                <button
                  onClick={backToList}
                  type="button"
                  className="button-size bg-gray-btn-secondary"
                >
                  戻る
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function ListVisitHistory(props) {
  const { visitHistoryList, handleShowDetailVisit, backToDetailPage } = props;
  return (
    <div className="w-full h-full pt-16 pb-8 lg:py-12 relative">
      <div className="w-full h-16 lg:h-12 pl-20 lg:pl-0 flex items-center justify-start absolute inset-x-0 top-0 bg-white">
        <h2 className="text-base lg:text-xl font-semibold">来店履歴</h2>
      </div>

      <div className="w-full h-full py-3">
        <div className="w-full h-full overflow-auto">
          <div className="w-full h-auto max-w-[880px] mx-auto">
            <div className="w-full max-h-full overflow-auto pr-3">
              <div className="flex flex-col">
                {visitHistoryList.length === 0 ? (
                  <NoData />
                ) : (
                  visitHistoryList.map((visitHistory) => (
                    <VisitHistoryItem
                      key={visitHistory.id}
                      visitHistory={visitHistory}
                      handleClick={handleShowDetailVisit}
                    />
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full h-8 flex items-center justify-end text-white text-base font-semibold absolute inset-x-0 bottom-0">
        <button
          onClick={backToDetailPage}
          type="button"
          className="button-size bg-gray-btn-secondary"
        >
          戻る
        </button>
      </div>
    </div>
  );
}

function CustomerVisitHistoryContent(props) {
  const {
    visitHistoryList,
    handleShowDetailVisit,
    backToList,
    backToDetailPage,
    showDetailVisitHistory,
  } = props;

  return (
    <>
      <ListVisitHistory
        visitHistoryList={visitHistoryList}
        handleShowDetailVisit={handleShowDetailVisit}
        backToDetailPage={backToDetailPage}
      />
      <div></div>
      {showDetailVisitHistory ? (
        <div className="absolute inset-0 w-full h-full bg-white">
          <DetailVisitHistory
            visitHistory={showDetailVisitHistory}
            backToList={backToList}
          />
        </div>
      ) : null}
    </>
  );
}

function CustomerVisitHistoryPage() {
  const [fetchVisitHistoryState, setFetchVisitHistoryState] = useState({
    isLoading: false,
    data: null,
    error: null,
  });
  const [showDetailVisitHistory, setShowDetailVisitHistory] = useState(null);

  const navigate = useNavigate();
  const { customerId } = useParams();

  const loadAllVisitHistory = () => {
    setFetchVisitHistoryState({
      isLoading: true,
      data: null,
      error: null,
    });

    getAllCustomerVisit(customerId)
      .then((res) => {
        setFetchVisitHistoryState({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        setFetchVisitHistoryState({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  };

  useEffect(() => {
    loadAllVisitHistory();
  }, []);

  const handleShowDetailVisit = (visitHistory) => {
    if (visitHistory.id === showDetailVisitHistory?.id) return;

    setShowDetailVisitHistory(visitHistory);
  };

  const backToList = () => {
    setShowDetailVisitHistory(null);
  };

  const backToDetailPage = () => {
    navigate(Path.customerManagementDetail(customerId));
  };

  return (
    <DefaultLayout>
      <MainContentLayout
        pageTitle={<span className="text-sm">お客様情報</span>}
      >
        {fetchVisitHistoryState.isLoading ? <LoadingProgress /> : null}

        {!fetchVisitHistoryState.isLoading && fetchVisitHistoryState.error ? (
          <LoadInitError error={fetchVisitHistoryState.error} />
        ) : null}

        {!fetchVisitHistoryState.isLoading &&
        !fetchVisitHistoryState.error &&
        fetchVisitHistoryState.data ? (
          <div className="w-full h-full relative">
            <CustomerVisitHistoryContent
              visitHistoryList={fetchVisitHistoryState.data}
              handleShowDetailVisit={handleShowDetailVisit}
              backToList={backToList}
              backToDetailPage={backToDetailPage}
              showDetailVisitHistory={showDetailVisitHistory}
            />
          </div>
        ) : null}
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default CustomerVisitHistoryPage;
