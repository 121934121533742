import React, { useEffect, useState } from "react";
import DefaultLayout from "../../../layouts/DefaultLayout";
import MainContentLayout from "../../../layouts/MainContentLayout";
import Path from "app/route/Path";
import { Link, useNavigate } from "react-router-dom";
import LoadingProgress from "../../../components/commonUI/LoadingProgress";
import LoadInitError from "../../../components/commonUI/LoadInitError";
import { getDMsAll } from "../../../services/api/1A";
import dayjs from "dayjs";
import { defaultDateFormat } from "../../../constants";
import {
  Button,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import NoData from "../../../components/commonUI/NoData";

function PopoverCalendar(props) {
  const { showCalendarEl, setShowCalendarEl, date, selectDate } = props;

  const [value, setvalue] = useState(() => {
    if (!date) return dayjs();
    return dayjs(date, defaultDateFormat);
  });

  useEffect(() => {
    if (!date) setvalue(dayjs());
    else setvalue(dayjs(date, defaultDateFormat));
  }, [date]);

  return (
    <Popover
      open={!!showCalendarEl}
      anchorEl={showCalendarEl}
      onClose={() => setShowCalendarEl(null)}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
        <StaticDatePicker
          value={value}
          onChange={(newValue) => {
            setvalue(newValue);
          }}
          onAccept={() => {
            selectDate(value.format(defaultDateFormat));
            setShowCalendarEl(null);
          }}
          onClose={() => setShowCalendarEl(null)}
          slots={{ toolbar: "div" }}
        />
      </LocalizationProvider>
    </Popover>
  );
}

function PopoverDeliveryStatus(props) {
  const {
    showDeliveryStatus,
    setShowDeliveryStatus,
    deliveryStatus,
    selectDeliveryStatus,
  } = props;

  const [value, setValue] = useState(deliveryStatus);

  useEffect(() => {
    if (!deliveryStatus) setValue(dayjs());
  }, [deliveryStatus]);

  return (
    <Popover
      open={!!showDeliveryStatus}
      anchorEl={showDeliveryStatus}
      onClose={() => setShowDeliveryStatus(null)}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <div className="w-28 h-16 border border-black ">
        <div className="border border-b-black h-1/2">
          <button
            onClick={() => selectDeliveryStatus("immediate")}
            className="w-full"
          >
            配信待ち
          </button>
        </div>
        <div>
          <button
            onClick={() => selectDeliveryStatus("reserve")}
            className="w-full"
          >
            配信済み
          </button>
        </div>
      </div>
    </Popover>
  );
}

function DmsManagementHistoryPage() {
  const navigate = useNavigate();
  const [showCalendarEl, setShowCalendarEl] = useState(null);
  const [selectedDate, setSelectedDate] = useState("");

  const [showDeliveryStatus, setShowDeliveryStatus] = useState(null);
  const [deliveryStatus, selectDeliveryStatus] = useState("");
  const [fetchHistoryDms, setFetchHistoryDms] = useState({
    isLoading: false,
    data: null,
    error: null,
  });

  const loadHistoryDms = () => {
    setFetchHistoryDms({
      isLoading: true,
      data: null,
      error: null,
    });

    getDMsAll(selectedDate, deliveryStatus)
      .then((res) => {
        setFetchHistoryDms({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        setFetchHistoryDms({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  };

  useEffect(() => {
    loadHistoryDms();
  }, [selectedDate, deliveryStatus]);

  return (
    <DefaultLayout>
      <MainContentLayout
        pageTitle={
          <>
            <p>ダイレクト</p>
            <p>メール</p>
          </>
        }
      >
        <div className="w-full h-full pt-16 pb-8 lg:py-12 relative">
          <div className="w-full h-16 lg:h-12 pl-20 lg:pl-0 flex items-center justify-start absolute inset-x-0 top-0 bg-white">
            <h2 className="text-base lg:text-xl font-semibold">配信履歴</h2>
          </div>
          <div className="w-full h-full py-3">
            <div className="w-full h-full pr-3 border border-gray-400 rounded-2xl p-4">
              <div className="w-full h-full overflow-auto">
                {fetchHistoryDms.isLoading ? <LoadingProgress /> : null}

                {!fetchHistoryDms.isLoading && fetchHistoryDms.error ? (
                  <LoadInitError error={fetchHistoryDms.error} />
                ) : null}
                {!fetchHistoryDms.isLoading && !fetchHistoryDms.error ? (
                  Array.isArray(fetchHistoryDms.data) &&
                  fetchHistoryDms.data.length > 0 ? (
                    <div className="w-full h-full relative overflow-auto min-w-[900px]">
                      {/* header */}
                      <div className="sticky top-0 left-0 w-full h-14 flex bg-gray-100 border-b z-10">
                        <div className="w-1/4 flex items-center justify-center shrink-0 truncate">
                          <div className="w-10">日時</div>
                          <button
                            type="button"
                            className="button-size text-black "
                            onClick={(e) => setShowCalendarEl(e.currentTarget)}
                          >
                            <FontAwesomeIcon icon={faChevronDown} />
                          </button>
                        </div>
                        <div className="w-1/4 flex items-center justify-center shrink-0 truncate">
                          タイトル
                        </div>
                        <div className="w-1/4 flex items-center justify-center shrink-0 truncate">
                          配信状況
                          <button
                            type="button"
                            className="button-size text-black flex justify-end"
                            onClick={(e) =>
                              setShowDeliveryStatus(e.currentTarget)
                            }
                          >
                            <FontAwesomeIcon icon={faChevronDown} />
                          </button>
                        </div>
                        <div className="w-1/4 flex items-center justify-center shrink-0 truncate"></div>
                      </div>

                      {/* body */}
                      {fetchHistoryDms?.data?.map((row, index) => (
                        <div className="w-full h-14 flex bg-white border-b">
                          <div className="w-1/4 flex items-center justify-center">
                            {moment(row.targetDeliverDatetime).format(
                              "YYYY-MM-DD HH:mm"
                            )}
                          </div>
                          <div className="w-1/4 flex items-center justify-center">
                            {row.title}
                          </div>
                          <div className="w-1/4 flex items-center justify-center">
                            {row.deliveryType}
                          </div>
                          <div className="w-1/4 flex items-center justify-end px-3">
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                navigate(
                                  Path.dmsManagementHistoryDetail(row._id)
                                );
                              }}
                            >
                              詳細
                            </Button>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <NoData />
                  )
                ) : null}
              </div>
            </div>
          </div>
          <div className="w-full h-8 flex items-center justify-end text-white text-base font-semibold absolute inset-x-0 bottom-0">
            <Link
              to={Path.dmsManagement}
              className="button-size bg-gray-btn-secondary"
            >
              戻る
            </Link>
          </div>
        </div>

        <PopoverCalendar
          showCalendarEl={showCalendarEl}
          setShowCalendarEl={setShowCalendarEl}
          date={selectedDate}
          selectDate={setSelectedDate}
        />
        <PopoverDeliveryStatus
          showDeliveryStatus={showDeliveryStatus}
          setShowDeliveryStatus={setShowDeliveryStatus}
          deliveryStatus={deliveryStatus}
          selectDeliveryStatus={selectDeliveryStatus}
        />
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default DmsManagementHistoryPage;
