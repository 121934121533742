import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedPrinter: null,
  allPrinters: [],
};

const slice = createSlice({
  name: "printers",
  initialState,
  reducers: {
    setSelectedPrinter: (state, action) => {
      state.selectedPrinter = action.payload;
    },
    addPrinter: (state, action) => {
      if (state.allPrinters.includes(action.payload)) return;
      else state.allPrinters = [action.payload, ...state.allPrinters];
    },
    removePrinter: (state, action) => {
      state.allPrinters = state.allPrinters.filter(
        (printer) => printer !== action.payload
      );
    },
  },
});

export const { setSelectedPrinter, addPrinter, removePrinter } = slice.actions;

export default slice.reducer;
