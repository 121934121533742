import client from "../client";
import {
  defaultDateFormat,
  defaultDateTimeFormat,
  defaultTimeFormat,
} from "app/constants";
import moment from "moment";
import "moment-timezone";

const reservationAdapter = (rawData) => ({
  id: rawData._id,
  visitDate: moment(rawData.visitDate)
    .tz("Asia/Tokyo")
    .format(defaultDateFormat),
  startTime: moment(rawData.startTime)
    .tz("Asia/Tokyo")
    .format(defaultTimeFormat),
  finishTime: moment(rawData.finishTime)
    .tz("Asia/Tokyo")
    .format(defaultTimeFormat),

  customer: rawData.customer
    ? {
        ...rawData.customer,
        id: rawData.customer._id,
      }
    : {},

  staff: rawData.staff
    ? {
        ...rawData.staff,
        id: rawData.staff._id,
      }
    : {},

  reservationMenus: Array.isArray(rawData.reservationMenu)
    ? rawData.reservationMenu.map((visitmenu) => ({
        id: visitmenu._id,
        menuType: visitmenu.menuType,
        name: visitmenu.name,
        durationString: visitmenu.durationString,
        durationNumber: visitmenu.durationNumber,
        priceTaxExc: visitmenu.priceTaxExc,
        priceTaxInc: visitmenu.priceTaxInc,
        salonMenuId: visitmenu.salonMenuId,
      }))
    : [],
});

const salonMenuAdapter = (rawData) => ({
  ...rawData,
  id: rawData._id,
});

const customerAdapter = (rawData) => ({
  id: rawData._id,
  lastNameKata: rawData.lastNameKata,
  firstNameKata: rawData.firstNameKata,
  lastNameKanji: rawData.lastNameKanji,
  firstNameKanji: rawData.firstNameKanji,
  dob: moment(rawData.dob).tz("Asia/Tokyo").format(defaultDateFormat),
  phone1: rawData.phone1,
  phone2: rawData.phone2,
  email: rawData.email,
  postalCode: rawData.postalCode,
  address: rawData.address,
});

const shiftPlanAdapter = (rawData) => ({
  id: rawData._id,
  workDate: moment(rawData.workDate).tz("Asia/Tokyo").format(defaultDateFormat),
  startTime: moment(rawData.startTime)
    .tz("Asia/Tokyo")
    .format(defaultTimeFormat),
  finishTime: moment(rawData.finishTime)
    .tz("Asia/Tokyo")
    .format(defaultTimeFormat),
});

const availableStaffShiftPlanAdapter = (rawData) => ({
  id: rawData._id,
  lastNameKata: rawData.lastNameKata,
  firstNameKata: rawData.firstNameKata,
  lastNameKanji: rawData.lastNameKanji,
  firstNameKanji: rawData.firstNameKanji,
  dob: moment(rawData.dob).tz("Asia/Tokyo").format(defaultDateFormat),
  phone: rawData.phone,
  email: rawData.email,
  postalCode: rawData.postalCode,
  address: rawData.address,
  imageUrl: rawData.imageUrl,
  availableShiftPlan:
    Array.isArray(rawData.shiftplan) && rawData.shiftplan.length > 0
      ? shiftPlanAdapter(rawData.shiftplan[0])
      : null,
});

const convertReservationToTokyoTimeZone = (reservation) => ({
  ...reservation,
  visitDate: moment
    .tz(reservation.visitDate, defaultDateFormat, true, "Asia/Tokyo")
    .toISOString(),
  startTime: moment
    .tz(
      `${reservation.visitDate} ${reservation.startTime}`,
      defaultDateTimeFormat,
      true,
      "Asia/Tokyo"
    )
    .toISOString(),
  finishTime: moment
    .tz(
      `${reservation.visitDate} ${reservation.finishTime}`,
      defaultDateTimeFormat,
      true,
      "Asia/Tokyo"
    )
    .toISOString(),
});

export const getAllReservationsToday = () => {
  const today = moment().tz("Asia/Tokyo").format(defaultDateFormat);

  return client
    .get("/api/salon/reservation/all", {
      params: { visitDate: today },
    })
    .then((res) => {
      if (res.data.success) {
        return {
          data: res.data.result.map((rawData) => reservationAdapter(rawData)),
        };
      }

      return { data: [] };
    });
};

export const getReservationDetail = (id) => {
  return client
    .get("/salon/reservation/get", {
      params: { id: id },
    })
    .then((res) => {
      if (res.data.success) {
        return {
          data: reservationAdapter(res.data.result),
        };
      }
      return { data: null };
    });
};

export const updateReservationDetail = (id, updateReservationData) => {
  console.log("updateReservationData", updateReservationData);

  return client
    .put(
      `/salon/reservation/update?id=${id}`,
      convertReservationToTokyoTimeZone(updateReservationData)
    )
    .then((res) => {
      if (res.data.success) {
        return {
          data: reservationAdapter(res.data.result),
        };
      }
      return { data: null };
    });
};

export const deleteReservation = (id) => {
  return client.delete("/salon/reservation/delete", { params: { id: id } });
};

export const searchCustomerInfo = (keyword) => {
  if (keyword)
    return client
      .get("/customer/customer/all", {
        params: { keyword: keyword },
      })
      .then((res) => {
        if (res.data.success) {
          const rawDataList = res.data.result;
          return {
            data: Array.isArray(rawDataList)
              ? rawDataList.map((rawData) => customerAdapter(rawData))
              : [],
          };
        }
        return { data: [] };
      });
  return client.get("/customer/customer/all").then((res) => {
    if (res.data.success) {
      const rawDataList = res.data.result;
      return {
        data: Array.isArray(rawDataList)
          ? rawDataList.map((rawData) => customerAdapter(rawData))
          : [],
      };
    }
    return { data: [] };
  });
};

export const getAllSalonMenus = (menuType) => {
  if (menuType)
    return client
      .get("/salon/salon-menu/all", {
        params: { menuType: menuType },
      })
      .then((res) => {
        if (res.data.success) {
          const rawDataList = res.data.result;
          return {
            data: Array.isArray(rawDataList)
              ? rawDataList.map((rawData) => salonMenuAdapter(rawData))
              : [],
          };
        }
        return { data: [] };
      });
  return client.get("/salon/salon-menu/all").then((res) => {
    if (res.data.success) {
      const rawDataList = res.data.result;
      return {
        data: Array.isArray(rawDataList)
          ? rawDataList.map((rawData) => salonMenuAdapter(rawData))
          : [],
      };
    }
    return { data: [] };
  });
};

export const getAllStaffAvailable = (date) => {
  return client
    .get("/salon/salon-staff/all-staff-with-shift-plan", {
      params: { date: date },
    })
    .then((res) => {
      if (res.data.success) {
        const rawDataList = res.data.result;
        return {
          data: Array.isArray(rawDataList)
            ? rawDataList.map((rawData) =>
                availableStaffShiftPlanAdapter(rawData)
              )
            : [],
        };
      }
      return { data: [] };
    });
};

export const createNewReservation = (customerId, reservation) => {
  console.log("reservation", reservation);

  return client
    .post(
      `/salon/salon-customer/create-reservation?customer-id=${customerId}`,
      convertReservationToTokyoTimeZone(reservation)
    )
    .then((res) => {
      if (res.data.success) {
        return {
          data: reservationAdapter(res.data.result),
        };
      }
      return { data: null };
    });
};

export const getAllReservationsByDate = (date) => {
  return client
    .get("/salon/reservation/all", {
      params: { visitDate: date },
    })
    .then((res) => {
      if (res.data.success) {
        return {
          data: res.data.result.map((rawData) => reservationAdapter(rawData)),
        };
      }
      return { data: [] };
    });
};

export const getStoreManagementStatus = async (date) => {
  const fetchAllReservationsByDatePromise = getAllReservationsByDate(date);
  const fetchAllStaffAvailablePromise = getAllStaffAvailable(date);

  const storeManagementStatusRes = await Promise.all([
    fetchAllReservationsByDatePromise,
    fetchAllStaffAvailablePromise,
  ]);

  const allReservations = storeManagementStatusRes[0].data;
  const allStaffAvailable = storeManagementStatusRes[1].data;

  if (!Array.isArray(allReservations) || !Array.isArray(allStaffAvailable))
    return [];
  const result = allStaffAvailable.map((item) => ({
    ...item,
    reservationList: [],
  }));
  for (let i = 0; i < result.length; i++) {
    const element = result[i];

    element.reservationList = allReservations.filter(
      (item) => item.staff?.id === element.id
    );
  }

  return { data: result };
};

export const sendCustomerContact = (customerId, contactData) => {
  const data = {
    sentDate: moment().toISOString(),
    title: contactData.title,
    content: contactData.content,
    customerId: customerId,
  };

  return client.post("/salon-dm-customer/create", data).then((res) => {
    if (res.data.success) {
      const rawData = res.data.result;
      return {
        data: rawData,
      };
    }
    return {
      data: null,
    };
  });
};

export const createNewReservationForNonCustomer = (nonCustomerInfo, data) => {
  return client
    .post("/customer/customer/create", {
      firstNameKata: nonCustomerInfo.name,
      phone1: nonCustomerInfo.phone,
    })
    .then((res) => {
      if (res.data.success) {
        const rawData = res.data.result;
        const customerId = rawData._id;

        return createNewReservation(customerId, data);
      }
      return {
        data: null,
      };
    });
};
