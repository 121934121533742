import React from "react";
import DefaultLayout from "../../../layouts/DefaultLayout";
import MainContentLayout from "../../../layouts/MainContentLayout";
import { useNavigate } from "react-router-dom";
import Path from "../../../route/Path";
import MainMenuItem from "../../../components/MainMenuItem";
import { useMediaQuery } from "@mui/material";
import { ResponsiveMenuItem } from "../../Home";
function ScalpLabManagementTopPage() {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:680px)");

  return (
    <DefaultLayout>
      <MainContentLayout
        pageTitle={
          <>
            <p>頭皮ラボ</p>
          </>
        }
        onClick={() => navigate(Path.home)}
      >
        <div className="w-full h-full pt-16 pb-8 lg:py-12 relative">
          <div className="w-full h-16 lg:h-12 pl-20 lg:pl-0 flex items-center justify-start absolute inset-x-0 top-0 bg-white">
            <h2 className="text-base lg:text-xl font-semibold">
              頭皮ラボについて
            </h2>
          </div>
          {isMobile ? (
            <div className="w-full h-full py-3 px-4">
              <div className="w-full h-full">
                <div className="w-full h-full flex flex-col gap-4 overflow-auto text-lg text-white ">
                  <div className="flex flex-col gap-2 justify-center items-center">
                    <div className="w-full flex justify-end">
                      <button
                        disabled
                        className="w-full h-9 flex items-center justify-center px-4 py-1 rounded-full bg-gray-btn-secondary font-semibold"
                      >
                        <span>頭皮ラボ</span>
                      </button>
                    </div>
                    <div className="w-full">
                      <ResponsiveMenuItem
                        isMobile={isMobile}
                        itemTitle={
                          <>
                            <p>提案する</p>
                          </>
                        }
                        onClick={() =>
                          navigate(Path.scalpLabManagementIntroduction)
                        }
                        size="100%"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col gap-2 justify-center items-center">
                    <div className="w-full flex justify-end">
                      <button
                        disabled
                        className="w-full h-9 flex items-center justify-center px-4 rounded-full bg-gray-btn-secondary font-semibold"
                      >
                        <span>頭皮データ</span>
                      </button>
                    </div>
                    <div className="w-full flex flex-col gap-2">
                      <ResponsiveMenuItem
                        isMobile={isMobile}
                        itemTitle={<p>発送する</p>}
                        onClick={() => navigate(Path.orderScalpLab)}
                        size="100%"
                      />
                      <ResponsiveMenuItem
                        isMobile={isMobile}
                        itemTitle={<p>発送履歴</p>}
                        onClick={() => navigate(Path.historyOfOrdering)}
                        size="100%"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col gap-2 justify-center items-center">
                    <div className="w-full flex justify-end">
                      <button
                        disabled
                        className="w-full h-9 flex items-center justify-center px-4 rounded-full bg-gray-btn-secondary font-semibold"
                      >
                        <span>検査キット</span>
                      </button>
                    </div>

                    <div className="w-full">
                      <ResponsiveMenuItem
                        isMobile={isMobile}
                        itemTitle={<p>在庫数確認</p>}
                        onClick={() => navigate(Path.orderKits)}
                        size="100%"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col gap-2 justify-center items-center">
                    <div className="w-full flex justify-end">
                      <button
                        disabled
                        className="w-full h-9 flex items-center justify-center px-4 rounded-full bg-gray-btn-secondary font-semibold"
                      >
                        <span>頭皮ラボ運営</span>
                      </button>
                    </div>
                    <div className="w-full">
                      <ResponsiveMenuItem
                        isMobile={isMobile}
                        itemTitle={<p>連絡する</p>}
                        onClick={() => navigate(Path.contactOprator)}
                        size="100%"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex flex-col justify-center gap-9 w-full max-h-full pr-3 text-lg text-white font-semibold rounded-2xl p-4">
              <div className="flex gap-6 justify-center items-center">
                <div className="w-1/2 flex justify-end">
                  <button
                    disabled
                    className="w-[150px] h-9 flex items-center justify-center px-4 py-1 rounded-full bg-gray-btn-secondary"
                  >
                    <span>頭皮ラボ</span>
                  </button>
                </div>
                <div className="w-1/2">
                  <MainMenuItem
                    itemTitle={
                      <div>
                        <p className="text-xs font-medium">提案</p>
                        <p className="text-xs font-medium">する</p>
                      </div>
                    }
                    onClick={() =>
                      navigate(Path.scalpLabManagementIntroduction)
                    }
                    borderSize={4}
                    size={60}
                  />
                </div>
              </div>
              <div className="flex gap-6 justify-center items-center">
                <div className="w-1/2 flex justify-end">
                  <button
                    disabled
                    className="w-[150px] h-9 flex items-center justify-center px-4 rounded-full bg-gray-btn-secondary"
                  >
                    <span>頭皮データ</span>
                  </button>
                </div>
                <div className="w-1/2 flex gap-6">
                  <MainMenuItem
                    itemTitle={
                      <div>
                        <p className="text-xs font-medium">発送</p>
                        <p className="text-xs font-medium">する</p>
                      </div>
                    }
                    onClick={() => navigate(Path.orderScalpLab)}
                    borderSize={4}
                    size={60}
                  />
                  <MainMenuItem
                    itemTitle={
                      <div>
                        <p className="text-xs font-medium">発送</p>
                        <p className="text-xs font-medium">履歴</p>
                      </div>
                    }
                    onClick={() => navigate(Path.historyOfOrdering)}
                    borderSize={4}
                    size={60}
                  />
                </div>
              </div>
              <div className="flex gap-6 justify-center items-center">
                <div className="w-1/2 flex justify-end">
                  <button
                    disabled
                    className="w-[150px] h-9 flex items-center justify-center px-4 rounded-full bg-gray-btn-secondary"
                  >
                    <span>検査キット</span>
                  </button>
                </div>

                <div className="w-1/2">
                  <MainMenuItem
                    itemTitle={
                      <div>
                        <p className="text-xs font-medium">在庫数</p>
                        <p className="text-xs font-medium">確認</p>
                      </div>
                    }
                    onClick={() => navigate(Path.orderKits)}
                    borderSize={4}
                    size={60}
                  />
                </div>
              </div>
              <div className="flex gap-6 justify-center items-center">
                <div className="w-1/2 flex justify-end">
                  <button
                    disabled
                    className="w-[150px] h-9 flex items-center justify-center px-4 rounded-full bg-gray-btn-secondary"
                  >
                    <span>頭皮ラボ運営</span>
                  </button>
                </div>
                <div className="w-1/2">
                  <MainMenuItem
                    itemTitle={
                      <div>
                        <p className="text-xs font-medium">連絡</p>
                        <p className="text-xs font-medium">する</p>
                      </div>
                    }
                    onClick={() => navigate(Path.contactOprator)}
                    borderSize={4}
                    size={60}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default ScalpLabManagementTopPage;
