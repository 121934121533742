import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  token: {
    accessToken: null,
    refreshToken: null
  },
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthUser: (state, action) => {
      state.user = action.payload;
    },
    setAuthToken: (state, action) => {
      state.token = action.payload
    }
  },
});

export const { setAuthUser, setAuthToken } = slice.actions;

export default slice.reducer;
