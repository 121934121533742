import moment from "moment";
import { defaultDateFormat } from "app/constants";
import client from "../client";

export const getAllVisitChargedPayment = (date) => {
  return client
    .get("/salon/visit/find-all-visit-charged-payment", {
      params: { date: date },
    })
    .then((res) => {
      if (res.data.success) {
        return {
          data: res.data.result,
        };
      }
      return { data: [] };
    });
};

export const getAllNonVisitChargedPayment = (paymentDate) => {
  return client
    .get("/non-visit-payment/all", {
      params: { paymentDate: paymentDate },
    })
    .then((res) => {
      if (res.data.success) {
        return {
          data: res.data.result,
        };
      }
      return { data: [] };
    });
};

export const getDetailVisitChargedPayment = (id) => {
  return client
    .get("/salon/visit/find-visit-charged-payment", {
      params: { id: id },
    })
    .then((res) => {
      if (res.data.success) {
        return {
          data: res.data.result,
        };
      }
      return { data: {} };
    });
};

export const getExportAllVisitChargedPayment = (date) => {
  return client
    .get("/salon/visit/export-all-visit-charged-payment", {
      params: { date: date },
    })
    .then((res) => {
      if (res.data.success) {
        return {
          data: res.data.result,
        };
      }
      return { data: [] };
    });
};

export const getTotalRevenue = (date) => {
  return client
    .get("/salon/payment/total-revenue-by-date", {
      params: { date: date },
    })
    .then((res) => {
      if (res.data.success) {
        return {
          data: res.data.result,
        };
      }
      return { data: {} };
    });
};

const paymentAdapter = (rawData) => ({
  paymentType: rawData.paymentType,
  paymentAmount: rawData.paymentAmount,
});

const getAllVistPayment = (start, end) => {
  return client
    .get("salon/salon-staff/visit-payment", {
      params: { startDate: start, endDate: end },
    })
    .then((res) => {
      if (res.data.success) {
        return {
          data: res.data.result.map((rawData) => paymentAdapter(rawData)),
        };
      }
      return { data: [] };
    });
};

const getAllNonCustomerPayment = (start, end) => {
  return client
    .get("/salon/non-visit-payment", {
      params: { startDate: start, endDate: end },
    })
    .then((res) => {
      if (res.data.success) {
        return {
          data: res.data.result.map((rawData) => paymentAdapter(rawData)),
        };
      }
      return { data: [] };
    });
};

export const getAddPaymentToday = () => {
  const todayJp = moment().tz("Asia/Tokyo");

  return Promise.all([
    getAllVistPayment(
      todayJp.format(defaultDateFormat),
      todayJp.clone().add(1, "d").format(defaultDateFormat)
    ),
    getAllNonCustomerPayment(
      todayJp.format(defaultDateFormat),
      todayJp.clone().add(1, "d").format(defaultDateFormat)
    ),
  ]).then((res) => {
    const allPaymentToday = [...res[0].data, ...res[1].data];

    return { data: allPaymentToday };
  });
};

export const getDetailNonVisitChargedPayment = (id) => {
  return client
    .get("/non-visit-payment/get", {
      params: { id: id },
    })
    .then((res) => {
      if (res.data.success) {
        return {
          data: res.data.result,
        };
      }
      return { data: {} };
    });
};
