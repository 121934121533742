import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import DefaultLayout from "../../../layouts/DefaultLayout";
import MainContentLayout from "../../../layouts/MainContentLayout";
import { Link } from "react-router-dom";
import Path from "../../../route/Path";
import InputField from "../../../components/commonUI/InputField";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import CommonDialog from "../../../components/CommonDialog";
import { getAllKit } from "../../../services/api/fake_services";
import LoadingProgress from "../../../components/commonUI/LoadingProgress";
import LoadInitError from "../../../components/commonUI/LoadInitError";
import { getSalonDetail } from "../../../services/api/18";
import { createNewOrderKit } from "../../../services/api/1C";
import NoData from "../../../components/commonUI/NoData";
import { toast } from "react-toastify";
const confirmSteps = {
  CONFIRM: "CONFIRM",
  CONFIRM_AGAIN: "CONFIRM_AGAIN",
};
function OrderKits() {
  const [orderAmount, setOrderAmount] = useState("");
  const [open, setOpen] = useState(false);
  const [showStep, setShowStep] = useState(confirmSteps.CONFIRM);
  const [fetchAllKitOfScalpLab, setFetchAllKitOfScalpLab] = useState({
    isLoading: false,
    data: null,
    error: null,
  });

  const loadAllKitOfScalpLab = () => {
    setFetchAllKitOfScalpLab({
      isLoading: true,
      data: null,
      error: null,
    });

    getSalonDetail()
      .then((res) => {
        setFetchAllKitOfScalpLab({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        setFetchAllKitOfScalpLab({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  };

  useEffect(() => {
    loadAllKitOfScalpLab();
  }, []);

  const handleConfirm = () => {
    if (showStep === confirmSteps.CONFIRM) {
      setShowStep(confirmSteps.CONFIRM_AGAIN);
      handleSubmit();
    } else if (showStep === confirmSteps.CONFIRM_AGAIN) {
      setOpen(false);
      setOrderAmount("");
    }
  };
  const validationSchema = Yup.object().shape({
    orderAmount: Yup.string().required("注文金額"),
  });

  const onSubmit = async (values) => {
    createNewOrderKit(values);
  };
  const {
    handleSubmit,
    errors,
    values,
    setFieldValue,
    // resetForm,
    touched,
    handleBlur,
  } = useFormik({
    initialValues: {
      orderAmount: orderAmount || "",
    },
    onSubmit,
    validationSchema,
  });
  return (
    <DefaultLayout>
      <MainContentLayout
        pageTitle={
          <>
            <p>頭皮ラボ</p>
          </>
        }
      >
        <div className="w-full h-full pt-16 pb-8 lg:py-12 relative">
          <div className="w-full h-16 lg:h-12 pl-20 lg:pl-0 flex items-center justify-start absolute inset-x-0 top-0 bg-white">
            <h2 className="text-base lg:text-xl font-semibold">検査キット</h2>
          </div>

          <div className="w-full h-full py-3">
            <div className="w-full h-full pr-3 border border-gray-400 rounded-2xl p-4">
              <div className="w-full h-full overflow-auto">
                <div className="w-full h-full flex gap-6">
                  <div className="w-1/2 flex justify-center items-center">
                    <div className="w-full lg:w-1/2 h-1/2  border border-gray-800 border-4 rounded-lg relative">
                      <div className="absolute bg-gray-btn-secondary text-base font-semibold text-white rounded-full px-8 py-2 top-[-8%]  left-[-2%] lg:left-[-12%]">
                        現在の在庫数
                      </div>
                      <div className=" h-full justify-center items-center">
                        {fetchAllKitOfScalpLab.isLoading ? (
                          <LoadingProgress />
                        ) : null}

                        {!fetchAllKitOfScalpLab.isLoading &&
                        fetchAllKitOfScalpLab.error ? (
                          <LoadInitError error={fetchAllKitOfScalpLab.error} />
                        ) : null}
                        {!fetchAllKitOfScalpLab.isLoading &&
                        !fetchAllKitOfScalpLab.error ? (
                          <div className="flex h-full justify-center items-center  font-bold text-5xl">
                            {
                              fetchAllKitOfScalpLab.data?.inspectionKit
                                ?.inStockAmount
                            }
                          </div>
                        ) : (
                          <NoData />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="w-1/2 flex flex-col justify-center items-center">
                    <form>
                      <div className="flex items-center gap-2">
                        <InputField
                          name="orderAmount"
                          value={values.orderAmount}
                          type="text"
                          onChange={(newValue) => {
                            setFieldValue(
                              "orderAmount",
                              Number(newValue.replace(/[^0-9.]/g, ""))
                            );
                            setOrderAmount(newValue);
                          }}
                          handleBlur={handleBlur}
                          touched={touched.orderAmount}
                          errors={errors.orderAmount}
                          labelClasses="!w-24"
                        />
                        <div className="font-semibold text-base">個</div>
                      </div>
                      <div className="flex justify-end mt-4">
                        <button
                          onClick={() => {
                            if (
                              Number(orderAmount) >
                              Number(
                                fetchAllKitOfScalpLab.data?.inspectionKit
                                  ?.inStockAmount
                              )
                            ) {
                              toast.error(
                                "在庫数よりも小さい数字を入力してください"
                              );
                            } else {
                              setOpen(true);
                            }
                          }}
                          type="button"
                          className="button-size bg-blue-btn-primary"
                          disabled={orderAmount === "" ? true : false}
                        >
                          発注する
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full h-8 flex items-center justify-end text-white text-base font-semibold absolute inset-x-0 bottom-0">
            <Link
              to={Path.scalpLabManagement}
              className="button-size bg-gray-btn-secondary"
            >
              戻る
            </Link>
          </div>
        </div>
        <CommonDialog
          open={open}
          handleClose={() => {
            if (!confirmSteps.CONFIRM_AGAIN) {
              setOpen(false);
            }
            setOpen(false);
          }}
          title={
            showStep === confirmSteps.CONFIRM
              ? "検査キットを発注しますか？"
              : ""
          }
          actions={
            <div className="w-full flex gap-6 items-center justify-end text-white text-base font-semibold">
              <button
                onClick={handleConfirm}
                type="button"
                className="button-size bg-blue-btn-primary"
              >
                {showStep === confirmSteps.CONFIRM ? "発注" : "終る"}
              </button>
            </div>
          }
        >
          <div className="flex justify-center text-lg font-semibold">
            {showStep === confirmSteps.CONFIRM
              ? `${orderAmount} 個`
              : "検査キットを発注ました"}
          </div>
        </CommonDialog>
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default OrderKits;
