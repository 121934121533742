import React, { useEffect, useState } from "react";
import CommonDialog from "../../../components/CommonDialog";
import { payment_type_and_method } from "../../../constants";
import MainMenuItem from "../../../components/MainMenuItem";

function PaymentMethodDialogContent(props) {
  const { paymentType, paymentMethod, setPaymentMethod } = props;

  return (
    <div className="w-full h-auto">
      <div className="w-full h-auto flex items-center mb-5 flex-wrap">
        <div className="w-44 mb-2 pr-4">
          <div className="w-full h-auto bg-stone-500 text-white font-medium p-3 rounded-full flex justify-center">
            {payment_type_and_method.credit_card.payment_type_label}
          </div>
        </div>
        <div className="w-auto flex gap-4">
          {payment_type_and_method.credit_card.payment_methods.map(
            (payment_method) => (
              <MainMenuItem
                itemTitle={
                  <p className="text-xs font-medium">
                    {payment_method.payment_method_label}
                  </p>
                }
                onClick={() => {
                  setPaymentMethod({
                    paymentType:
                      payment_type_and_method.credit_card.payment_type,
                    paymentMethod: payment_method.payment_method,
                  });
                }}
                borderSize={4}
                size={50}
                isActive={
                  paymentType ===
                    payment_type_and_method.credit_card.payment_type &&
                  paymentMethod === payment_method.payment_method
                }
              />
            )
          )}
        </div>
      </div>

      <div className="w-full h-auto flex items-center mb-5 flex-wrap">
        <div className="w-44 mb-2 pr-4">
          <div className="w-full h-auto bg-stone-500 text-white font-medium p-3 rounded-full flex justify-center">
            {payment_type_and_method.qr_code.payment_type_label}
          </div>
        </div>
        <div className="w-auto flex gap-4">
          {payment_type_and_method.qr_code.payment_methods.map(
            (payment_method) => (
              <MainMenuItem
                itemTitle={
                  <p className="text-xs font-medium">
                    {payment_method.payment_method_label}
                  </p>
                }
                onClick={() => {
                  setPaymentMethod({
                    paymentType: payment_type_and_method.qr_code.payment_type,
                    paymentMethod: payment_method.payment_method,
                  });
                }}
                borderSize={4}
                size={50}
                isActive={
                  paymentType ===
                    payment_type_and_method.qr_code.payment_type &&
                  paymentMethod === payment_method.payment_method
                }
              />
            )
          )}
        </div>
      </div>

      <div className="w-full h-auto flex items-center mb-5 flex-wrap">
        <div className="w-44 mb-2 pr-4">
          <div className="w-full h-auto bg-stone-500 text-white font-medium p-3 rounded-full flex justify-center">
            {payment_type_and_method.eletronic_payment.payment_type_label}
          </div>
        </div>
        <div className="w-auto flex gap-4">
          {payment_type_and_method.eletronic_payment.payment_methods.map(
            (payment_method) => (
              <MainMenuItem
                itemTitle={
                  <p className="text-xs font-medium">
                    {payment_method.payment_method_label}
                  </p>
                }
                onClick={() => {
                  setPaymentMethod({
                    paymentType:
                      payment_type_and_method.eletronic_payment.payment_type,
                    paymentMethod: payment_method.payment_method,
                  });
                }}
                borderSize={4}
                size={50}
                isActive={
                  paymentType ===
                    payment_type_and_method.eletronic_payment.payment_type &&
                  paymentMethod === payment_method.payment_method
                }
              />
            )
          )}
        </div>
      </div>
    </div>
  );
}

function PaymentMethodDialog(props) {
  const { open, handleClose, paymentMethodProps, setPaymentMethodProps } =
    props;

  const [paymentMethod, setPaymentMethod] = useState({
    paymentType: "",
    paymentMethod: "",
  });

  useEffect(() => {
    if (open) {
      setPaymentMethod(
        paymentMethodProps || { paymentType: "", paymentMethod: "" }
      );
    }
  }, [open]);

  const resetDialog = () => {
    setPaymentMethod({
      paymentType: "",
      paymentMethod: "",
    });
  };

  const handleConfirmNewPaymentMethod = () => {
    setPaymentMethodProps(paymentMethod);
    handleClose();
    resetDialog();
  };

  return (
    <CommonDialog
      open={open}
      handleClose={() => {
        resetDialog();
        handleClose();
      }}
      title={"お支払い方法を選択してください"}
      actions={
        <div className="w-full flex items-center justify-end text-white text-base font-semibold">
          <button
            onClick={() => {
              resetDialog();
              handleClose();
            }}
            type="button"
            className="button-size bg-gray-btn-secondary mr-4 lg:mr-6"
          >
            戻る
          </button>
          <button
            onClick={handleConfirmNewPaymentMethod}
            type="button"
            className="button-size bg-blue-btn-primary"
          >
            進む
          </button>
        </div>
      }
    >
      <div className="w-full h-auto py-4">
        {
          <PaymentMethodDialogContent
            paymentType={paymentMethod.paymentType}
            paymentMethod={paymentMethod.paymentMethod}
            setPaymentMethod={setPaymentMethod}
          />
        }
      </div>
    </CommonDialog>
  );
}

export default PaymentMethodDialog;
