import React from "react";
import { useNavigate } from "react-router-dom";
import Path from "app/route/Path";
import AnalyzeBox from "./AnalyzeBox";
import CustomBarChart from "../../components/CustomBarChart";

function AverageSpendPerCustomerAnalyze(props) {
  const navigate = useNavigate();

  return (
    <AnalyzeBox
      title="客単価"
      onClickTitle={() =>
        navigate(Path.averageSpendPerCustomerAnalyzeManagement)
      }
    >
      <CustomBarChart />
    </AnalyzeBox>
  );
}

export default AverageSpendPerCustomerAnalyze;
