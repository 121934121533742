import React, { useMemo, useState } from "react";
import moment from "moment";
import { Avatar } from "@mui/material";
import SouthIcon from "@mui/icons-material/South";
import { jpDateFormat } from "app/constants";
import { formatPrice, getNameFromFirstLast } from "app/utils";
import defaultAvatar from "app/assets/svg/default_avatar.svg";

function ReservationInfo(props) {
  const { nextToSchedule, menuList, visitDate, startTime, staff } = props;

  const reservationTime = useMemo(() => {
    const visitDateJpStr = moment(visitDate).format(jpDateFormat);
    return `${visitDateJpStr} ${startTime}`;
  }, [visitDate, startTime]);

  const durationStr = useMemo(() => {
    const durationNumber =
      menuList.reduce((a, b) => a + b.durationNumber, 0) / 60;
    if (durationNumber < 1) return `${Math.round(durationNumber * 60)}分`;

    return `${Math.floor(durationNumber)}時間${Math.round(
      (durationNumber % 1) * 60
    )}分`;
  }, [menuList]);

  const totalMenuList = useMemo(() => {
    return menuList.reduce((a, b) => a + b.priceTaxInc, 0);
  }, [menuList]);

  return (
    <div className="w-full h-full pt-16 lg:pt-0 pb-8 lg:pb-12 relative">
      <div className="w-full h-full py-3">
        <div className="w-full h-full overflow-auto">
          <div className="w-full h-auto min-h-full flex flex-col justify-center">
            <div className="w-full h-full max-w-[960px] mx-auto relative max-h-full flex flex-wrap">
              <div className="w-full h-full overflow-auto flex flex-col justify-center">
                <div className="w-full h-auto rounded-lg border border-gray-400 max-w-[680px] mx-auto">
                  <div className="w-ffull h-auto border-b border-gray-400">
                    <div className="w-full h-8 border-b border-gray-400 px-6 flex items-center text-lg font-semibold bg-gray-200 rounded-t-lg">
                      日時
                    </div>
                    <div className="w-full h-auto px-3 lg:px-6 py-4">
                      {reservationTime}
                    </div>
                  </div>

                  <div className="w-ffull h-auto border-b border-gray-400">
                    <div className="w-full h-8 border-b border-gray-400 px-6 flex items-center text-lg font-semibold bg-gray-200">
                      施術時間合計（日時）
                    </div>
                    <div className="w-full h-auto px-3 lg:px-6 py-4">
                      {durationStr}
                    </div>
                  </div>

                  <div className="w-ffull h-auto border-b border-gray-400">
                    <div className="w-full h-8 border-b border-gray-400 px-6 flex items-center text-lg font-semibold bg-gray-200">
                      担当
                    </div>

                    <div className="w-full h-auto px-3 lg:px-6 py-4">
                      {staff ? (
                        <div className="w-full h-auto flex items-center justify-center">
                          <div className="w-12 h-12 rounded-full overflow-hidden">
                            <Avatar
                              alt=""
                              src={staff.imageUrl || defaultAvatar}
                              sx={{
                                width: 48,
                                height: 48,
                                border: "1px solid black",
                              }}
                            />
                          </div>
                          <p className="text-base font-semibold ml-4">
                            {getNameFromFirstLast(staff)}
                          </p>
                        </div>
                      ) : (
                        <div className="w-full h-auto min-h-[40px] flex items-center justify-center">
                          指名なし
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="w-full h-auto flex border-b border-gray-400 min-h-[180px]">
                    <div className="w-1/2 h-auto border-r border-gray-400">
                      <div className="w-ffull h-auto">
                        <div className="w-full h-8 border-b border-gray-400 px-6 flex items-center text-lg font-semibold bg-gray-200">
                          メニュー
                        </div>
                        <div className="w-full h-auto px-3 lg:px-6 py-4">
                          <div className="w-full h-auto min-h-[80px] flex items-stretch flex-col">
                            <h3 className="font-semibold mb-4">
                              ご利用クーポン
                            </h3>

                            <div className="w-full h-auto flex items-start justify-between gap-3">
                              <span>
                                {menuList
                                  .map((menuItem) => menuItem.name)
                                  .join(" + ")}
                              </span>
                              <span className="shrink-0 whitespace-nowrap font-semibold">
                                {formatPrice(totalMenuList)}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-1/2 h-auto">
                      <div className="w-ffull h-auto">
                        <div className="w-full h-8 border-b border-gray-400 px-6 flex items-stretch text-lg font-semibold bg-gray-200">
                          予約時の金額
                        </div>
                        <div className="w-full h-auto px-3 lg:px-6 py-4">
                          <div className="w-full h-auto min-h-[80px] flex items-center flex-col">
                            <div className="w-full h-auto flex items-center justify-between mb-2">
                              <span>合計金額</span>
                              <span>{formatPrice(totalMenuList)}</span>
                            </div>

                            <div className="w-full h-auto flex items-center justify-between">
                              <span>ご利用ポイント</span>
                              <span>0</span>
                            </div>

                            <div className="w-full h-[1px] bg-slate-500 my-3" />

                            <div className="w-full h-auto flex items-center justify-between">
                              <span>お支払い予約金額</span>
                              <span>{formatPrice(totalMenuList)}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="w-ffull h-auto">
                    <div className="w-full h-8 border-b border-gray-400 px-6 flex items-center text-lg font-semibold bg-gray-200">
                      要望・その他
                    </div>
                    <div className="w-full h-auto px-3 lg:px-6 py-4"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full h-8 lg:h-12 flex items-end justify-end bg-white absolute inset-x-0 bottom-0">
        <button
          onClick={nextToSchedule}
          type="button"
          className="button-size bg-blue-btn-primary"
        >
          次へ
        </button>
      </div>
    </div>
  );
}

function ServiceUsageSchedule(props) {
  const { backToEditMenuList, handleClickConfirm, menuList } = props;

  const durationStr = useMemo(() => {
    const durationNumber =
      menuList.reduce((a, b) => a + b.durationNumber, 0) / 60;
    if (durationNumber < 1) return `${Math.round(durationNumber * 60)}分`;

    return `${Math.floor(durationNumber)}時間${Math.round(
      (durationNumber % 1) * 60
    )}分`;
  }, [menuList]);

  const totalMenuList = useMemo(() => {
    return menuList.reduce((a, b) => a + b.priceTaxInc, 0);
  }, [menuList]);

  return (
    <div className="w-full h-full pt-16 lg:pt-0">
      <div className="w-full h-full py-3">
        <div className="w-full h-full overflow-auto flex flex-col justify-center">
          <div className="w-full h-auto max-w-[960px] mx-auto relative max-h-full flex flex-wrap lg:flex-nowrap">
            <div className="w-full lg:w-3/5 flex flex-col max-h-full lg:pr-6 relative">
              <div className="w-full h-full lg:min-h-[360px] flex flex-col">
                {menuList.map((menuItem, index) => (
                  <React.Fragment key={index}>
                    <div className="flex items-center gap-4">
                      <div className="w-44 h-12 flex items-center justify-center rounded-full bg-[#736966] hover:bg-[#5e5553] overflow-hidden text-base text-white shrink-0">
                        {menuItem.name}
                      </div>

                      <div className="text-[#736966] font-normal text-sm">
                        <p>カット時間：{menuItem.durationString}</p>
                        <p>カット料金：{formatPrice(menuItem.priceTaxInc)}</p>
                      </div>
                    </div>

                    {index === menuList.length - 1 ? null : (
                      <div className="w-full h-8 flex">
                        <div className="w-44 h-full flex items-center justify-center">
                          <SouthIcon className="text-lg text-[#736966]" />
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                ))}
              </div>
            </div>
            <div className="hidden lg:flex  w-1 h-full flex-col items-center justify-center inset-y-0 right-0">
              <div className="w-2 h-2 overflow-hidden rounded-full bg-[#736966] shrink-0" />
              <div className="w-[2px] h-full grow bg-[#736966]" />
              <div className="w-2 h-2 overflow-hidden rounded-full bg-[#736966] shrink-0" />
            </div>

            <div className="flex lg:hidden w-full h-1 items-center justify-center my-8">
              <div className="w-2 h-2 overflow-hidden rounded-full bg-[#736966] shrink-0" />
              <div className="h-[2px] w-full grow bg-[#736966]" />
              <div className="w-2 h-2 overflow-hidden rounded-full bg-[#736966] shrink-0" />
            </div>

            <div className="w-full lg:w-2/5 flex flex-col max-h-full pl-6">
              <div className="w-full max-h-full overflow-auto pb-12 relative h-full">
                <h3 className="text-lg font-semibold text-[#736966] mb-2">
                  ＜本日の施術内容＞
                </h3>

                <ul className="list-disc pl-4 mb-8 text-[#736966] font-normal text-sm">
                  {menuList.map((menuItem, index) => (
                    <li key={index}>{menuItem.name}</li>
                  ))}
                </ul>

                <p className="text-[#736966] font-normal text-sm">
                  想定施術時間：{durationStr}
                </p>
                <p className="text-[#736966] font-normal text-sm">
                  施術費用：{formatPrice(totalMenuList)}（税抜）
                </p>

                <div className="w-full h-8 flex items-center justify-end text-white text-base font-semibold absolute inset-x-0 bottom-0">
                  <button
                    onClick={backToEditMenuList}
                    type="button"
                    className="button-size rounded bg-gray-btn-secondary mr-4 lg:mr-6"
                  >
                    内容修正
                  </button>

                  <button
                    onClick={handleClickConfirm}
                    type="button"
                    className="button-size bg-blue-btn-primary"
                  >
                    施術開始
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Step3ConfirmAndCreate(props) {
  const {
    handleBackToStep2,
    handleClickConfirm,
    menuList,
    visitDate,
    startTime,
    staff,
  } = props;

  const [showMainReservationInfo, setShowMainReservationInfo] = useState(true);

  return showMainReservationInfo ? (
    <ReservationInfo
      menuList={menuList}
      visitDate={visitDate}
      startTime={startTime}
      staff={staff}
      nextToSchedule={() => setShowMainReservationInfo(false)}
    />
  ) : (
    <ServiceUsageSchedule
      menuList={menuList}
      visitDate={visitDate}
      startTime={startTime}
      staff={staff}
      backToEditMenuList={() => handleBackToStep2()}
      handleClickConfirm={handleClickConfirm}
    />
  );
}

export default Step3ConfirmAndCreate;
