import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import DefaultLayout from "app/layouts/DefaultLayout";
import MainContentLayout from "app/layouts/MainContentLayout";
import Path from "app/route/Path";
import CreateCompleteDialog from "../../../components/CommonDialog/CreateCompleteDialog";
import LoadingBackdrop from "../../../components/commonUI/LoadingBackdrop";
import CreateSalonForm from "../../../components/salonForm/CreateSalonForm";
import {
  createNewSalon,
  getSalon,
  updateSalon,
} from "../../../services/api/1D";
import LoadInitError from "../../../components/commonUI/LoadInitError";
import LoadingProgress from "../../../components/commonUI/LoadingProgress";

function RegisterSalonFormPage() {
  const salonId = useParams();
  const id = salonId.salonId;
  const [creatingSalonState, setCreatingSalonState] = useState({
    confirmedData: null,
    isCreating: false,
    error: null,
    res: null,
  });
  const [fetchSalonDetail, setFetchSalonDetail] = useState({
    isLoading: false,
    data: null,
    error: null,
  });
  const loadSalonDetail = () => {
    setFetchSalonDetail({
      isLoading: true,
      data: null,
      error: null,
    });
    getSalon(id)
      .then((res) => {
        setFetchSalonDetail({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        setFetchSalonDetail({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  };
  useEffect(() => {
    if (id) {
      loadSalonDetail();
    }
  }, []);
  const navigate = useNavigate();

  const handleCreateNewSalon = () => {
    setCreatingSalonState((oldState) => ({
      ...oldState,
      isCreating: true,
      error: null,
      res: null,
    }));
    if (id) {
      console.log("zô");
      updateSalon(id, creatingSalonState.confirmedData)
        .then((res) => {
          setCreatingSalonState((oldState) => ({
            ...oldState,
            isCreating: false,
            error: null,
            res: res,
          }));
        })
        .catch((error) => {
          console.log("error: ", error);

          setCreatingSalonState((oldState) => ({
            ...oldState,
            isCreating: false,
            error: null,
            res: error,
          }));
        });
    } else {
      createNewSalon(creatingSalonState.confirmedData)
        .then((res) => {
          setCreatingSalonState((oldState) => ({
            ...oldState,
            isCreating: false,
            error: null,
            res: res,
          }));
        })
        .catch((error) => {
          console.log("error: ", error);

          setCreatingSalonState((oldState) => ({
            ...oldState,
            isCreating: false,
            error: null,
            res: error,
          }));
        });
    }
  };

  const confirmSalonData = (confirmedData) => {
    setCreatingSalonState({
      confirmedData,
      isCreating: false,
      error: null,
      res: null,
    });
    console.log("confirmedData", confirmedData);
  };

  const handleBackClick = () => {
    if (!creatingSalonState.confirmedData) {
      return navigate(Path.systemManagement);
    }

    setCreatingSalonState({
      confirmedData: null,
      isCreating: false,
      error: null,
      res: null,
    });
  };

  return (
    <DefaultLayout>
      <MainContentLayout
        pageTitle={
          <>
            <p>システム</p>
          </>
        }
      >
        <div className="w-full h-full pt-16 pb-8 lg:py-12 relative">
          <div className="w-full h-16 lg:h-12 pl-20 lg:pl-0 flex items-center justify-start absolute inset-x-0 top-0 bg-white">
            <h2 className="text-base lg:text-xl font-semibold">店舗情報</h2>
          </div>
          <div className="w-full h-full py-3">
            <div className="w-full h-full pr-3 ">
              <div className="w-full h-full overflow-auto">
                {fetchSalonDetail.isLoading ? <LoadingProgress /> : null}

                {!fetchSalonDetail.isLoading && fetchSalonDetail.error ? (
                  <LoadInitError error={fetchSalonDetail.error} />
                ) : null}
                {!fetchSalonDetail.isLoading &&
                !fetchSalonDetail.error &&
                id !== "" &&
                !!fetchSalonDetail.data ? (
                  <CreateSalonForm
                    salonId={id}
                    fetchSalonDetail={fetchSalonDetail.data}
                    isReadMode={creatingSalonState.confirmedData}
                    handleCreate={
                      creatingSalonState.confirmedData
                        ? handleCreateNewSalon
                        : null
                    }
                    confirmSalonData={confirmSalonData}
                    handleBackClick={handleBackClick}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <CreateCompleteDialog
          open={creatingSalonState.res}
          createCompleteTitle={
            salonId ? "店舗情報を変更しました。" : "登録を完了しました。"
          }
          createCompleteBtnLabel1="システム設定へ戻る"
          createCompleteAction1={() => {
            navigate(Path.systemManagement);
          }}
          createCompleteBtnLabel2="終る"
          createCompleteAction2={() => {
            navigate(Path.systemManagement);
          }}
        />

        <LoadingBackdrop isOpen={creatingSalonState.isCreating} />
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default RegisterSalonFormPage;
