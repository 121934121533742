import client from "../client";

export const getDMsDetail = (id) => {
  return client
    .get("/salon/dms/get", {
      params: { id: id },
    })
    .then((res) => {
      if (res.data.success) {
        return {
          data: res.data.result,
        };
      }
      return { data: {} };
    });
};

export const createNewDMs = (newDMsData) => {
  return client.post("/salon/dms/create", newDMsData).then((res) => {
    if (res.data.success) {
      return {
        data: res.data.result,
      };
    }
    return { data: {} };
  });
};

export const getDMsAll = (date, deliveryType) => {
  return client
    .get(`/salon/dms/all?date=${date}&deliveryType=${deliveryType}`)
    .then((res) => {
      if (res.data.success) {
        return {
          data: res.data.result,
        };
      }
      return { data: {} };
    });
};

export const getAllTargetAudience = () => {
  return client.get("/salon/dms/target-audiencell").then((res) => {
    if (res.data.success) {
      return {
        data: res.data.result,
      };
    }
    return { data: {} };
  });
};
