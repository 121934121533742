import React from "react";
import Dialog from "@mui/material/Dialog";
import Fade from "@mui/material/Fade";

function Confirm(props) {
  const { open, handleOk, handleCancel, message, okTitle, cancelTitle } = props;
  return (
    <Dialog
      maxWidth={"sm"}
      fullWidth
      open={open}
      onClose={() => {}}
      classes={{ root: "bg-transparent" }}
      TransitionComponent={Fade}
    >
      <div className="w-full flex flex-col p-8">
        <div className="w-full grow min-h-[60px] text-lg font-semibold">{message}</div>

        <div className="w-full flex items-center justify-end text-white text-base font-semibold">
          <button
            onClick={() => {
              handleCancel && handleCancel();
            }}
            type="button"
            className="button-size rounded bg-blue-btn-primary mr-4 lg:mr-6"
          >
            {cancelTitle || "Cancel"}
          </button>
          <button
            onClick={() => {
              handleOk && handleOk();
            }}
            type="button"
            className="button-size rounded bg-blue-btn-primary mr-6"
          >
            {okTitle || "OK"}
          </button>
        </div>
      </div>
    </Dialog>
  );
}

export default Confirm;
