import React, { useMemo } from "react";
import moment from "moment";

import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "moment/locale/ja";
import { defaultDateFormat } from "../../../constants";

export default function InputTimeFieldLarge(props) {
  const { time, setTime, className, disabled, error } = props;

  const value = useMemo(() => {
    if (!time) return null;
    const timeArr = time.split(":");
    return moment(moment().format(defaultDateFormat), defaultDateFormat)
      .set("hour", Number(timeArr[0]))
      .set("minute", Number(timeArr[1]));
  }, [time]);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="ja">
      <TimePicker
        value={value}
        onChange={(newValue) => setTime(newValue.format("HH:mm"))}
        ampm={false}
        // minutesStep={15}
        className={className}
        sx={{
          "& .MuiInputBase-input": {
            padding: "4px 0px 4px 8px !important",
            lineHeight: "24px !important",
            height: "48px !important",
            fontSize: "20px",
            fontWeight: "600",
            color: error ? "#EF4444 !important" : null,
          },
          "& .Mui-disabled": {
            backgroundColor: "#f3f4f6 !important",
          },
          "& input.Mui-disabled ": {
            color: "#000 !important",
            "-webkit-text-fill-color": "#000 !important",
          },
        }}
        disabled={disabled}
      />
    </LocalizationProvider>
  );
}
