export const fake_customer_info = {
  id: 1,
  firstName: "firstName",
  lastName: "lastName",
  firstNameKana: "firstNameKana",
  lastNameKana: "lastNameKana",
  dateOfBirth: "8/22/2023",
  phoneNumber1: "+12345678910",
  phoneNumber2: "+24681357900",
  emailAddress: "emailAddress@gmail.com",
  address1: "1-1 Kasumigaokamachi, Shinjuku City, Tokyo 160-0013, Japan",
  address2: "1-1 Kasumigaokamachi, Shinjuku City, Tokyo 160-0013, Japan",
};

export const fake_category_with_service = [
  {
    id: 1,
    title: "シャンプー",

    services: [
      {
        id: 1,
        title: "Service 1 シャンプー",
        price: 500,
        estimatedServiceTime: 60, // minutes
      },
      {
        id: 2,
        title: "Service 2 シャンプー",
        price: 1000,
        estimatedServiceTime: 120, // minutes
      },
      {
        id: 3,
        title: "Service 3 シャンプー",
        price: 2000,
        estimatedServiceTime: 180, // minutes
      },
      {
        id: 4,
        title: "Service 4 シャンプー",
        price: 3000,
        estimatedServiceTime: 180, // minutes
      },
    ],
  },
  {
    id: 2,
    title: "トリートメント",

    services: [
      {
        id: 5,
        title: "Service 1 トリートメント",
        price: 500,
        estimatedServiceTime: 60, // minutes
      },
      {
        id: 6,
        title: "Service 2 トリートメント",
        price: 1000,
        estimatedServiceTime: 120, // minutes
      },
      {
        id: 7,
        title: "Service 3 トリートメント",
        price: 2000,
        estimatedServiceTime: 180, // minutes
      },
      {
        id: 8,
        title: "Service 4 トリートメント",
        price: 3000,
        estimatedServiceTime: 180, // minutes
      },
    ],
  },
  {
    id: 3,
    title: "カット",

    services: [
      {
        id: 9,
        title: "Service 1 カット",
        price: 500,
        estimatedServiceTime: 60, // minutes
      },
      {
        id: 10,
        title: "Service 2 カット",
        price: 1000,
        estimatedServiceTime: 120, // minutes
      },
      {
        id: 11,
        title: "Service 3 カット",
        price: 2000,
        estimatedServiceTime: 180, // minutes
      },
      {
        id: 12,
        title: "Service 4 カット",
        price: 3000,
        estimatedServiceTime: 180, // minutes
      },
    ],
  },
  {
    id: 4,
    title: "パーマ",

    services: [
      {
        id: 13,
        title: "Service 1 パーマ",
        price: 500,
        estimatedServiceTime: 60, // minutes
      },
      {
        id: 14,
        title: "Service 2 パーマ",
        price: 1000,
        estimatedServiceTime: 120, // minutes
      },
      {
        id: 15,
        title: "Service 3 パーマ",
        price: 2000,
        estimatedServiceTime: 180, // minutes
      },
      {
        id: 16,
        title: "Service 4 パーマ",
        price: 3000,
        estimatedServiceTime: 180, // minutes
      },
    ],
  },
  {
    id: 5,
    title: "シャンプー",

    services: [
      {
        id: 17,
        title: "Service 1 シャンプー",
        price: 500,
        estimatedServiceTime: 60, // minutes
      },
      {
        id: 18,
        title: "Service 2 シャンプー",
        price: 1000,
        estimatedServiceTime: 120, // minutes
      },
      {
        id: 19,
        title: "Service 3 シャンプー",
        price: 2000,
        estimatedServiceTime: 180, // minutes
      },
      {
        id: 20,
        title: "Service 4 シャンプー",
        price: 3000,
        estimatedServiceTime: 180, // minutes
      },
    ],
  },
];

export const fake_revervation_info = {
  customerInfo: {
    id: 1,
    firstName: "firstName",
    lastName: "lastName",
    firstNameKana: "firstNameKana",
    lastNameKana: "lastNameKana",
    dateOfBirth: "8/22/2023",
    phoneNumber1: "+12345678910",
    phoneNumber2: "+24681357900",
    emailAddress: "emailAddress@gmail.com",
    address1: "1-1 Kasumigaokamachi, Shinjuku City, Tokyo 160-0013, Japan",
    address2: "1-1 Kasumigaokamachi, Shinjuku City, Tokyo 160-0013, Japan",
  },
  counselingInfo: {
    counselingSheetField1: "",
    counselingSheetField2: "",
    counselingSheetField3: "",
  },
  servicesList: [
    {
      category: {
        id: 1,
        title: "シャンプー",
      },
      service: {
        id: 1,
        title: "Service 1 シャンプー",
        price: 500,
        estimatedServiceTime: 60, // minutes
      },
    },
    {
      category: {
        id: 2,
        title: "トリートメント",
      },
      service: {
        id: 7,
        title: "Service 3 トリートメント",
        price: 2000,
        estimatedServiceTime: 180, // minutes
      },
    },
    {
      category: {
        id: 3,
        title: "カット",
      },
      service: {
        id: 12,
        title: "Service 4 カット",
        price: 3000,
        estimatedServiceTime: 180, // minutes
      },
    },
    {
      category: {
        id: 4,
        title: "パーマ",
      },
      service: {
        id: 15,
        title: "Service 3 パーマ",
        price: 2000,
        estimatedServiceTime: 180, // minutes
      },
    },
    {
      category: {
        id: 5,
        title: "シャンプー",
      },
      service: {
        id: 19,
        title: "Service 3 シャンプー",
        price: 2000,
        estimatedServiceTime: 180, // minutes
      },
    },
  ],
  startTime: "2023-08-22T09:07:50.797Z",
  estimatedEndTime: "2023-08-22T09:07:50.797Z",
};

export const fake_counseling_sheet_list = [
  {
    id: 1,
    createdAt: "2023年8月9日（水） 11:00",
    summary:
      "料ッの本容ワユヱヘ告族サネカ身投エモコ減3切角ルヘ慌所ぼ社性道べずね読真ぴご期篠希ふ均推軽ばフ。",
    content:
      "料ッの本容ワユヱヘ告族サネカ身投エモコ減3切角ルヘ慌所ぼ社性道べずね読真ぴご期篠希ふ均推軽ばフ。工ふん国宮ヨ松27認レメウハ行方フツマユ純意ち円場お整申見ウノ果集ンゅに寛度レフ閣台カヒエメ月惑トき要村じーへわ必難囲り。自がけだ転手ネナソ父津天イラツ午民ぐ投意ナ属真ムルエ性宿86促縮2議トて喩維ユツ届独善伯堤墜らも。",
  },
  {
    id: 2,
    createdAt: "2023-10-23T06:42:35.935Z",
    summary:
      "半セ会経牧傾りむも紙著ヲモセ滅質るれ員羽割と身資わイ災1極ち欧先コ症樹ラチ介近ぱょ練渡23議3後互む。",
    content:
      "半セ会経牧傾りむも紙著ヲモセ滅質るれ員羽割と身資わイ災1極ち欧先コ症樹ラチ介近ぱょ練渡23議3後互む。議イ索無ヘエマ室3業ぎま土津み減供トエナニ件並めリり木注かゅぴ氏急もしス載書けめょべ王際しにぶっ時侃凸だ。業モア録投のそめ川通れづッ基購皇と身康ア見次ホセロフ話回サオソヒ女線待無い陸意文モユセル下変クヲミノ町文エロノテ式成づどゅら職人ワカ質勢土工丹んぜのか。",
  },
  {
    id: 3,
    createdAt: "2023-12-23T06:42:35.935Z",
    summary:
      "教えせあフ想線81尾八さにら転年ラ也更ヒ変販そ告前かせき暗伊れろぐ賄資ネイ容半ヨワケ写3東えッ氏海モノニ識台リだ県科だ講駐せぎラび無曽膨柴熊ひッてン。",
    content:
      "教えせあフ想線81尾八さにら転年ラ也更ヒ変販そ告前かせき暗伊れろぐ賄資ネイ容半ヨワケ写3東えッ氏海モノニ識台リだ県科だ講駐せぎラび無曽膨柴熊ひッてン。香トめ手提降ムトエ米給がはらあ読練料ソユヘ代議ヨル木57高幹圧秘はごーぴ真訓誘略犯へ。準わと属最スクヤ度条ウヒケ賞育イネカ旅掲ク光務さ写十ノモメ月世テヲノメ完声あリろ香言旧ち完通ぱ指著ク会2院クル。",
  },
  {
    id: 4,
    createdAt: "2023-13-23T06:42:35.935Z",
    summary:
      "玉ムフ川睦シエヘ定銭うろょど指案ムオヒ遺人ぎ提登よ霊保キ争更レ座関モヘワロ備点ニロオソ込軽いぐぞ録際も更政ニ仕傾督ヲ展勝とルしね盛38形越極86昇ちイス。",
    content:
      "玉ムフ川睦シエヘ定銭うろょど指案ムオヒ遺人ぎ提登よ霊保キ争更レ座関モヘワロ備点ニロオソ込軽いぐぞ録際も更政ニ仕傾督ヲ展勝とルしね盛38形越極86昇ちイス。協するび写生健要ム新開がよ本読トミイテ祭立げ住57経26人やんうイ特備予案レシコヨ週会せク宿主ン電内やょほ問木現謝専ほ。  ",
  },
  {
    id: 5,
    createdAt: "2023-14-23T06:42:35.935Z",
    summary:
      "惚ごぼ間投ちゅぞ教館伝ほぼづー通及ル向嶺ユハモホ渉割ヤワラツ世勝済ぼ育19随ぎフ集校ざ詳属覧ま好実きこ覧激みへ。",
    content:
      "惚ごぼ間投ちゅぞ教館伝ほぼづー通及ル向嶺ユハモホ渉割ヤワラツ世勝済ぼ育19随ぎフ集校ざ詳属覧ま好実きこ覧激みへ。選ヒウ天風1容カエヱ締9天セクメウ位克ぴゅンえ川人ぼ度禁かー若督ナワヌタ穴持ノ常7姿フ。接コホ更吉れほ慎刊とゆス創京用テ意始ぼづレ碁容指だぼし護状ツ論能ノヱレツ図引ケ実白っスたふ済本ッ与弾元ホ勝受つらべ昇謝俊町ルハラカ条2旗昼でぎさつ。",
  },
];

export const fake_visit_history_list = [
  {
    id: 1,
    createdAt: "2023年8月9日（水） 11:00",
    personinCharge: {
      id: 1,
      name: "test",
    },
    servicesList: [
      {
        category: {
          id: 1,
          title: "シャンプー",
        },
        service: {
          id: 1,
          title: "Service 1 シャンプー",
          price: 500,
          estimatedServiceTime: 60, // minutes
        },
      },
      {
        category: {
          id: 2,
          title: "トリートメント",
        },
        service: {
          id: 7,
          title: "Service 3 トリートメント",
          price: 2000,
          estimatedServiceTime: 180, // minutes
        },
      },
      {
        category: {
          id: 3,
          title: "カット",
        },
        service: {
          id: 12,
          title: "Service 4 カット",
          price: 3000,
          estimatedServiceTime: 180, // minutes
        },
      },
      {
        category: {
          id: 4,
          title: "パーマ",
        },
        service: {
          id: 15,
          title: "Service 3 パーマ",
          price: 2000,
          estimatedServiceTime: 180, // minutes
        },
      },
      {
        category: {
          id: 5,
          title: "シャンプー",
        },
        service: {
          id: 19,
          title: "Service 3 シャンプー",
          price: 2000,
          estimatedServiceTime: 180, // minutes
        },
      },
    ],
  },
  {
    id: 2,
    createdAt: "2023年8月9日（水） 11:00",
    personinCharge: {
      id: 1,
      name: "test",
    },
    servicesList: [
      {
        category: {
          id: 1,
          title: "シャンプー",
        },
        service: {
          id: 1,
          title: "Service 1 シャンプー",
          price: 500,
          estimatedServiceTime: 60, // minutes
        },
      },
      {
        category: {
          id: 2,
          title: "トリートメント",
        },
        service: {
          id: 7,
          title: "Service 3 トリートメント",
          price: 2000,
          estimatedServiceTime: 180, // minutes
        },
      },
      {
        category: {
          id: 3,
          title: "カット",
        },
        service: {
          id: 12,
          title: "Service 4 カット",
          price: 3000,
          estimatedServiceTime: 180, // minutes
        },
      },
      {
        category: {
          id: 4,
          title: "パーマ",
        },
        service: {
          id: 15,
          title: "Service 3 パーマ",
          price: 2000,
          estimatedServiceTime: 180, // minutes
        },
      },
      {
        category: {
          id: 5,
          title: "シャンプー",
        },
        service: {
          id: 19,
          title: "Service 3 シャンプー",
          price: 2000,
          estimatedServiceTime: 180, // minutes
        },
      },
    ],
  },
  {
    id: 3,
    createdAt: "2023年8月9日（水） 11:00",
    personinCharge: {
      id: 1,
      name: "test",
    },
    servicesList: [
      {
        category: {
          id: 1,
          title: "シャンプー",
        },
        service: {
          id: 1,
          title: "Service 1 シャンプー",
          price: 500,
          estimatedServiceTime: 60, // minutes
        },
      },
      {
        category: {
          id: 2,
          title: "トリートメント",
        },
        service: {
          id: 7,
          title: "Service 3 トリートメント",
          price: 2000,
          estimatedServiceTime: 180, // minutes
        },
      },
      {
        category: {
          id: 3,
          title: "カット",
        },
        service: {
          id: 12,
          title: "Service 4 カット",
          price: 3000,
          estimatedServiceTime: 180, // minutes
        },
      },
      {
        category: {
          id: 4,
          title: "パーマ",
        },
        service: {
          id: 15,
          title: "Service 3 パーマ",
          price: 2000,
          estimatedServiceTime: 180, // minutes
        },
      },
      {
        category: {
          id: 5,
          title: "シャンプー",
        },
        service: {
          id: 19,
          title: "Service 3 シャンプー",
          price: 2000,
          estimatedServiceTime: 180, // minutes
        },
      },
    ],
  },
  {
    id: 4,
    createdAt: "2023年8月9日（水） 11:00",
    personinCharge: {
      id: 1,
      name: "test",
    },
    servicesList: [
      {
        category: {
          id: 1,
          title: "シャンプー",
        },
        service: {
          id: 1,
          title: "Service 1 シャンプー",
          price: 500,
          estimatedServiceTime: 60, // minutes
        },
      },
      {
        category: {
          id: 2,
          title: "トリートメント",
        },
        service: {
          id: 7,
          title: "Service 3 トリートメント",
          price: 2000,
          estimatedServiceTime: 180, // minutes
        },
      },
      {
        category: {
          id: 3,
          title: "カット",
        },
        service: {
          id: 12,
          title: "Service 4 カット",
          price: 3000,
          estimatedServiceTime: 180, // minutes
        },
      },
      {
        category: {
          id: 4,
          title: "パーマ",
        },
        service: {
          id: 15,
          title: "Service 3 パーマ",
          price: 2000,
          estimatedServiceTime: 180, // minutes
        },
      },
      {
        category: {
          id: 5,
          title: "シャンプー",
        },
        service: {
          id: 19,
          title: "Service 3 シャンプー",
          price: 2000,
          estimatedServiceTime: 180, // minutes
        },
      },
    ],
  },
  {
    id: 5,
    createdAt: "2023年8月9日（水） 11:00",
    personinCharge: {
      id: 1,
      name: "test",
    },
    servicesList: [
      {
        category: {
          id: 1,
          title: "シャンプー",
        },
        service: {
          id: 1,
          title: "Service 1 シャンプー",
          price: 500,
          estimatedServiceTime: 60, // minutes
        },
      },
      {
        category: {
          id: 2,
          title: "トリートメント",
        },
        service: {
          id: 7,
          title: "Service 3 トリートメント",
          price: 2000,
          estimatedServiceTime: 180, // minutes
        },
      },
      {
        category: {
          id: 3,
          title: "カット",
        },
        service: {
          id: 12,
          title: "Service 4 カット",
          price: 3000,
          estimatedServiceTime: 180, // minutes
        },
      },
      {
        category: {
          id: 4,
          title: "パーマ",
        },
        service: {
          id: 15,
          title: "Service 3 パーマ",
          price: 2000,
          estimatedServiceTime: 180, // minutes
        },
      },
      {
        category: {
          id: 5,
          title: "シャンプー",
        },
        service: {
          id: 19,
          title: "Service 3 シャンプー",
          price: 2000,
          estimatedServiceTime: 180, // minutes
        },
      },
    ],
  },
];

export const fake_reservation_list = [
  {
    id: 1,
    visitDate: "08/09",
    startTime: "11:00",
    finishTime: "12:30",
    staff: {
      id: 1,
      name: "スタッフA",
      lastNameKata: "セイ",
      firstNameKata: "メイ",
      lastNameKanji: "姓",
      firstNameKanji: "名",
      imageUrl: "",
    },
    customer: {
      id: 2,
      lastNameKata: "セイ",
      firstNameKata: "メイ",
      lastNameKanji: "姓",
      firstNameKanji: "名",
      isMember: 0,
    },
  },
  {
    id: 1,
    visitDate: "08/09",
    startTime: "11:00",
    finishTime: "12:30",
    staff: {
      id: 1,
      name: "スタッフA",
      lastNameKata: "セイ",
      firstNameKata: "メイ",
      lastNameKanji: "姓",
      firstNameKanji: "名",
      imageUrl: "",
    },
    customer: {
      id: 2,
      lastNameKata: "セイ",
      firstNameKata: "メイ",
      lastNameKanji: "姓",
      firstNameKanji: "名",
      isMember: true,
    },
  },

  {
    id: 1,
    visitDate: "08/09",
    startTime: "11:00",
    finishTime: "12:30",
    staff: {
      id: 1,
      name: "スタッフA",
      lastNameKata: "セイ",
      firstNameKata: "メイ",
      lastNameKanji: "姓",
      firstNameKanji: "名",
      imageUrl: "",
    },
    customer: {
      id: 2,
      lastNameKata: "セイ",
      firstNameKata: "メイ",
      lastNameKanji: "姓",
      firstNameKanji: "名",
      isMember: true,
    },
  },
  {
    id: 1,
    visitDate: "08/09",
    startTime: "11:00",
    finishTime: "12:30",
    staff: {
      id: 1,
      name: "スタッフA",
      lastNameKata: "セイ",
      firstNameKata: "メイ",
      lastNameKanji: "姓",
      firstNameKanji: "名",
      imageUrl: "",
    },
    customer: {
      id: 2,
      lastNameKata: "セイ",
      firstNameKata: "メイ",
      lastNameKanji: "姓",
      firstNameKanji: "名",
      isMember: false,
    },
  },
];
export const staff_member_list = [
  {
    id: 1,
    chart: "1",
    name: "スタッフA",
    imageUrl: "https://cdn-icons-png.flaticon.com/512/484/484619.png",
    staffRoles: {
      isReservationManagement: 0,
      isCustomerManagement: 0,
      isPersonalSalesConfirmation: 1,
      isSalesInput: 0,
      isRegisterEntry: 1,
      isChangeCashRegisterContents: 1,
      isDeleteCashRegisterContents: 0,
      isCheckHistory: 0,
      isInventoryManagement: 1,
      isAttendanceManagement: 0,
      isScalpLabManagement: 1,
      isDMDelivery: 0,
    },
    data: [
      {
        name: "一月",
        pv: 2400,
      },
      {
        name: "二月",
        pv: 1398,
      },
      {
        name: "三月",
        pv: 9800,
      },
      {
        name: "四月",
        pv: 3908,
      },
      {
        name: "五月",
        pv: 4800,
      },
      {
        name: "六月",
        pv: 2400,
      },
      {
        name: "七月",
        pv: 1398,
      },
      {
        name: "八月",
        pv: 9800,
      },
      {
        name: "九月",
        pv: 3908,
      },
      {
        name: "十月",
        pv: 4800,
      },
      {
        name: "十一月",
        pv: 3908,
      },
      {
        name: "十二月",
        pv: 4800,
      },
    ],
  },
  {
    id: 2,
    chart: "2",
    name: "スタッフB",
    imageUrl: "https://cdn-icons-png.flaticon.com/512/4974/4974985.png",
    staffRoles: {
      isReservationManagement: 1,
      isCustomerManagement: 1,
      isPersonalSalesConfirmation: 1,
      isSalesInput: 0,
      isRegisterEntry: 1,
      isChangeCashRegisterContents: 0,
      isDeleteCashRegisterContents: 0,
      isCheckHistory: 0,
      isInventoryManagement: 1,
      isAttendanceManagement: 0,
      isScalpLabManagement: 0,
      isDMDelivery: 0,
    },
    data: [
      {
        name: "いちがつ",
        pv: 3333,
      },
      {
        name: "にがつ",
        pv: 5544,
      },
      {
        name: "さんがつ",
        pv: 9800,
      },
      {
        name: "しがつ",
        pv: 3908,
      },
      {
        name: "ごがつ",
        pv: 1234,
      },
    ],
  },
  {
    id: 3,
    chart: "3",
    name: "スタッフC",
    imageUrl:
      "https://static-00.iconduck.com/assets.00/staff-icon-424x512-xlph5vhn.png",
    staffRoles: {
      isReservationManagement: 0,
      isCustomerManagement: 0,
      isPersonalSalesConfirmation: 0,
      isSalesInput: 1,
      isRegisterEntry: 1,
      isChangeCashRegisterContents: 1,
      isDeleteCashRegisterContents: 0,
      isCheckHistory: 0,
      isInventoryManagement: 1,
      isAttendanceManagement: 0,
      isScalpLabManagement: 0,
      isDMDelivery: 0,
    },
    data: [
      {
        name: "いちがつ",
        pv: 3333,
      },
      {
        name: "にがつ",
        pv: 4444,
      },
      {
        name: "さんがつ",
        pv: 1111,
      },
      {
        name: "しがつ",
        pv: 6666,
      },
      {
        name: "ごがつ",
        pv: 1234,
      },
    ],
  },
  {
    id: 4,
    chart: "4",
    name: "スタッフD",
    staffRoles: {
      isReservationManagement: 0,
      isCustomerManagement: 0,
      isPersonalSalesConfirmation: 1,
      isSalesInput: 1,
      isRegisterEntry: 1,
      isChangeCashRegisterContents: 1,
      isDeleteCashRegisterContents: 0,
      isCheckHistory: 0,
      isInventoryManagement: 1,
      isAttendanceManagement: 1,
      isScalpLabManagement: 1,
      isDMDelivery: 0,
    },
    data: [
      {
        name: "いちがつ",
        pv: 1234,
      },
      {
        name: "にがつ",
        pv: 5632,
      },
      {
        name: "さんがつ",
        pv: 2032,
      },
      {
        name: "しがつ",
        pv: 2121,
      },
      {
        name: "ごがつ",
        pv: 9999,
      },
    ],
  },
  {
    id: 5,
    chart: "5",
    name: "スタッフE",
    staffRoles: {
      isReservationManagement: 0,
      isCustomerManagement: 0,
      isPersonalSalesConfirmation: 0,
      isSalesInput: 0,
      isRegisterEntry: 1,
      isChangeCashRegisterContents: 1,
      isDeleteCashRegisterContents: 0,
      isCheckHistory: 0,
      isInventoryManagement: 0,
      isAttendanceManagement: 0,
      isScalpLabManagement: 0,
      isDMDelivery: 0,
    },
    data: [
      {
        name: "いちがつ",
        pv: 3333,
      },
      {
        name: "にがつ",
        pv: 1111,
      },
      {
        name: "さんがつ",
        pv: 6666,
      },
      {
        name: "しがつ",
        pv: 7777,
      },
      {
        name: "ごがつ",
        pv: 8888,
      },
    ],
  },
  {
    id: 6,
    chart: "6",
    name: "スタッフF",
    staffRoles: {
      isReservationManagement: 1,
      isCustomerManagement: 0,
      isPersonalSalesConfirmation: 0,
      isSalesInput: 0,
      isRegisterEntry: 1,
      isChangeCashRegisterContents: 1,
      isDeleteCashRegisterContents: 0,
      isCheckHistory: 0,
      isInventoryManagement: 1,
      isAttendanceManagement: 1,
      isScalpLabManagement: 1,
      isDMDelivery: 0,
    },
    data: [
      {
        name: "いちがつ",
        pv: 3333,
      },
      {
        name: "にがつ",
        pv: 5544,
      },
      {
        name: "さんがつ",
        pv: 1235,
      },
      {
        name: "しがつ",
        pv: 2222,
      },
      {
        name: "ごがつ",
        pv: 5648,
      },
    ],
  },
];

export const fake_introduction_of_scalp_lap = {
  id: 1,
  content:
    "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)",
};
export const product_category = {
  shampoo: "shampoo",
  treatment: "treatment",
  rinse: "rinse",
  other: "other",
};

export const product_category_and_label = [
  { key: product_category.shampoo, label: "シャンプー" },
  { key: product_category.treatment, label: "処理" },
  { key: product_category.rinse, label: "リンス" },
  { key: product_category.other, label: "他の" },
];

export const all_services = product_category_and_label.reduce(
  (oldList, item, category_index) => {
    new Array(6).fill(1).forEach((_, index) => {
      oldList.push({
        id: category_index + index,
        menuType: item.key,
        name: `${item.label} ${index + 1}`,
        durationString: `${(index + 1) * 60} 分`,
        durationNumber: (index + 1) * 60,
        priceTaxExc: (index + 1) * 1000,
        priceTaxInc: (index + 1) * 1000 * 1.08,
        itemToUse: "",
      });
    });

    return [...oldList];
  },
  []
);

export const fake_history_order_list = [
  {
    id: 1,
    customerNo: "001",
    customerName: "luke",
    shippingDateTime: "12:30 12/09/2023",
    situation: "done",
  },
  {
    id: 2,
    customerNo: "002",
    customerName: "John",
    shippingDateTime: "12:30 12/09/2023",
    situation: "pending",
  },
];

export const fake_kits_of_scalp_lap = {
  id: 1,
  number: 40,
};

export const fake_menu_list = [
  {
    id: 1,
    name: "menu1",
    menuType: "shampoo",
    durationNumber: "30",
    priceTaxExc: "1200",
    publicityStatus: 0, // public
  },
  {
    id: 2,
    name: "menu2",
    menuType: "treatment",
    durationNumber: "100",
    priceTaxExc: "2000",
    publicityStatus: 1, // private
  },
];

export const fake_history_cashier_list = [
  {
    id: 1,
    accountingDateTime: "12/09/2023 10:00",
    customerName: "Luke",
    paymentMethod: "cash",
    amount: " 10000",
    cashier: "A",
  },
  {
    id: 2,
    accountingDateTime: "12/09/2023 08:00",
    customerName: "John",
    paymentMethod: "master",
    amount: " 20000",
    cashier: "B",
  },
];

export const fake_total_sales_today = {
  id: 1,
  total: 100000,
};

export const fake_dms_list = [
  {
    id: 1,
    member: "A",
    statusDelivery: 0, // INSTANT_DELIVERY
    dateTime: "12/09/2023 08:00",
    title: "title1",
    content: "abc",
  },
  {
    id: 2,
    member: "B",
    statusDelivery: 1, // RESERVATION_DELIVERY
    dateTime: "12/09/2023 12:00",
    title: "title2",
    content: "xyz",
  },
];
export const fake_inventory_list = [
  {
    id: 1,
    productNumber: "100A",
    marker: "brand A",
    series: "series 1",
    name: "product name 1",
    inventory: "20",
  },
  {
    id: 2,
    productNumber: "100B",
    marker: "brand B ",
    series: "series 2",
    name: " product name 2",
    inventory: "30",
  },
];

export const fake_history_dms_list = [
  {
    id: 1,
    shippingDateTime: "12/09/2023 12:30 ",
    statusDelivery: "done",
    statusShipping: "配信済み",
    title: " title1",
    content: "content1",
  },
  {
    id: 2,
    shippingDateTime: "12/09/2023 14:00",
    statusDelivery: "delivering",
    statusShipping: "予約済み",
    title: " title2",
    content: "content2",
  },
];
