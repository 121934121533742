import React from "react";
import { useNavigate } from "react-router-dom";
import Path from "app/route/Path";
import AnalyzeBox from "./AnalyzeBox";
import CustomPieChart from "../../components/CustomPieChart";

function CostRateAnalyze() {
  const navigate = useNavigate();
  return (
    <AnalyzeBox
      title="経費率"
      onClickTitle={() => navigate(Path.costRateAnalyzeManagement)}
    >
      <CustomPieChart rate={0.34} />
    </AnalyzeBox>
  );
}

export default CostRateAnalyze;
