import moment from "moment";
import client from "../client";
import { defaultDateFormat } from "../../../constants";

const chartDataAdapter = (rawData) => ({
  dateTime: rawData.paymentDate,
  amount: rawData.paymentAmount,
});

const getAllVistPayment = (start, end, staffId) => {
  return client
    .get("salon/salon-staff/visit-payment", {
      params: staffId
        ? { startDate: start, endDate: end, staffId: staffId }
        : { startDate: start, endDate: end },
    })
    .then((res) => {
      if (res.data.success) {
        return {
          data: res.data.result.map((rawData) => chartDataAdapter(rawData)),
        };
      }
      return { data: [] };
    });
};

const getAllNonCustomerPayment = (start, end, staffId) => {
  return client
    .get("/salon/non-visit-payment", {
      params: staffId
        ? { startDate: start, endDate: end }
        : { startDate: start, endDate: end, staffId: staffId },
    })
    .then((res) => {
      if (res.data.success) {
        return {
          data: res.data.result.map((rawData) => chartDataAdapter(rawData)),
        };
      }
      return { data: [] };
    });
};

export const getSalonSaleDetail = (start, end) => {
  return Promise.all([
    getAllVistPayment(start, end),
    getAllNonCustomerPayment(start, end),
  ]).then((res) => {
    return { data: [...res[0].data, ...res[1].data] };
  });
};

export const addManyNonCustomerPaymentWithStaff = (staffId, paymentList) => {
  return client
    .post("/non-visit-payment/create-many", {
      staffId: staffId,
      nonVisitPayments: paymentList.map((item) => ({
        paymentDate: moment
          .tz(item.workDate, defaultDateFormat, true, "Asia/Tokyo")
          .toISOString(),
        paymentType: item.paymentType,
        paymentMethod: "",
        paymentAmount: item.amount,
      })),
    })
    .then((res) => {
      if (res.data.success) {
        return {
          data: res.data.result,
        };
      }
      return { data: [] };
    });
};

export const getSalonStaffSaleDetail = (start, end, staffId) => {
  return Promise.all([
    getAllVistPayment(start, end, staffId),
    getAllNonCustomerPayment(start, end, staffId),
  ]).then((res) => {
    return { data: [...res[0].data, ...res[1].data] };
  });
};
