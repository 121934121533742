import React, { useEffect, useState } from "react";
import DefaultLayout from "../../../layouts/DefaultLayout";
import MainContentLayout from "../../../layouts/MainContentLayout";
import Path from "../../../route/Path";
import { useNavigate } from "react-router-dom";
import CreateDmsForm from "./CreateDmsForm";
import { getAllTargetAudience } from "../../../services/api/1A";

function DMsManagementTopPage() {
  const [listCustomer, setListCustomer] = useState();

  const loadSalonDetail = () => {
    getAllTargetAudience().then((res) => {
      setListCustomer(res.data);
    });
  };

  useEffect(() => {
    loadSalonDetail();
  }, []);
  const navigate = useNavigate();

  return (
    <DefaultLayout>
      <MainContentLayout
        pageTitle={
          <>
            <p>ダイレクト</p>
            <p>メール</p>
          </>
        }
      >
        <div className="w-full h-full pt-16 pb-8 lg:py-12 relative">
          <div className="w-full h-16 lg:h-12 pl-20 lg:pl-0 flex items-center justify-between absolute inset-x-0 top-0 bg-white">
            <h2 className="text-base lg:text-xl font-semibold">
              ダイレクトメールを作成
            </h2>
            <button
              onClick={() => navigate(Path.dmsManagementHistory)}
              className="button-size  text-white text-base font-semibold rounded bg-blue-btn-primary"
            >
              配信履歴
            </button>
          </div>
          <div className="w-full h-full py-3">
            <div className="w-full h-full pr-3 ">
              <div className="w-full h-full overflow-auto">
                <CreateDmsForm listCustomer={listCustomer} />
              </div>
            </div>
          </div>
        </div>
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default DMsManagementTopPage;
