import React, { useEffect, useMemo, useState } from "react";

import {
  statisticalDataByYear,
  statisticalDataByMonth,
  statisticalDataByWeek,
  statisticalDataByDay,
  statisticalDataByDayOfWeek,
  statisticalDataByHour,
} from "./dataHandlers";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import LoadingProgress from "../commonUI/LoadingProgress";

export const chartDataTypeOptions = [
  {
    key: "by_year",
    label: "年別",
    dataHandlerFunction: statisticalDataByYear,
  },
  {
    key: "by_month",
    label: "月別",
    dataHandlerFunction: statisticalDataByMonth,
  },
  {
    key: "by_day",
    label: "日別",
    dataHandlerFunction: statisticalDataByDay,
  },
  {
    key: "by_week",
    label: "年別",
    dataHandlerFunction: statisticalDataByWeek,
  },
  {
    key: "by_day_of_week",
    label: "曜日別",
    dataHandlerFunction: statisticalDataByDayOfWeek,
  },
  {
    key: "by_hour",
    label: "時間別",
    dataHandlerFunction: statisticalDataByHour,
  },
];

const CommonStatisticalChart = React.forwardRef((props, ref) => {
  const { data, startTime, endTime, isLoading, setCurrentChartData } = props;
  const [charDataType, setCharDataType] = useState(chartDataTypeOptions[0]);

  const dataToShow = useMemo(() => {
    if (
      !Array.isArray(data) ||
      typeof charDataType?.dataHandlerFunction !== "function"
    )
      return [];

    const dataHandlerFunction = charDataType?.dataHandlerFunction;
    return dataHandlerFunction(data, startTime, endTime);
  }, [data, charDataType, startTime, endTime]);

  useEffect(() => {
    if (
      typeof setCurrentChartData === "function" &&
      Array.isArray(dataToShow)
    ) {
      const exportData = [["label", "amount"]];
      dataToShow.forEach((item) => {
        exportData.push([item.label, item.amount]);
      });
      setCurrentChartData(exportData);
    }
  }, [dataToShow]);

  return (
    <div className="w-full h-full pt-14 relative">
      <div className="absolute inset-x-0 top-0 w-full h-12 mb-2 flex flex-nowrap overflow-auto small-scrollbar">
        {chartDataTypeOptions.map((item) => (
          <button
            key={item.key}
            className={`shrink-0 h-8 text-base leading-5 px-5 py-1.5 border border-gray-300 rounded-md mr-4 mb-2 transition-all hover:bg-slate-100 break-keep ${
              charDataType.key === item.key
                ? "!bg-blue-btn-primary !border-blue-btn-primary !text-white"
                : ""
            }`}
            onClick={() => setCharDataType(item)}
          >
            {item.label}
          </button>
        ))}
      </div>

      <div className="w-full h-full border border-gray-400 rounded-md p-4 relative">
        {Array.isArray(dataToShow) ? (
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={dataToShow}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
              ref={ref}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="label" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="amount" fill="#82ca9d" />
            </BarChart>
          </ResponsiveContainer>
        ) : null}

        {isLoading ? (
          <div className="w-full h-full absolute inset-0 bg-gray-500 bg-opacity-25 flex items-center justify-center">
            <LoadingProgress />
          </div>
        ) : null}
      </div>
    </div>
  );
});

// function CommonStatisticalChart(props) {

// }

export default CommonStatisticalChart;
