import React, { useEffect, useState } from "react";
import SelectMonth from "../../../components/commonUI/SelectMonth";
import SelectYear from "../../../components/commonUI/SelectYear";
import moment from "moment";
import { defaultDateFormat } from "../../../constants";
import { gettFirstDayOfThisMonth } from "../../../utils";

function SelectRangeToAnalyze(props) {
  const { startProp, endProp, setStartProp, setEndProp } = props;

  const [start, setStart] = useState(() => gettFirstDayOfThisMonth());
  const [end, setEnd] = useState(() =>
    gettFirstDayOfThisMonth().add(1, "months")
  );

  useEffect(() => {
    if (startProp) {
      if (
        startProp.format(defaultDateFormat) !== start.format(defaultDateFormat)
      ) {
        setStart(startProp);
      }
    } else {
      setStart(gettFirstDayOfThisMonth());
    }
  }, [startProp]);

  useEffect(() => {
    if (endProp) {
      if (endProp.format(defaultDateFormat) !== end.format(defaultDateFormat)) {
        setEnd(endProp);
      }
    } else {
      setEnd(gettFirstDayOfThisMonth().add(1, "months"));
    }
  }, [endProp]);

  const checkRangeIsValid = (startDate, endDate) => {
    return startDate.diff(endDate, "days") < 0;
  };

  const handleChangeStartMonth = (newMonthMoment) => {
    const newStart = start.clone().set("month", newMonthMoment.get("month"));
    setStartProp(newStart);
    if (!checkRangeIsValid(newStart, end)) {
      const newEnd = newStart.clone().add(1, "months");
      setEndProp(newEnd);
    }
  };

  const handleChangeStartYear = (newYearMoment) => {
    const newStart = start.clone().set("year", newYearMoment.get("year"));
    setStartProp(newStart);
    if (!checkRangeIsValid(newStart, end)) {
      const newEnd = newStart.clone().add(1, "months");
      setEndProp(newEnd);
    }
  };

  const handleChangeEndMonth = (newMonthMoment) => {
    const newEnd = end.clone().set("month", newMonthMoment.get("month"));
    setEndProp(newEnd);

    if (!checkRangeIsValid(start, newEnd)) {
      const newStart = newEnd.clone().add(-1, "months");
      setStartProp(newStart);
    }
  };

  const handleChangeEndYear = (newYearMoment) => {
    const newEnd = end.clone().set("year", newYearMoment.get("year"));
    setEndProp(newEnd);

    if (!checkRangeIsValid(start, newEnd)) {
      const newStart = newEnd.clone().add(-1, "months");
      setStartProp(newStart);
    }
  };

  return (
    <div className="w-full h-20 flex items-center px-8 rounded-md border border-gray-400">
      <div className="text-lg font-semibold mr-5">分析期間: </div>

      <div className="flex items-center">
        <div className="w-auto h-auto flex items-center gap-2">
          <SelectYear year={start} setYear={handleChangeStartYear} />
          <SelectMonth month={start} setMonth={handleChangeStartMonth} />
        </div>
        <div className="px-8">~</div>
        <div className="w-auto h-auto flex items-center gap-2">
          <SelectYear year={end} setYear={handleChangeEndYear} />
          <SelectMonth month={end} setMonth={handleChangeEndMonth} />
        </div>
      </div>
    </div>
  );
}

export default SelectRangeToAnalyze;
