import React from "react";
import DefaultLayout from "app/layouts/DefaultLayout";
import CounselingSheet from "../components/CounselingSheet";
import MainContentLayout from "app/layouts/MainContentLayout";

function CustomterCounselingSheetPage() {
  return (
    <DefaultLayout>
      <MainContentLayout
        pageTitle={
          <>
            <p>お客様の</p>
            <p>ご来店</p>
          </>
        }
      >
        <CounselingSheet />
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default CustomterCounselingSheetPage;
