import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import DefaultLayout from "../../../layouts/DefaultLayout";
import { useNavigate } from "react-router-dom";
import MainContentLayout from "../../../layouts/MainContentLayout";
import Path from "../../../route/Path";
import CommonDialog from "../../../components/CommonDialog";
import InputField from "../../../components/commonUI/InputField";
import {
  createNewScalpData,
  getAllSalonCustomer,
  getSalonCustomer,
} from "../../../services/api/1C";
import { FormControl, InputLabel } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { toast } from "react-toastify";

const confirmSteps = {
  CONFIRM: "CONFIRM",
  CONFIRM_AGAIN: "CONFIRM_AGAIN",
};

function OrderScalpLab() {
  const navigate = useNavigate();
  const [creatingOrderScalpState, setCreatingOrderScalpState] = useState({
    confirmedData: null,
    isCreating: false,
    error: null,
    res: null,
  });
  const [listCustomer, setListCustomer] = useState();
  const [customer, setCustomer] = useState();
  const [open, setOpen] = useState(false);
  const [showStep, setShowStep] = useState(confirmSteps.CONFIRM);
  const [customerNameValue, setCustomerName] = useState();

  const loadAllSalonCustomer = () => {
    getAllSalonCustomer().then((res) => {
      setListCustomer(res.data);
    });
  };

  const loadSalonCustomer = (customerno) => {
    getSalonCustomer(customerno).then((res) => {
      if (res.data !== null) {
        setCustomer(res.data);
        setFieldValue(
          "customerName",
          res.data?.firstNameKata + res.data?.lastNameKata
        );
        setCustomerName(res.data?.firstNameKata + res.data?.lastNameKata);
      }
    });
  };

  useEffect(() => {
    loadAllSalonCustomer();
  }, []);
  const handleChange = (event, name) => {
    console.log();
    setFieldValue("customerName", event.target.value);
    /* chen */
    // if (values.customerNo === "") {
    //   setFieldValue("customerNo", name.props.name);
    // }
    setFieldValue("customerNo", name.props.name);
  };

  const handleConfirm = () => {
    if (showStep === confirmSteps.CONFIRM) {
      handleSubmit();
      setShowStep(confirmSteps.CONFIRM_AGAIN);
    } else if (showStep === confirmSteps.CONFIRM_AGAIN) {
      // executeAction();
      setOpen(false);
      setShowStep(confirmSteps.CONFIRM);
      navigate(Path.scalpLabManagement);
    }
  };
  const validationSchema = Yup.object().shape({
    customerName: Yup.string().required("お客様名は必須です。"),
    customerNo: Yup.string().required("お客様No.は必須です。"),
  });

  const onSubmit = async (values) => {
    setCreatingOrderScalpState((oldState) => ({
      ...oldState,
      isCreating: true,
      error: null,
      res: null,
    }));

    createNewScalpData(values)
      .then((res) => {
        setCreatingOrderScalpState((oldState) => ({
          ...oldState,
          isCreating: false,
          error: null,
          res: res,
        }));
        toast.success("頭皮データの注文が成功しました");
      })
      .catch((error) => {
        setCreatingOrderScalpState((oldState) => ({
          ...oldState,
          isCreating: false,
          error: error,
          res: null,
        }));

        if (error.response.data.message == "duplicate.kitId") {
          toast.error("キットIDが重複しました");
        } else {
          toast.error("正しい情報を入力してください");
        }
      });
  };
  const {
    handleSubmit,
    errors,
    values,
    setFieldValue,
    // resetForm,
    touched,
    handleBlur,
  } = useFormik({
    initialValues: {
      inspectionKitId: "",
      customerNo: "",
      customerName: "",
    },
    onSubmit,
    validationSchema,
  });
  return (
    <DefaultLayout>
      <MainContentLayout
        pageTitle={
          <>
            <p>頭皮ラボ</p>
          </>
        }
      >
        <div className="w-full h-full pt-16 pb-8 lg:py-12 relative">
          <div className="w-full h-16 lg:h-12 pl-20 lg:pl-0 flex items-center justify-start absolute inset-x-0 top-0 bg-white">
            <h2 className="text-base lg:text-xl font-semibold">
              頭皮データを発送
            </h2>
          </div>
          <div className="w-full h-full py-3">
            <div className="w-full h-full pr-3 border border-gray-400 rounded-2xl p-4">
              <form className="w-full h-full flex flex-col  pr-3 ">
                <div className="w-full md:w-2/3">
                  <div className="w-full h-auto pr-3 mb-5">
                    <InputField
                      label="検体No."
                      name="inspectionKitId"
                      value={values.inspectionKitId}
                      type="text"
                      onChange={(newValue) =>
                        setFieldValue("inspectionKitId", newValue || "")
                      }
                      labelClasses="!w-24"
                    />
                  </div>
                  <div className="w-full flex h-auto pr-3 mb-5">
                    <label className="block shrink-0 font-semibold !w-24">
                      お客様名
                    </label>
                    <FormControl sx={{ width: "100%" }} size="small">
                      <InputLabel id="demo-select-small-label">
                        メンバー
                      </InputLabel>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={values.customerName}
                        label="メンバー"
                        onChange={handleChange}
                      >
                        {listCustomer?.map((customer) => (
                          <MenuItem
                            key={customer._id}
                            value={
                              customerNameValue ||
                              customer.customer.firstNameKata +
                                customer.customer.lastNameKata
                            }
                            name={customer.customerNo}
                          >
                            {customerNameValue ||
                              customer.customer.firstNameKata +
                                customer.customer.lastNameKata}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="flex flex-col md:flex-row gap-0 md:gap-6">
                  <div className="w-full md:w-2/3">
                    <div className="w-full h-auto pr-3 mb-5">
                      <InputField
                        label="お客様No."
                        name="customerNo"
                        value={values.customerNo}
                        type="text"
                        onChange={(newValue) => {
                          setFieldValue("customerNo", newValue);

                          loadSalonCustomer(newValue);
                        }}
                        handleBlur={handleBlur}
                        touched={touched.customerNo}
                        errors={errors.customerNo}
                        labelClasses="!w-24"
                      />
                    </div>
                  </div>
                  <div className="w-full justify-end flex items-end md:w-auto md:items-baseline">
                    <button
                      onClick={() => {
                        if (
                          values.customerName !== "" &&
                          values.customerNo !== ""
                        ) {
                          setOpen(true);
                        }
                      }}
                      type="button"
                      className={`button-size   ${
                        values.inspectionKitId === "" &&
                        values.customerName === "" &&
                        values.customerNo === ""
                          ? "bg-gray-btn-secondary"
                          : "bg-blue-btn-primary"
                      }`}
                      disabled={
                        values.inspectionKitId === "" &&
                        values.customerName === "" &&
                        values.customerNo === ""
                          ? true
                          : false
                      }
                    >
                      発送
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="w-full h-8 flex items-center justify-end text-white text-base font-semibold absolute inset-x-0 bottom-0">
            <button
              onClick={() => navigate(Path.scalpLabManagement)}
              type="button"
              className="button-size bg-gray-btn-secondary"
            >
              戻る
            </button>
          </div>
        </div>

        <CommonDialog
          open={open}
          handleClose={() => {
            if (!confirmSteps.CONFIRM_AGAIN) {
              setOpen(false);
            }
            setOpen(false);
          }}
          title={
            showStep === confirmSteps.CONFIRM ? "頭皮ラボを発送しますか？" : ""
          }
          actions={
            <div className="w-full flex gap-6 items-center justify-end text-white text-base font-semibold">
              <button
                onClick={handleConfirm}
                type="button"
                className="button-size bg-blue-btn-primary"
              >
                {showStep === confirmSteps.CONFIRM ? "発送" : "終る"}
              </button>
            </div>
          }
        >
          <div className="flex justify-center text-lg font-semibold">
            {showStep === confirmSteps.CONFIRM
              ? ` お客様名 ${values.customerName}`
              : "頭皮データを発送しました"}
          </div>
        </CommonDialog>
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default OrderScalpLab;
