import React from "react";
import { Link } from "react-router-dom";

function NoticeSectionPC() {
  return (
    <div className="w-full h-full p-4">
      {/* title */}
      <div className="w-full h-10 pb-2">
        <h3 className="text-2xl font-semibold">【おしらせ】</h3>
      </div>

      {/* list post or article */}
      <div className="w-full h-[calc(100%-40px)] overflow-auto">
        <ul className="w-full h-full pl-6 list-disc">
          <li className="text-lg font-semibold underline mb-2">
            <Link>●●件 メッセージがあります</Link>
          </li>
          <li className="text-lg font-semibold underline mb-2">
            <Link>▲▲に関する内容について</Link>
          </li>
          <li className="text-lg font-semibold underline mb-2">
            <Link>▲▲に関する内容について</Link>
          </li>
          <li className="text-lg font-semibold underline mb-2">
            <Link>▲▲に関する内容について</Link>
          </li>
          <li className="text-lg font-semibold underline mb-2">
            <Link>▲▲に関する内容について</Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default NoticeSectionPC;
