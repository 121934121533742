import React from "react";

import Dialog from "@mui/material/Dialog";
import CloseDialogButton from "./CloseDialogButton";
import Fade from "@mui/material/Fade";
function CommonDialog(props) {
  const {
    open,
    handleClose,
    handleBack,
    handleConfim,
    title,
    fullScreen,
    children,
    actions,
    ...rest
  } = props;
  return (
    <Dialog
      maxWidth={fullScreen || "sm"}
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      classes={{ root: "bg-transparent" }}
      PaperProps={{
        classes: {
          root: "px-3 md:px-8 py-4 md:py-6 bg-[#FAFAFA] relative",
        },
        style: {
          borderRadius: "2px",
          border: "1px solid #D0CECE",
        },
      }}
      TransitionComponent={Fade}
      sx={{
        "& > .MuiBackdrop-root": {
          background: "transparent",
        },
        ".MuiDialog-container": {
          backgroundColor: "#00000080",
        },
      }}
      {...rest}
    >
      <CloseDialogButton handleClose={handleClose} />

      <div className="w-full flex flex-col">
        {/* title */}
        <div className="w-full h-auto shrink-0 pb-4">
          <h3 className="text-xl font-semibold pr-10">{title}</h3>
        </div>

        {/* content */}
        <div className="w-full grow min-h-[60px]">{children}</div>

        {/* actions */}
        {actions || (
          <div className="w-full flex items-center justify-end text-white text-base font-semibold">
            <button
              onClick={() => {
                handleBack ? handleBack() : handleClose && handleClose();
              }}
              type="button"
              className="button-size rounded bg-gray-btn-secondary mr-4 lg:mr-6"
            >
              戻る
            </button>
            <button
              onClick={() => {
                handleConfim && handleConfim();
              }}
              type="button"
              className="button-size rounded bg-blue-btn-primary"
            >
              進む
            </button>
          </div>
        )}
      </div>
    </Dialog>
  );
}

export default CommonDialog;
