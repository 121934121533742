import moment from "moment";
import { jpWeekDayLabel } from "../../constants";

export const statisticalDataByYear = (data, startTime, endTime) => {
  if (!Array.isArray(data)) return [];

  const sortedData = data.sort((a, b) => moment(a).diff(moment(b)));

  const startTimeClone = startTime.clone();
  const result = [];

  while (startTimeClone.diff(endTime) < 0) {
    result.push({
      key: startTimeClone.format("YYYY年"),
      label: startTimeClone.format("YYYY年"),
      dataList: [],
      amount: 0,
    });
    startTimeClone.add(1, "y");
  }

  sortedData.forEach((dataItem) => {
    const dataKey = moment(dataItem.dateTime).tz("Asia/Tokyo").format("YYYY年");
    const resultItem = result.find((item) => item.key === dataKey);
    if (resultItem) {
      resultItem.dataList.push(dataItem);
      resultItem.amount += dataItem.amount;
    }
  });

  return result;
};

export const statisticalDataByMonth = (data, startTime, endTime) => {
  if (!Array.isArray(data)) return [];

  const sortedData = data.sort((a, b) => moment(a).diff(moment(b)));

  const startTimeClone = startTime.clone();
  const result = [];

  while (startTimeClone.diff(endTime) < 0) {
    result.push({
      key: startTimeClone.format("YYYY年MM月"),
      label:
        startTimeClone.month() === 0
          ? startTimeClone.format("YYYY年MM月")
          : startTimeClone.format("MM月"),
      dataList: [],
      amount: 0,
    });
    startTimeClone.add(1, "M");
  }

  sortedData.forEach((dataItem) => {
    const dataKey = moment(dataItem.dateTime).tz("Asia/Tokyo").format("YYYY年MM月");
    const resultItem = result.find((item) => item.key === dataKey);
    if (resultItem) {
      resultItem.dataList.push(dataItem);
      resultItem.amount += dataItem.amount;
    }
  });

  return result;
};

export const statisticalDataByDay = (data, startTime, endTime) => {
  if (!Array.isArray(data)) return [];

  const sortedData = data.sort((a, b) => moment(a).diff(moment(b)));

  const startTimeClone = startTime.clone();
  const result = [];

  while (startTimeClone.diff(endTime) < 0) {
    result.push({
      key: startTimeClone.format("YYYY年MM月DD日"),
      label:
        startTimeClone.month() === 0 && startTimeClone.date() === 1
          ? startTimeClone.format("YYYY年MM月DD日")
          : startTimeClone.date() === 1
          ? startTimeClone.format("MM月DD日")
          : startTimeClone.format("DD日"),
      dataList: [],
      amount: 0,
    });
    startTimeClone.add(1, "d");
  }

  sortedData.forEach((dataItem) => {
    const dataKey = moment(dataItem.dateTime).tz("Asia/Tokyo").format("YYYY年MM月DD日");
    const resultItem = result.find((item) => item.key === dataKey);
    if (resultItem) {
      resultItem.dataList.push(dataItem);
      resultItem.amount += dataItem.amount;
    }
  });

  return result;
};

export const statisticalDataByWeek = (data, startTime, endTime) => {
  if (!Array.isArray(data)) return [];

  const sortedData = data.sort((a, b) => moment(a).diff(moment(b)));

  const startTimeClone = startTime.clone();
  const result = [];

  while (startTimeClone.diff(endTime) < 0) {
    result.push({
      key: startTimeClone.week(),
      label: startTimeClone.week(),
      dataList: [],
      amount: 0,
    });
    startTimeClone.add(1, "w");
  }

  sortedData.forEach((dataItem) => {
    const dataKey = moment(dataItem.dateTime).tz("Asia/Tokyo").week();
    const resultItem = result.find((item) => item.key === dataKey);
    if (resultItem) {
      resultItem.dataList.push(dataItem);
      resultItem.amount += dataItem.amount;
    }
  });

  return result;
};

export const statisticalDataByDayOfWeek = (data, startTime, endTime) => {
  if (!Array.isArray(data)) return [];

  const sortedData = data.sort((a, b) => moment(a).diff(moment(b)));

  const result = jpWeekDayLabel.map((item, index) => ({
    key: index,
    label: item,
    dataList: [],
    amount: 0,
  }));

  sortedData.forEach((dataItem) => {
    const dataKey = moment(dataItem.dateTime).tz("Asia/Tokyo").day();
    const resultItem = result.find((item) => item.key === dataKey);
    if (resultItem) {
      resultItem.dataList.push(dataItem);
      resultItem.amount += dataItem.amount;
    }
  });

  return result;
};

export const statisticalDataByHour = (data, startTime, endTime) => {
  if (!Array.isArray(data)) return [];

  const sortedData = data.sort((a, b) => moment(a).diff(moment(b)));

  const result = new Array(24).fill(1).map((item, index) => ({
    key: index,
    label: index,
    dataList: [],
    amount: 0,
  }));

  sortedData.forEach((dataItem) => {
    const dataKey = moment(dataItem.dateTime).tz("Asia/Tokyo").hour();
    const resultItem = result.find((item) => item.key === dataKey);
    if (resultItem) {
      resultItem.dataList.push(dataItem);
      resultItem.amount += dataItem.amount;
    }
  });

  return result;
};
