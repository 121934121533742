import React, { useEffect, useState } from "react";
import SidePostItem from "../../../components/SidePostItem";

function SideSectionPC() {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  return (
    <div className="w-full h-full">
      {/* title */}
      <div className="w-full h-10 pb-2">
        <h3 className="text-2xl font-semibold">頭皮ラボ 記事</h3>
      </div>

      {/* list post or article */}
      <div className="w-full h-[calc(100%-40px)]">
        {!isLoading ? (
          <div className="w-full h-full overflow-auto">
            <SidePostItem />
            <SidePostItem />
            <SidePostItem />
            <SidePostItem />
            <SidePostItem />
            <SidePostItem />
            <SidePostItem />
            <SidePostItem />
            <SidePostItem />
            <SidePostItem />
            <SidePostItem />
            <SidePostItem />
            <SidePostItem />
          </div>
        ) : null}

        {isLoading ? (
          <div className="w-full h-full overflow-auto">
            <SidePostItem isLoading />
            <SidePostItem isLoading />
            <SidePostItem isLoading />
            <SidePostItem isLoading />
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default SideSectionPC;
