import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";

import defaultAvatar from "app/assets/svg/default_avatar.svg";
import { getNameFromFirstLast } from "../../../utils";

export default function TableVisit(props) {
  const { allVisit, handleClickRow } = props;

  return (
    <TableContainer>
      <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
        <TableBody>
          {allVisit.map((row, index) => (
            <TableRow
              key={row.id}
              hover
              onClick={() => {}}
              tabIndex={-1}
              sx={{ cursor: "pointer" }}
            >
              <TableCell align="left">
                <div className="w-full h-full flex items-center justify-start">
                  {row.staff ? (
                    <>
                      <Avatar
                        alt={row.staff?.name || ""}
                        src={row.staff?.imageUrl || defaultAvatar}
                      />
                      {/* <span className="ml-3">{row.staffInfo?.name || ""}</span> */}
                      <span className="ml-3">
                        {getNameFromFirstLast(row.staff)}
                      </span>
                    </>
                  ) : (
                    <p>指定されていない</p>
                  )}
                </div>
              </TableCell>
              <TableCell align="right">{row.visitDate}</TableCell>
              <TableCell align="right">
                {row.expectedStartTime + " - " + row.expectedFinishTime}
              </TableCell>
              <TableCell align="right">
                {getNameFromFirstLast(row.customer)}
              </TableCell>
              <TableCell align="right">
                {row.payment ? (
                  <div className="font-semibold">請求書は支払われました</div>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleClickRow(row)}
                  >
                    次へ
                  </Button>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
